import Form from '@bfly/ui2/Form';
import FormControlWithAddon from '@bfly/ui2/FormControlWithAddon';
import MainContent from '@bfly/ui2/MainContent';
import Page from '@bfly/ui2/Page';
import clsx from 'clsx';
import React from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { RelayProp, createFragmentContainer, graphql } from 'react-relay';

import ErrorBoundary from 'components/ErrorBoundary';
import { useVariation } from 'components/LaunchDarklyContext';
import { usePermissions } from 'components/PermissionsProvider';
import actionMessages from 'messages/actions';
import { useArchiveRoutes } from 'routes/archive';
import archiveSchema, {
  deserialize,
  makeArchiveSchemaContext,
  serialize,
} from 'schemas/archive';

import UpdateArchiveMutation from '../mutations/UpdateArchiveMutation';
import ArchivePageTitle from './ArchivePageTitle';
import ArchivePrivacyForm from './ArchivePrivacyForm';
import ArchiveQaNotificationsForm from './ArchiveQaNotificationsForm';
import ArchiveStudyRestrictionsForm from './ArchiveStudyRestrictionsForm';
import DeleteArchiveControl from './DeleteArchiveControl';
import EhrArchiveAssociationForm, {
  MAX_EHR_ASSOCIATIONS,
} from './EhrArchiveAssociationForm';
import PacsArchiveAssociationForm, {
  MAX_PACS_ASSOCIATIONS,
} from './PacsArchiveAssociationForm';
import ThirdPartyUltrasoundListenerConfiguration from './ThirdPartyUltrasoundListenerConfiguration';
import WorksheetsExportControl from './WorksheetsExportControl';
import { ArchiveSettingsPage_archive$data as Archive } from './__generated__/ArchiveSettingsPage_archive.graphql';
import { ArchiveSettingsPage_organization$data as Organization } from './__generated__/ArchiveSettingsPage_organization.graphql';
import { ArchiveSettingsPage_tenant$data as Tenant } from './__generated__/ArchiveSettingsPage_tenant.graphql';
import { ArchiveSettingsPage_viewer$data as Viewer } from './__generated__/ArchiveSettingsPage_viewer.graphql';

const messages = defineMessages({
  title: {
    id: 'archiveSettings.title',
    defaultMessage: '{archive} Settings',
  },
  name: {
    id: 'archiveSettings.name',
    defaultMessage: 'Archive Name',
  },
});

interface Props {
  organization: Organization;
  archive: Archive;
  viewer: Viewer;
  relay: RelayProp;
  tenant: Tenant;
}

function ArchiveSettingsPage({
  organization,
  archive,
  viewer,
  relay,
  tenant,
}: Props) {
  const runRenameMutation = (input) => {
    return UpdateArchiveMutation.commit(
      relay.environment,
      archive.id,
      serialize(input).label,
    );
  };
  const canUseDicomConnector = useVariation('dicom-connector');
  const canUseHl7Connector = useVariation('hl7-connector');
  const canUseGranularQaNotifications = useVariation(
    'granular-qa-notifications',
  );

  const canUseTpusArchiveConfiguration = useVariation(
    'tpus-archive-configuration',
  );

  const archiveRoutes = useArchiveRoutes();
  const context = makeArchiveSchemaContext(organization, archive.id);
  const { hasBasicPermission } = usePermissions();
  const hasPermissionToManageSettings =
    hasBasicPermission('archiveManagement');

  return (
    <>
      <Page.Header backTo={archiveRoutes.archive()}>
        <ArchivePageTitle archive={archive} title={messages.title} />
      </Page.Header>
      <MainContent data-bni-id="ArchiveSettingsPage">
        <Form
          schema={archiveSchema}
          submitForm={runRenameMutation}
          defaultValue={deserialize(archive)}
          className="mb-10"
          formContext={context}
        >
          <Form.FieldGroup
            horizontal
            name="label"
            label={messages.name}
            disabled={!hasPermissionToManageSettings}
            className={clsx(!hasPermissionToManageSettings && 'opacity-50')}
          >
            {(props, meta) => (
              <FormControlWithAddon
                {...props}
                placeholder={messages.name}
                onChange={(e) => props.onChange(e.target.value)}
                addon={
                  hasPermissionToManageSettings && (
                    <Form.Submit
                      variant="primary"
                      disabled={
                        props.value.trim() === archive.label || meta.invalid
                      }
                    >
                      <FormattedMessage {...actionMessages.save} />
                    </Form.Submit>
                  )
                }
              />
            )}
          </Form.FieldGroup>
        </Form>
        {organization.subscription!.isTeam && (
          <>
            <ArchivePrivacyForm
              organization={organization}
              archive={archive}
            />
            <ArchiveStudyRestrictionsForm archive={archive} />
          </>
        )}

        {canUseGranularQaNotifications && (
          <ArchiveQaNotificationsForm archive={archive} />
        )}

        {canUseDicomConnector && (
          <Form.FieldSet
            legend={
              <FormattedMessage
                id="archive.settings.pacsSync.title"
                defaultMessage="PACS Forwarding (Select up to {limit})"
                values={{ limit: MAX_PACS_ASSOCIATIONS }}
              />
            }
            className="mb-6"
          >
            <ErrorBoundary>
              <PacsArchiveAssociationForm
                organization={organization}
                archive={archive}
              />
            </ErrorBoundary>
          </Form.FieldSet>
        )}

        {canUseHl7Connector && (
          <Form.FieldSet
            legend={
              <FormattedMessage
                id="archive.settings.ehrAssociations.title"
                defaultMessage="EHR Forwarding (Select up to {limit})"
                values={{ limit: MAX_EHR_ASSOCIATIONS }}
              />
            }
            className="mb-6"
          >
            <ErrorBoundary>
              <EhrArchiveAssociationForm
                organization={organization}
                archive={archive}
              />
            </ErrorBoundary>
          </Form.FieldSet>
        )}

        {canUseTpusArchiveConfiguration && (
          <ThirdPartyUltrasoundListenerConfiguration
            archive={archive}
            viewer={viewer}
          />
        )}
        <WorksheetsExportControl archive={archive} tenant={tenant} />

        <DeleteArchiveControl organization={organization} archive={archive} />
      </MainContent>
    </>
  );
}

export default createFragmentContainer(ArchiveSettingsPage, {
  organization: graphql`
    fragment ArchiveSettingsPage_organization on Organization {
      subscription {
        isTeam
      }
      ...ArchivePrivacyForm_organization
      ...DeleteArchiveControl_organization
      ...PacsArchiveAssociationForm_organization
      ...archiveSchema_organization
      ...EhrArchiveAssociationForm_organization
    }
  `,
  archive: graphql`
    fragment ArchiveSettingsPage_archive on Archive {
      id
      label
      ...ArchivePageTitle_archive
      ...PacsArchiveAssociationForm_archive
      ...DeleteArchiveControl_archive
      ...ArchivePrivacyForm_archive
      ...WorksheetsExportControl_archive
      ...ThirdPartyUltrasoundListenerConfiguration_archive
      ...EhrArchiveAssociationForm_archive
      ...ArchiveStudyRestrictionsForm_archive
      ...ArchiveQaNotificationsForm_archive
    }
  `,
  viewer: graphql`
    fragment ArchiveSettingsPage_viewer on Viewer {
      ...ThirdPartyUltrasoundListenerConfiguration_viewer
    }
  `,
  tenant: graphql`
    fragment ArchiveSettingsPage_tenant on TenantInterface {
      id
      ...WorksheetsExportControl_tenant
    }
  `,
});
