/**
 * @generated SignedSource<<577646bc02843e4a1973c2bc0943942c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PermissionLevel = "ADMIN" | "BASIC" | "NONE" | "%future added value";
export type routeConfig_OrganizationQuery$variables = {
  organizationSlug: string;
};
export type routeConfig_OrganizationQuery$data = {
  readonly organization: {
    readonly id: string;
    readonly setupAt: string | null;
    readonly slug: string | null;
    readonly viewerPermissions: {
      readonly organizationManagement: PermissionLevel | null;
    } | null;
  } | null;
  readonly tenant: {
    readonly viewerPermissions: {
      readonly " $fragmentSpreads": FragmentRefs<"PermissionsProvider_viewerPermissions">;
    } | null;
  } | null;
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"PermissionsProvider_viewer">;
  } | null;
};
export type routeConfig_OrganizationQuery = {
  response: routeConfig_OrganizationQuery$data;
  variables: routeConfig_OrganizationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationSlug"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "organizationManagement",
  "storageKey": null
},
v3 = {
  "alias": "organization",
  "args": [
    {
      "kind": "Variable",
      "name": "slug",
      "variableName": "organizationSlug"
    }
  ],
  "concreteType": "Organization",
  "kind": "LinkedField",
  "name": "organizationBySlug",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "setupAt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ViewerPermissions",
      "kind": "LinkedField",
      "name": "viewerPermissions",
      "plural": false,
      "selections": [
        (v2/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "routeConfig_OrganizationQuery",
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ViewerPermissions",
            "kind": "LinkedField",
            "name": "viewerPermissions",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "PermissionsProvider_viewerPermissions"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PermissionsProvider_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "routeConfig_OrganizationQuery",
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ViewerPermissions",
            "kind": "LinkedField",
            "name": "viewerPermissions",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "accessAllOrganizations",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "archiveManagement",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "bulkDataExport",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "connectivityManagement",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "credentialManagement",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dataDeletion",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dataExport",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "educationManagement",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "examTypeManagement",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fleetManagement",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "iqCareRestrictedExperience",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "loginAndSecurityManagement",
                "storageKey": null
              },
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "qa",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "qaManagement",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "signing",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "studyDocumentation",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "studyDocumentationManagement",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "studyReversion",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "userManagement",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "userPermissions",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserProfile",
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "543e5808f203e282ed3d2e3ba7073268",
    "id": null,
    "metadata": {},
    "name": "routeConfig_OrganizationQuery",
    "operationKind": "query",
    "text": "query routeConfig_OrganizationQuery(\n  $organizationSlug: String!\n) {\n  organization: organizationBySlug(slug: $organizationSlug) {\n    id\n    slug\n    setupAt\n    viewerPermissions {\n      organizationManagement\n    }\n  }\n  tenant {\n    __typename\n    viewerPermissions {\n      ...PermissionsProvider_viewerPermissions\n    }\n    id\n  }\n  viewer {\n    ...PermissionsProvider_viewer\n    id\n  }\n}\n\nfragment PermissionsProvider_viewer on Viewer {\n  profile {\n    id\n  }\n}\n\nfragment PermissionsProvider_viewerPermissions on ViewerPermissions {\n  ...checkPermission_viewerPermissions\n}\n\nfragment checkPermission_viewerPermissions on ViewerPermissions {\n  accessAllOrganizations\n  archiveManagement\n  bulkDataExport\n  connectivityManagement\n  credentialManagement\n  dataDeletion\n  dataExport\n  educationManagement\n  examTypeManagement\n  fleetManagement\n  iqCareRestrictedExperience\n  loginAndSecurityManagement\n  organizationManagement\n  qa\n  qaManagement\n  signing\n  studyDocumentation\n  studyDocumentationManagement\n  studyReversion\n  userManagement\n  userPermissions\n}\n"
  }
};
})();

(node as any).hash = "30cb281ea2ce3d74d0cbb9d13e035713";

export default node;
