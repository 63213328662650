import { graphql } from 'react-relay';

import RenameRedirect from 'components/RenameRedirect';
import Route from 'components/Route';
import routes from 'routes/admin';
import { checkTenantPermission } from 'utils/RouteAccessControl';

import { mdm_SettingsPageQuery as SettingsPageQuery } from './__generated__/mdm_SettingsPageQuery.graphql';

export default (
  <>
    <RenameRedirect from="emm" to={(match) => routes(match).mdm()} />
    <Route<SettingsPageQuery>
      path="mdm"
      getComponent={() =>
        import(
          /* webpackChunkName: "admin" */ './components/EmmSettingsPage'
        ).then((m) => m.default)
      }
      query={graphql`
        query mdm_SettingsPageQuery {
          viewer {
            ...EmmSettingsPage_viewer
          }
          tenant {
            viewerPermissions {
              ...RouteAccessControl_viewerPermissions
            }
            ...RouteAccessControl_tenant
          }
        }
      `}
      prerender={checkTenantPermission<SettingsPageQuery['response']>(
        'loginAndSecurityManagement',
        'BASIC',
      )}
    />
  </>
);
