import Layout from '@4c/layout';
import BookmarkIcon from '@bfly/icons/Bookmark';
import LearnIcon from '@bfly/icons/Learn';
import Button from '@bfly/ui2/Button';
import Form from '@bfly/ui2/Form';
import Heading from '@bfly/ui2/Heading';
import Link from '@bfly/ui2/Link';
import Text from '@bfly/ui2/Text';
import { stylesheet } from 'astroturf';
import clsx from 'clsx';
import { useRouter } from 'found';
import debounce from 'lodash/debounce';
import orderBy from 'lodash/orderBy';
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import { dataItem } from 'react-widgets/Accessors';
import { DeepNonNullable } from 'utility-types';

import CheckGroupSelectButton from 'components/CheckGroupSelectButton';
import StickyContainer from 'components/StickyContainer';
import { useEducationRoutes } from 'routes/education';
import Analytics from 'utils/Analytics';
import { useIsEnterpriseOrEducation } from 'utils/isEnterpriseOrEducation';
import { useViewerAllowMissingContext } from 'utils/viewerState';

import SortSelectButton from '../../../components/SortSelectButton';
import educationMessages from '../messages/educationMessages';
import {
  CONTENT_TYPES,
  DurationType,
  SORTS,
  Sort,
  VIDEO_TYPES,
  deserialize,
  serialize,
} from '../schemas/educationContentFilters';
import ContentTypeSelectButton from './ContentTypeSelectButton';
import LmsSsoBanner from './LmsSsoBanner';
import SearchField from './SearchField';
import type { EducationVideoPageHeader_lms$data as Lms } from './__generated__/EducationVideoPageHeader_lms.graphql';
import useButterflyAcademyAccess from './utils/useButterflyAcademyAccess';
import { DEBOUNCE_TIMEOUT } from './utils/videoListHelpers';

const styles = stylesheet`
  .header {
    background-image: url('../../../assets/static/wave.png');
  }

  .search {
    --form-control-bg-color: black;
    --form-control-border-color: black;
  }

  .sticky {
    // Don't show this above the controls dropdown.
    z-index: calc(theme('zIndex.dropdown') - 1);
  }
`;

interface Props {
  lms: Lms;
  totalNumber: number;
}

export interface SortDataT {
  id: Sort;
  label: string;
}

type Category = DeepNonNullable<Lms>['educationContentCategories'][number];

const sortData = SORTS.map((item): SortDataT => {
  return { id: item, label: educationMessages[item].defaultMessage };
});
function EducationVideoPageHeader({
  lms: {
    assigned,
    saved,
    educationContentCategories,
    educationContentFilters,
  },
  totalNumber,
}: Props) {
  const viewer = useViewerAllowMissingContext();
  const educationRoutes = useEducationRoutes();
  const { match, router } = useRouter();
  const { formatMessage } = useIntl();
  const { location } = match;
  const filters = useMemo(
    () => deserialize({ ...location.query }),
    [location.query],
  );
  const [scrolled, setScrolled] = useState(false);
  const butterflyAcademyAccess = useButterflyAcademyAccess();
  const isViewerEnterpriseOrEducation = useIsEnterpriseOrEducation();
  const hasAssignedCoursesAccess =
    !!viewer && butterflyAcademyAccess !== 'hidden';

  const [searchFiltersValue, setSearchFiltersValue] = useState(filters.search);

  const setFilters = useMemo(
    () =>
      debounce((nextFilters) => {
        router.replace({
          ...location,
          query: serialize(nextFilters),
        });
        Analytics.track('educationVideoFiltersChanged', nextFilters);
      }, DEBOUNCE_TIMEOUT),
    [location, router],
  );

  const categories = useMemo(() => {
    return orderBy(
      educationContentCategories,
      ({ ordering }: Category) => ordering,
    ) as Category[];
  }, [educationContentCategories]);

  const durations = useMemo(
    () => [
      {
        id: DurationType.LESS_THAN_1,
        label: formatMessage(educationMessages.lessThanMin),
      },
      {
        id: DurationType.FROM_1_TO_5,
        label: formatMessage(educationMessages.from1To5),
      },
      {
        id: DurationType.FROM_5_TO_10,
        label: formatMessage(educationMessages.from5To10),
      },
      {
        id: DurationType.MORE_THAN_10,
        label: formatMessage(educationMessages.moreThan10Min),
      },
    ],
    [formatMessage],
  );

  const disabledDurations = useMemo(() => {
    return educationContentFilters?.duration?.length
      ? durations.filter(
          ({ id }) => !educationContentFilters.duration!.includes(id),
        )
      : [];
  }, [durations, educationContentFilters?.duration]);

  const disabledVideoTypes = useMemo(() => {
    return educationContentFilters?.videoType?.length
      ? VIDEO_TYPES.filter(
          (item) => !educationContentFilters.videoType!.includes(item),
        )
      : [];
  }, [educationContentFilters?.videoType]);

  const disabledContentTypes = useMemo(() => {
    return educationContentFilters?.contentType?.length
      ? CONTENT_TYPES.filter(
          (item) => !educationContentFilters.contentType!.includes(item),
        )
      : [];
  }, [educationContentFilters?.contentType]);

  useEffect(() => {
    window.addEventListener('scroll', () => {
      setScrolled(window.scrollY > 320);
    });
  }, []);

  const areFiltersApplied = useMemo(() => {
    return (
      Object.keys(filters).length > 1 || filters.sort !== Sort.CREATED_AT_DESC
    );
  }, [filters]);

  return (
    <>
      <Layout wrap className="bg-grey-90">
        <Layout
          wrap
          className={clsx(
            styles.header,
            'px-8 pt-4 pb-8 w-full lg:bg-contain bg-cover bg-left-top bg-no-repeat',
          )}
        >
          <Layout
            className="w-full mb-4 md:flex-nowrap flex-wrap"
            justify="flex-end"
          >
            {viewer && <LmsSsoBanner />}
          </Layout>
          <Layout wrap className="pb-8 lg:w-full">
            <Heading variant="display-md" className="w-full">
              <FormattedMessage {...educationMessages.newEduTitle} />
            </Heading>
            <SearchField
              searchTerm={searchFiltersValue}
              setSearchTerm={setSearchFiltersValue}
              onChange={(search) => setFilters({ ...filters, search })}
              className={clsx(
                styles.search,
                'bg-grey-80 w-full lg:w-1/2 sm:mb-0 mb-3 sm:mr-3',
              )}
              placeholder={formatMessage(
                educationMessages.educationVideoSearch,
              )}
              data-bni-id="EducationSearchField"
            />
          </Layout>
        </Layout>
        <Layout
          align="center"
          className="w-full px-app-panel-sm md:px-app-panel pt-6 flex-wrap md:flex-nowrap"
        >
          <Text
            variant="lg"
            color="headline"
            data-bni-id="EducationContentTotalNumber"
          >
            <FormattedMessage
              {...educationMessages.items}
              values={{ count: totalNumber }}
            />
          </Text>
          <Layout className="px-6 mr-auto flex-wrap" pad={2}>
            <Form.Field name="category" className="sm-max:my-1">
              {(props) => (
                <CheckGroupSelectButton
                  {...props}
                  data={categories}
                  textKey="name"
                  dataKey="handle"
                  data-bni-id="EducationCategoryFilter"
                  listClassName="max-h-[30vh]"
                  placeholder={formatMessage(educationMessages.bodySystem)}
                  value={filters.category as string[]}
                  onChange={(value) =>
                    setFilters({
                      ...filters,
                      category: value?.map(({ handle }) => handle) ?? [],
                    })
                  }
                />
              )}
            </Form.Field>
            <Form.Field name="duration" className="sm-max:my-1">
              {(props) => (
                <CheckGroupSelectButton<{ id: string; label: string }>
                  {...props}
                  hideFilter
                  dataKey="id"
                  textKey="label"
                  data={durations}
                  data-bni-id="EducationDurationFilter"
                  placeholder={formatMessage(educationMessages.duration)}
                  value={filters.duration as any}
                  disabledItems={disabledDurations}
                  onChange={(value) =>
                    setFilters({
                      ...filters,
                      duration: value?.map(({ id }) => id) ?? [],
                    })
                  }
                />
              )}
            </Form.Field>
            <Form.Field name="contentType" className="sm-max:my-1">
              {(props) => (
                <ContentTypeSelectButton
                  {...props}
                  value={filters}
                  onChange={(value) => setFilters({ ...filters, ...value })}
                  disabledVideoTypes={disabledVideoTypes}
                  disabledContentTypes={disabledContentTypes}
                />
              )}
            </Form.Field>
            <div className="border border-divider my-0.5 sm-max:hidden" />
            <SortSelectButton
              className="sm-max:my-1"
              defaultValue={sortData[0]}
              sortedValue={dataItem(sortData, filters.sort, 'id')}
              data={sortData}
              onChange={(value) => {
                setFilters({
                  ...filters,
                  sort: value?.id,
                });
              }}
              onReset={() => {
                setFilters({
                  ...filters,
                  sort: undefined,
                });
              }}
            />
            <Button
              variant={{ type: 'text', primary: 'white' }}
              onClick={() => {
                setFilters({});
                setSearchFiltersValue('');
              }}
              disabled={!areFiltersApplied}
            >
              <FormattedMessage {...educationMessages.clearFilters} />
            </Button>
          </Layout>

          <Layout className="md:w-auto flex-wrap md:flex-nowrap">
            {hasAssignedCoursesAccess && (
              <Link to={educationRoutes.assignedCourses()}>
                {({ active: _, ...rest }) => (
                  <Button
                    {...rest}
                    variant="text-secondary"
                    className="flex-none"
                    data-bni-id="AssignedCoursesLink"
                  >
                    <LearnIcon width={16} className="mr-3" />
                    <FormattedMessage
                      {...(isViewerEnterpriseOrEducation
                        ? educationMessages.assigned
                        : educationMessages.enrollments)}
                      values={{ count: assigned?.edges?.length || 0 }}
                    />
                  </Button>
                )}
              </Link>
            )}
            {!!viewer && (
              <Link to={educationRoutes.savedContent()}>
                {({ active: _, ...rest }) => (
                  <Button
                    {...rest}
                    variant="text-secondary"
                    className="flex-none"
                    data-bni-id="SavedContentLink"
                  >
                    <BookmarkIcon width={16} className="mr-3" />
                    <FormattedMessage
                      {...educationMessages.saved}
                      values={{ count: saved?.edges?.length || 0 }}
                    />
                  </Button>
                )}
              </Link>
            )}
          </Layout>
        </Layout>
      </Layout>
      <StickyContainer
        className={clsx(
          styles.sticky,
          scrolled ? 'block' : 'hidden',
          'transition duration-300 ease-out',
        )}
        top={60}
      >
        <Layout className="bg-grey-90 px-6 py-4 w-full">
          <SearchField
            searchTerm={searchFiltersValue}
            onChange={(search) => setFilters({ ...filters, search })}
            setSearchTerm={setSearchFiltersValue}
            className={clsx(
              styles.search,
              'bg-grey-80 w-1/2 sm:mb-0 mb-3 sm:mr-3 md:mr-auto',
            )}
            placeholder={formatMessage(educationMessages.educationVideoSearch)}
            data-bni-id="EducationSearchFieldSticky"
          />
          <Layout className="ml-auto">
            {hasAssignedCoursesAccess && (
              <Link to={educationRoutes.assignedCourses()}>
                {({ active: _, ...rest }) => (
                  <Button
                    {...rest}
                    variant="text-secondary"
                    className="flex-none"
                    data-bni-id="AssignedCoursesSticky"
                  >
                    <LearnIcon width={16} className="mr-3" />
                    <FormattedMessage
                      {...(isViewerEnterpriseOrEducation
                        ? educationMessages.assigned
                        : educationMessages.enrollments)}
                      values={{ count: assigned?.edges?.length || 0 }}
                    />
                  </Button>
                )}
              </Link>
            )}
            {!!viewer && (
              <Link to={educationRoutes.savedContent()}>
                {({ active: _, ...rest }) => (
                  <Button
                    {...rest}
                    variant="text-secondary"
                    className="flex-none"
                    data-bni-id="SavedContentLinkSticky"
                  >
                    <BookmarkIcon width={16} className="mr-3" />
                    <FormattedMessage
                      {...educationMessages.saved}
                      values={{ count: saved?.edges?.length || 0 }}
                    />
                  </Button>
                )}
              </Link>
            )}
          </Layout>
        </Layout>
      </StickyContainer>
    </>
  );
}

export default createFragmentContainer(EducationVideoPageHeader, {
  lms: graphql`
    fragment EducationVideoPageHeader_lms on Lms {
      assigned: educationContentConnection(
        first: 2147483647
        viewerIsAssigned: true
        viewerHasCompleted: false
      ) @connection(key: "EducationVideoPageHeader_assigned") {
        edges {
          node {
            id
          }
        }
      }
      saved: educationContentConnection(
        first: 2147483647
        viewerHasSaved: true
      ) @connection(key: "EducationVideoPageHeader_saved") {
        edges {
          node {
            id
          }
        }
      }
      educationContentCategories {
        handle
        name
        ordering
      }
      educationContentFilters {
        duration
        videoType
        contentType
      }
    }
  `,
});
