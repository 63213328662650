import Layout from '@4c/layout';
import type { CredentialGroup as _CredentialGroup } from '@bfly/olympus-graphql-schema';
import Tooltip from '@bfly/ui2/Tooltip';
import useQuery from '@bfly/ui2/useQuery';
import getNodes from '@bfly/utils/getNodes';
import { FormattedMessage } from 'react-intl';
import { graphql } from 'react-relay';
import { dataItem } from 'react-widgets/Accessors';

import CheckGroupSelectButton, {
  CheckGroupSelectButtonHOCProps,
} from 'components/CheckGroupSelectButton';
import { useViewerContext } from 'utils/viewerState';

import { CredentialGroupSelectButton_Tenant_Query as TenantQuery } from './__generated__/CredentialGroupSelectButton_Tenant_Query.graphql';
import { CredentialGroupSelectButton_Viewer_Query as ViewerQuery } from './__generated__/CredentialGroupSelectButton_Viewer_Query.graphql';

export const credentialGroupConnectionFragment = graphql`
  fragment CredentialGroupSelectButton_tenant on TenantInterface {
    credentialGroupConnection(first: 2147483647) {
      edges {
        node {
          id
          name
          description
        }
      }
    }
  }
`;

type CredentialGroup = Pick<_CredentialGroup, 'id' | 'name' | 'description'>;

function useData(tenantIdProp?: string): CredentialGroup[] {
  const viewer = useViewerContext();
  const tenantId = tenantIdProp || viewer?.domain?.id;

  const { data: tenantData } = useQuery<TenantQuery>(
    graphql`
      query CredentialGroupSelectButton_Tenant_Query($id: ID!) {
        tenant: node(id: $id) {
          ... on TenantInterface {
            ...CredentialGroupSelectButton_tenant @relay(mask: false)
          }
        }
      }
    `,
    {
      fetchPolicy: 'store-and-network',
      variables: { id: tenantId || '' },
      skip: !tenantId,
    },
  );

  const { data: viewerData } = useQuery<ViewerQuery>(
    graphql`
      query CredentialGroupSelectButton_Viewer_Query($id: ID!) {
        viewer: node(id: $id) {
          ... on Viewer {
            memberships {
              organization {
                ... on TenantInterface {
                  ...CredentialGroupSelectButton_tenant @relay(mask: false)
                }
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'store-and-network',
      variables: { id: viewer.id },
      skip: !!tenantId,
    },
  );

  if (tenantData) {
    return getNodes(tenantData?.tenant?.credentialGroupConnection).map(
      (node) => ({
        ...node,
        description: node.description || '',
        name: node.name || '',
      }),
    );
  }

  if (viewerData) {
    return (
      (
        viewerData?.viewer?.memberships?.flatMap((m) =>
          getNodes(m?.organization?.credentialGroupConnection),
        ) || []
      )
        // duplication filter can be removed once the backend is fixed [CBUG-1486]
        .filter(
          (node, index, arr) =>
            arr.findIndex((n) => n.id === node.id) === index,
        )
    );
  }

  return [];
}

interface Props extends CheckGroupSelectButtonHOCProps<CredentialGroup> {
  tenantId?: string;
  dataKey: keyof CredentialGroup & string;
}

export function CredentialGroupSelectButtonDisabledTooltip({ children }) {
  return (
    <Tooltip.Trigger
      id="credentialGroupSelectButton.tooltip"
      placement="top"
      tooltip={
        <FormattedMessage
          id="credentialGroupSelectButton.tooltip.text"
          defaultMessage="Create user groups to filter analytics by a group of users. You can create user groups from your settings."
        />
      }
    >
      <Layout>{children}</Layout>
    </Tooltip.Trigger>
  );
}

export default function CredentialGroupSelectButton({
  tenantId,
  placeholder,
  dataKey,
  ...props
}: Props) {
  const credentialGroups = useData(tenantId);

  // FIXME: this should handled by CheckGroupSelectButton
  // It covers a case where dropdownForm uses the raw form value instead of the data items
  const valueCredentialGroups =
    props.value?.map((item) => dataItem(credentialGroups, item, dataKey)) ??
    null;

  const selectButton = (
    <CheckGroupSelectButton<CredentialGroup>
      {...props}
      type="radio"
      disabled={credentialGroups.length < 1}
      value={valueCredentialGroups}
      dataKey={dataKey}
      hideSelectAll
      placeholder={
        placeholder || (
          <FormattedMessage
            id="credentialGroupSelectButton.placeholder"
            defaultMessage="All user groups"
          />
        )
      }
      data={credentialGroups}
      textKey="name"
      data-bni-id="CredentialGroupSelectButton"
    />
  );

  if (!credentialGroups.length) {
    return (
      <CredentialGroupSelectButtonDisabledTooltip>
        {selectButton}
      </CredentialGroupSelectButtonDisabledTooltip>
    );
  }
  return selectButton;
}
