import { graphql } from 'react-relay';

import Route from 'components/Route';
import { checkTenantPermission } from 'utils/RouteAccessControl';

import { sso_SsoSettingsPageQuery as SsoSettingsPageQuery } from './__generated__/sso_SsoSettingsPageQuery.graphql';

export default (
  <Route<SsoSettingsPageQuery>
    path="sso"
    getComponent={() =>
      import(
        /* webpackChunkName: "admin" */ './components/SsoSettingsPage'
      ).then((m) => m.default)
    }
    query={graphql`
      query sso_SsoSettingsPageQuery {
        viewer {
          ...SsoSettingsPage_viewer
        }
        tenant {
          viewerPermissions {
            ...RouteAccessControl_viewerPermissions
          }
          ...RouteAccessControl_tenant
        }
      }
    `}
    prerender={checkTenantPermission<SsoSettingsPageQuery['response']>(
      'loginAndSecurityManagement',
      'BASIC',
    )}
  />
);
