import useRouter from 'found/useRouter';
import platform from 'platform';
import React, { useCallback } from 'react';

import useZendeskLocale from 'hooks/useZendeskLocale';
import { useViewerAllowMissingContext } from 'utils/viewerState';

const {
  ZENDESK_URL,
  ZENDESK_BROWSER_CF,
  ZENDESK_URL_CF,
  ZENDESK_TICKET_FORM,
  ZENDESK_TICKET_TYPE_CF,
} = globalThis.bflyConfig;

export enum TicketType {
  QUESTION = 'ticket_type_question',
  BUG = 'ticket_type_bug',
  FEEDBACK = 'ticket_type_feedback',
}

interface Props {
  as: React.ElementType;
  ticketType: TicketType;
  children?: React.ReactNode;
}

export default function ZendeskTicketLink({
  as: Component,
  ticketType,
  ...props
}: Props) {
  const viewer = useViewerAllowMissingContext();
  const email = viewer?.email || '';
  const { match } = useRouter();
  const zendeskLocale = useZendeskLocale();

  const handleClick = useCallback(() => {
    // TODO: Is this really needed?
    const browserInfo = `
browser: ${platform.name} ${platform.version}
platform: ${platform.os!.family} ${platform.os!.version}
device: ${platform.product}
screen size: ${window.innerWidth}x${window.innerHeight}
`.trim();

    // This is not an official Zendesk API - it is an addition to our new
    //  request template here: https://bit.ly/2JuxogZ.
    // XXX https://app.asana.com/0/381397475033997/698336408086439/f
    const search = new URLSearchParams([
      ['ticket_form_id', ZENDESK_TICKET_FORM],
      ['anonymous_requester_email', email],
      [`custom_fields_${ZENDESK_TICKET_TYPE_CF}`, ticketType],
      [`custom_fields_${ZENDESK_URL_CF}`, match.location.pathname],
      [`custom_fields_${ZENDESK_BROWSER_CF}`, browserInfo],
    ]);

    window.open(
      `${ZENDESK_URL}/hc/${zendeskLocale}/requests/new?${search}`,
      '_blank',
      'noopener,noreferrer',
    );
  }, [email, ticketType, match.location.pathname, zendeskLocale]);

  // Don't set this link as href to avoid showing the ugly URL to users.
  // FIXME: This doesn't work correctly when middle-clicked.
  return <Component {...props} onClick={handleClick} />;
}
