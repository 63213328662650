import { graphql, readInlineData } from 'react-relay';

import {
  PermissionLevel,
  checkPermission_viewerPermissions$data as ViewerPermissions,
  checkPermission_viewerPermissions$key as ViewerPermissionsRef,
} from 'utils/__generated__/checkPermission_viewerPermissions.graphql';

export type { PermissionLevel, ViewerPermissionsRef };

const UserPermission = {
  Level: {
    NONE: 0,
    BASIC: 50,
    ADMIN: 100,
  },
};

function getPermissionLevel(key: PermissionLevel): number {
  return UserPermission.Level[key];
}

export function checkPermission(
  viewerPermissionsRef: ViewerPermissionsRef | null | undefined,
  permission: Permission,
  requestedLevel?: PermissionLevel,
): boolean {
  if (!viewerPermissionsRef) {
    return false;
  }
  const viewerPermissions = readInlineData(
    graphql`
      fragment checkPermission_viewerPermissions on ViewerPermissions @inline {
        accessAllOrganizations
        archiveManagement
        bulkDataExport
        connectivityManagement
        credentialManagement
        dataDeletion
        dataExport
        educationManagement
        examTypeManagement
        fleetManagement
        iqCareRestrictedExperience
        loginAndSecurityManagement
        organizationManagement
        qa
        qaManagement
        signing
        studyDocumentation
        studyDocumentationManagement
        studyReversion
        userManagement
        userPermissions
      }
    `,
    viewerPermissionsRef,
  );

  if (!viewerPermissions) {
    return false;
  }
  const viewerPermissionLevel = viewerPermissions[permission];

  if (!requestedLevel) return viewerPermissionLevel !== 'NONE';

  return (
    !!viewerPermissionLevel &&
    getPermissionLevel(viewerPermissionLevel) >=
      getPermissionLevel(requestedLevel)
  );
}

export function checkPermissions(
  viewerPermissionsRefs:
    | (ViewerPermissionsRef | null | undefined)[]
    | null
    | undefined,
  permission: Permission,
  requestedLevel?: PermissionLevel,
) {
  return (viewerPermissionsRefs || []).some((ref) =>
    checkPermission(ref, permission, requestedLevel),
  );
}

export type Permission = Exclude<keyof ViewerPermissions, ' $fragmentType'>;
