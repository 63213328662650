import Layout from '@4c/layout';
import Button from '@bfly/ui2/Button';
import Form from '@bfly/ui2/Form';
import { useMemo } from 'react';
import { FormattedMessage, defineMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import DateRangeSelectButton from 'components/DateRangeSelectButton';
import ExamTypeSelectButton from 'components/ExamTypeSelectButton';
import { useVariation } from 'components/LaunchDarklyContext';
import OrganizationSelectButton from 'components/OrganizationSelectButton';
import StudyStatusSelectButton from 'components/StudyStatusSelectButton';
import useModalToggleState from 'hooks/useModalToggleState';
import useSearchState from 'hooks/useSearchState';
import actionMessages from 'messages/actions';
import {
  SearchMetaKey as MetaKey,
  RecentSearchIcon,
  SavedSearchIcon,
  messages,
} from 'utils/Search';
import mapFromValue from 'utils/mapFromValue';

import CreateSavedSearchModal from './CreateSavedSearchModal';
import GlobalStudySearchAdvanced from './GlobalStudySearchAdvanced';
import RecentSearchesModal from './GlobalStudySearchRecentSearchesModal';
import SavedSearchesModal from './SavedSearchesModal';
import { GlobalStudySearchSubNav_tenant$data as Tenant } from './__generated__/GlobalStudySearchSubNav_tenant.graphql';
import { GlobalStudySearchSubNav_viewer$data as Viewer } from './__generated__/GlobalStudySearchSubNav_viewer.graphql';

const SubnavKeys = new Set([
  MetaKey.FREE_TEXT,
  MetaKey.ORGANIZATION,
  MetaKey.CAPTURE_DATE,
  MetaKey.EXAM_TYPE,
  MetaKey.STATUS,
]);
interface Props {
  tenant: Tenant;
  viewer: Viewer;
}

function GlobalStudySearchSubNav({ tenant, viewer }: Props) {
  const canUseExamTypes = useVariation('exam-types');
  const canUseDraftStudies = useVariation('draft-studies');
  const showGlobalRecentSearches = useVariation('recent-search');

  const saveModal = useModalToggleState(false);
  const savedSearchesModal = useModalToggleState(false);
  const recentSearchesModal = useModalToggleState(false);

  const [searchData, setSearchData] = useSearchState(tenant);

  const isEmptySearch = Object.values(searchData || {}).every((v) => !v);

  const handleReset = () => setSearchData({});

  const divider = <div className="mx-3 my-1 bg-grey-80 w-0.5" />;

  const hasHiddenValues = useMemo(
    () =>
      Object.keys(searchData)
        .filter((key) => !SubnavKeys.has(key as MetaKey))
        .some((key) => !!searchData[key]),
    [searchData],
  );

  return (
    <>
      <Layout className="p-3 bg-grey-85 border-grey-80 border-t flex-shrink-0">
        <Form
          className="flex space-x-2 mr-2"
          onChange={(value) => setSearchData(value)}
          value={searchData}
        >
          {tenant.type === 'Domain' && (
            <Form.Field name={MetaKey.ORGANIZATION}>
              {(fieldProps, meta) => (
                <OrganizationSelectButton
                  hideSelectAll
                  {...fieldProps}
                  value={meta.value}
                  data-bni-id="OrganizationSelectButton"
                  onChange={mapFromValue('id', fieldProps)}
                />
              )}
            </Form.Field>
          )}
          <Form.Field name={MetaKey.CAPTURE_DATE}>
            {(props) => (
              <DateRangeSelectButton
                {...props}
                placeholder={defineMessage({
                  id: 'globalStudySearchSubNav.captureDates',
                  defaultMessage: 'All capture dates',
                })}
              />
            )}
          </Form.Field>
          {canUseExamTypes && (
            <Form.Field name={MetaKey.EXAM_TYPE}>
              {(fieldProps, meta) => (
                <ExamTypeSelectButton
                  {...fieldProps}
                  value={meta.value}
                  includeNoExamTypeOption
                  tenantId={tenant.id}
                  data-bni-id="ExamTypeSubNavSelectButton"
                  onChange={mapFromValue('id', fieldProps)}
                  dataKey="id"
                />
              )}
            </Form.Field>
          )}
          <Form.Field name={MetaKey.STATUS}>
            {(fieldProps, meta) => (
              <StudyStatusSelectButton
                {...fieldProps}
                value={meta.value}
                data-bni-id="StudyStatusSubNavSelectButton"
                showDraftOption={canUseDraftStudies}
              />
            )}
          </Form.Field>
        </Form>
        <GlobalStudySearchAdvanced
          active={hasHiddenValues}
          tenant={tenant}
          onChange={(newSearchData) => setSearchData(newSearchData)}
          values={searchData}
          onSave={(newSearchData) => {
            setSearchData(newSearchData);
            saveModal.show();
          }}
        />
        {divider}
        <Button
          data-bni-id="GlobalSaveSearch"
          disabled={isEmptySearch}
          variant="text-secondary"
          onClick={saveModal.show}
        >
          <FormattedMessage {...actionMessages.save} />
        </Button>
        <Button
          data-bni-id="GlobalResetSearch"
          disabled={isEmptySearch}
          variant="text-secondary"
          onClick={handleReset}
        >
          <FormattedMessage {...messages.reset} />
        </Button>
        {divider}
        {showGlobalRecentSearches && (
          <Button
            variant="text-secondary"
            onClick={recentSearchesModal.show}
            data-bni-id="GlobalRecentSearches"
          >
            <RecentSearchIcon className="mr-1" />
            <FormattedMessage {...messages.recentSearches} />
          </Button>
        )}
        <Button
          variant="text-secondary"
          onClick={() => savedSearchesModal.show()}
        >
          <SavedSearchIcon className="mr-1" />
          <FormattedMessage {...messages.savedSearches} />
        </Button>
      </Layout>
      <CreateSavedSearchModal
        {...saveModal.props}
        viewer={viewer}
        searchData={searchData}
        tenant={tenant}
      />
      <RecentSearchesModal
        {...recentSearchesModal.props}
        organizationSlug={tenant.slug || null}
        onApply={setSearchData}
      />
      <SavedSearchesModal
        {...savedSearchesModal.props}
        organizationSlug={tenant.slug || null}
        tenantId={tenant.id}
        onApply={setSearchData}
      />
    </>
  );
}

export default createFragmentContainer(GlobalStudySearchSubNav, {
  tenant: graphql`
    fragment GlobalStudySearchSubNav_tenant on TenantInterface {
      type: __typename
      id
      ... on Domain {
        id
      }
      ... on Organization {
        id
        handle
        name
        slug
      }
      ...GlobalStudySearchAdvanced_tenant
      ...CreateSavedSearchModal_tenant
      ...useSearchState_tenant
    }
  `,
  viewer: graphql`
    fragment GlobalStudySearchSubNav_viewer on Viewer {
      id
      ...CreateSavedSearchModal_viewer
    }
  `,
});
