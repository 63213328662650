import { graphql, readInlineData } from 'react-relay';

import { canAccessEnterpriseSettings_domain$key as DomainrKey } from 'utils/__generated__/canAccessEnterpriseSettings_domain.graphql';
import { Permission, checkPermission } from 'utils/checkPermission';

export default function canAccessEnterpriseSettings(
  domainRef: DomainrKey | null,
  permission?: Permission | null,
) {
  const domain = readInlineData(
    graphql`
      fragment canAccessEnterpriseSettings_domain on Domain @inline {
        viewerPermissions {
          ...checkPermission_viewerPermissions
        }
      }
    `,
    domainRef,
  );

  const permissions = domain?.viewerPermissions;

  if (permission) {
    return checkPermission(permissions, permission, 'BASIC');
  }
  return (
    checkPermission(permissions, 'credentialManagement', 'BASIC') ||
    checkPermission(permissions, 'connectivityManagement', 'BASIC') ||
    checkPermission(permissions, 'examTypeManagement', 'BASIC') ||
    checkPermission(permissions, 'fleetManagement', 'BASIC') ||
    checkPermission(permissions, 'loginAndSecurityManagement', 'BASIC') ||
    checkPermission(permissions, 'qaManagement', 'BASIC') ||
    checkPermission(permissions, 'studyDocumentation', 'BASIC') ||
    checkPermission(permissions, 'studyDocumentationManagement', 'BASIC') ||
    checkPermission(permissions, 'userManagement', 'BASIC') ||
    checkPermission(permissions, 'userPermissions', 'BASIC')
  );
}
