/* eslint-disable relay/no-future-added-value */
import getNodes from '@bfly/utils/getNodes';
import { createContext, useContext } from 'react';
import { graphql } from 'react-relay';

import { checkPermission } from 'utils/checkPermission';

import OmitFragType from './OmitFragType';
import { viewerState_organization$data } from './__generated__/viewerState_organization.graphql';
import { viewerState_viewer$data } from './__generated__/viewerState_viewer.graphql';

export type Viewer = OmitFragType<viewerState_viewer$data>;
export type Organization = OmitFragType<viewerState_organization$data>;

export interface ViewerState extends Omit<Viewer, 'userPreferences'> {
  // these are all the organizations the user has access to
  organizations: Organization[];
  // we convert the userPreferences data to a cleaner Record<string, any>
  userPreferences: Record<string, any>;
}

/**
 * This class is used to manage the viewer state and provide a single source of truth for the viewer state.
 */
export class ViewerStateManager {
  state: ViewerState | null = null;

  public static instance: ViewerStateManager | null = null;

  public static getInstance(): ViewerStateManager {
    if (!ViewerStateManager.instance) {
      ViewerStateManager.instance = new ViewerStateManager();
    }
    return ViewerStateManager.instance;
  }

  static clear() {
    ViewerStateManager.instance = new ViewerStateManager();
  }

  init(viewer: Viewer | null) {
    if (!viewer) {
      this.state = null;
      return this.state;
    }

    let organizations: Organization[] = [];

    if (
      viewer.domain &&
      checkPermission(
        viewer.domain?.viewerPermissions,
        'accessAllOrganizations',
      )
    ) {
      organizations = viewer.domain.organizationConnection
        ? (getNodes(viewer.domain.organizationConnection) as Organization[])
        : [];
    } else {
      organizations =
        viewer?.memberships?.map(
          (membership) => membership!.organization! as Organization,
        ) || [];
    }

    this.state = {
      ...viewer,
      organizations: organizations.sort((o1, o2) =>
        (o1.name || '').localeCompare(o2.name || ''),
      ),
      userPreferences:
        viewer?.userPreferences?.edges?.reduce((preferences, edge) => {
          if (edge?.node?.preferenceKey)
            preferences[edge?.node?.preferenceKey] = edge?.node?.value;
          return preferences;
        }, {}) || {},
    };

    return this.state;
  }
}

const viewerContext = createContext<ViewerState | null>(null);

export const ViewerContextProvider = viewerContext.Provider;

export function useViewerContext() {
  const context = useContext(viewerContext);
  if (!context) {
    throw new Error('useViewerContext must be called within viewerContext');
  }
  return context;
}

/**
 * Same as useViewerContext but won't throw an error when viewer context is unavailable.
 * Use this on pages that are outside of an organization, e.g. account settings.
 */
export function useViewerAllowMissingContext() {
  return useContext(viewerContext);
}

export const organizationFragment = graphql`
  fragment viewerState_organization on Organization {
    id
    handle
    name
    slug
    viewerCanQa
    viewerIsMember
    viewerPermissions {
      ...checkPermission_viewerPermissions
    }
    viewerLaunchDarklyConfig(platform: CLOUD) {
      state
    }
    subscription {
      planType
    }
  }
`;

export const viewerFragment = graphql`
  fragment viewerState_viewer on Viewer {
    acceptedTermsAndConditionsUrl
    canAccessEduDashboard
    canAccessEduDashboardWithEduManagementPermissions
    canAccessLms
    didInteractCommentBox: didExperience(key: "web.interact_comment_box")
    didViewEduPage: didExperience(key: "web.view_edu_page")
    didViewStudyDetail: didExperience(key: "web.view_study_detail")
    eligibleForTermsAndConditions
    email
    hasAcceptedLatestEula
    hasUnexpiredMembership
    id
    placeOfWork {
      id
    }
    placeOfWorkFreeform
    setupAt
    shouldSeeMembershipsExpiredBanner
    shouldSeeNonPaidBanner
    shouldSeeProCustomWithoutEduBanner
    specialty {
      key
    }
    profile {
      name
      id
      handle
      ...Avatar_userProfile
    }
    memberships {
      organization {
        ...viewerState_organization @relay(mask: false)
      }
    }
    domain {
      id
      viewerCanQa
      name
      viewerPermissions {
        ...checkPermission_viewerPermissions
      }
      organizationConnection {
        edges {
          node {
            ...viewerState_organization @relay(mask: false)
          }
        }
      }
    }
    userPreferences {
      edges {
        node {
          id
          value
          preferenceKey
        }
      }
    }
  }
`;
