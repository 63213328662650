import { graphql } from 'react-relay';

import Route, { chainPrerenders } from 'components/Route';
import {
  checkFlagsOr404,
  checkRoutePermission,
} from 'utils/RouteAccessControl';

export default (
  <Route
    path="annotation-extensions"
    query={graphql`
      query annotationExtensions_AnnotationExtensionsSettingsPrerenderQuery(
        $organizationSlug: String!
      ) {
        organization: organizationBySlug(slug: $organizationSlug) {
          viewerPermissions {
            ...RouteAccessControl_viewerPermissions
          }
        }
      }
    `}
    prerender={chainPrerenders(
      checkFlagsOr404('deep-learning-sdk'),
      checkRoutePermission('organizationManagement', 'BASIC', 'organization'),
    )}
  >
    <Route
      getComponent={() =>
        import(
          /* webpackChunkName: "admin" */ './components/AnnotationExtensionsSettingsContent'
        ).then((m) => m.default)
      }
      query={graphql`
        query annotationExtensions_AnnotationExtensionsSettingsQuery(
          $organizationSlug: String!
        ) {
          organization: organizationBySlug(slug: $organizationSlug) {
            ...AnnotationExtensionsSettingsContent_organization
          }
        }
      `}
    />
    <Route
      path="-/new"
      getComponent={() =>
        import(
          /* webpackChunkName: "admin" */ './components/CreateAnnotationExtensionPage'
        ).then((m) => m.default)
      }
      query={graphql`
        query annotationExtensions_CreateAnnotationExtensionQuery(
          $organizationSlug: String!
        ) {
          organization: organizationBySlug(slug: $organizationSlug) {
            ...CreateAnnotationExtensionPage_organization
          }
        }
      `}
    />
    <Route
      path=":extensionHandle"
      getComponent={() =>
        import(
          /* webpackChunkName: "admin" */ './components/UpdateAnnotationExtensionPage'
        ).then((m) => m.default)
      }
      query={graphql`
        query annotationExtensions_UpdateAnnotationExtensionQuery(
          $organizationSlug: String!
          $extensionHandle: String!
        ) {
          annotationExtension(handle: $extensionHandle) {
            ...UpdateAnnotationExtensionPage_annotationExtension
          }

          organization: organizationBySlug(slug: $organizationSlug) {
            ...UpdateAnnotationExtensionPage_organization
          }
        }
      `}
    />
  </Route>
);
