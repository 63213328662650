import captureException from '@bfly/utils/captureException';
import pick from 'lodash/pick';
import { Component, type ErrorInfo, type ReactNode } from 'react';

import { noticeError } from 'utils/newRelic';

import { ViewerStateManager } from '../utils/viewerState';
import OutageMessage from './OutageMessage';

interface Props {
  children: ReactNode;
  fallbackComponent?: ReactNode;
}

/**
 * ErrorBoundary shows a fallback UI when any child components throw an error
 * @param {any} props:Props
 * @property {ReactNode} children - ReactNode to catch any errors from.
 * @property {ReactNode} [fallbackComponent] - ReactNode to show if any errors are caught. Defaults to <OutageMessage />
 *
 * @returns {any}
 */
class ErrorBoundary extends Component<Props, { hasError: boolean }> {
  constructor(props: Props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // eslint-disable-next-line no-console
    console.error(error, errorInfo);

    noticeError(new Error(`Error Boundary: URL: ${window.location}`), {
      ...pick(ViewerStateManager.getInstance().state, [
        'id',
        'email',
        'domain.id',
      ]),
      originalError: `${error}`,
      errorInfo,
    });

    captureException(error, errorInfo as Record<string, any>);
  }

  render() {
    if (!this.state.hasError) return <>{this.props.children}</>;

    return this.props.fallbackComponent || <OutageMessage />;
  }
}

export default ErrorBoundary;
