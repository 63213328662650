import orderBy from 'lodash/orderBy';
import { useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import CheckGroupSelectButton, {
  CheckGroupSelectButtonHOCProps,
} from 'components/CheckGroupSelectButton';
import { Organization, useViewerContext } from 'utils/viewerState';

import { usePermissions } from './PermissionsProvider';

type OrganizationOption = Pick<
  Organization,
  'id' | 'handle' | 'name' | 'slug'
>;

interface Props extends CheckGroupSelectButtonHOCProps<OrganizationOption> {
  value: string[];
  dataKey?: 'id' | 'handle';
  hideFilter?: boolean;
  defaultOption?: OrganizationOption;
}

export default function OrganizationSelectButton({
  placeholder,
  hideFilter = false,
  dataKey = 'id',
  defaultOption,
  ...props
}: Props) {
  const viewer = useViewerContext();

  const { hasBasicPermission } = usePermissions();

  const organizations = useMemo(() => {
    let items: OrganizationOption[] = [];
    if (hasBasicPermission('organizationManagement'))
      items = viewer.organizations;
    else {
      items = viewer.memberships!.map(
        (membership) => membership!.organization!,
      ) as OrganizationOption[];
    }
    if (defaultOption) {
      items.unshift(defaultOption);
    }
    return orderBy(items, (org) => org.name?.toLowerCase());
  }, [viewer, hasBasicPermission, defaultOption]);

  return (
    <CheckGroupSelectButton<OrganizationOption>
      {...props}
      value={organizations.filter((o) =>
        props.value?.includes(o[dataKey] || ''),
      )}
      dataKey="id"
      placeholder={
        placeholder || (
          <FormattedMessage
            id="organizationSelectButton.placeholder"
            defaultMessage="All organizations"
          />
        )
      }
      data={organizations}
      textKey="name"
      hideFilter={hideFilter}
    />
  );
}
