/**
 * @generated SignedSource<<5e81d60ca5c282de5cf8971bb8952ad1>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ArchiveStudyRestrictionsForm_archive$data = {
  readonly attestationMessage: string | null;
  readonly autoFinalizeNbdExams: boolean | null;
  readonly examTypeRequiredToFinalize: boolean | null;
  readonly excludedStudies: boolean | null;
  readonly hasRestrictedFinalization: boolean | null;
  readonly id: string;
  readonly multipleWorksheetsEnabled: boolean | null;
  readonly studyAuthorRequiredToRequestFinalization: boolean | null;
  readonly worklistRequiredToFinalize: boolean | null;
  readonly worksheetRequiredForQa: boolean | null;
  readonly worksheetRequiredToFinalize: boolean | null;
  readonly " $fragmentSpreads": FragmentRefs<"RestrictedFinalizationForm_archive">;
  readonly " $fragmentType": "ArchiveStudyRestrictionsForm_archive";
};
export type ArchiveStudyRestrictionsForm_archive$key = {
  readonly " $data"?: ArchiveStudyRestrictionsForm_archive$data;
  readonly " $fragmentSpreads": FragmentRefs<"ArchiveStudyRestrictionsForm_archive">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ArchiveStudyRestrictionsForm_archive",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasRestrictedFinalization",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "attestationMessage",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "worklistRequiredToFinalize",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "worksheetRequiredToFinalize",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "examTypeRequiredToFinalize",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "worksheetRequiredForQa",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "studyAuthorRequiredToRequestFinalization",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "excludedStudies",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "multipleWorksheetsEnabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "autoFinalizeNbdExams",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RestrictedFinalizationForm_archive"
    }
  ],
  "type": "Archive",
  "abstractKey": null
};

(node as any).hash = "801e9756a3e4bed72fe28898ea599a0d";

export default node;
