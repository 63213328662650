import { checkPermission } from 'utils/checkPermission';

import { ViewerState, useViewerAllowMissingContext } from './viewerState';

export default function canAccessEduDashboard(
  viewer: ViewerState | null,
): boolean {
  if (!viewer) return false;

  // if public cloud org with CUP flag on, it won't have a domain, so need to check for permissions-enabled edu dash access
  if (!viewer.domain) {
    return !!viewer.canAccessEduDashboardWithEduManagementPermissions;
  }

  return (
    !!viewer.canAccessEduDashboardWithEduManagementPermissions &&
    checkPermission(
      viewer?.domain?.viewerPermissions,
      'educationManagement',
      'BASIC',
    )
  );
}

export function useCanAccessEduDashboard(): boolean {
  const viewer = useViewerAllowMissingContext();
  return canAccessEduDashboard(viewer);
}
