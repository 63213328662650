import BlankSlate from '@bfly/ui2/BlankSlate';
import HttpError from 'found/HttpError';
import { FormattedMessage } from 'react-intl';
import { graphql } from 'react-relay';

import { getVariation } from 'components/LaunchDarklyContext';
import LaunchDarklyRoute from 'components/LaunchDarklyRoute';
import LmsForceLoginRedirect from 'components/LmsForceLoginRedirect';
import LmsRedirect from 'components/LmsRedirect';
import Route, { chainPrerenders } from 'components/Route';
import { useEducationRoutes } from 'routes/education';
import { checkFlagsOr404 } from 'utils/RouteAccessControl';
import canAccessEduDashboard from 'utils/canAccessEduDashboard';
import sessionStore from 'utils/sessionStore';
import someRouteHasProperty from 'utils/someRouteHasProperty';

import { SHOW_SIGN_IN_CONFIRMATION_STATE_KEY } from '../../components/AuthProvider';
import AssignedCoursesList from './components/AssignedCoursesList';
import CompletedCoursesList from './components/CompletedCoursesList';
import CourseOverviewPage from './components/CourseOverviewPage';
import EducationAppPage from './components/EducationAppPage';
import EducationContentBookmarks from './components/EducationContentBookmarks';
import EducationContentGrid from './components/EducationContentGrid';
import EducationContentPage from './components/EducationContentPage';
import EducationVideoListPage from './components/EducationVideoListPage';
import EducationVideoNotFoundPage from './components/EducationVideoNotFoundPage';
import SavedContentList from './components/SavedContentList';
import UsersOverviewPage from './components/UsersOverviewPage';
import UsersPage from './components/UsersPage';
import { getLastShownVideoType } from './components/utils/videoListHelpers';
import educationMessages from './messages/educationMessages';
import { deserialize as deserializeContentFilters } from './schemas/educationContentFilters';
import {
  deserialize as deserializeDashboardFilters,
  serializeSort,
} from './schemas/educationDashboardFilters';

export default (
  <Route
    name="edu"
    path="edu"
    mobileFriendly
    allowPublic
    allowLoginFromHash
    Component={EducationAppPage}
    renderFetched={({ Component, props, match }) => (
      <Component
        {...props}
        pageLayout={someRouteHasProperty(match, 'pageLayout') || undefined}
        canGoBack={someRouteHasProperty(match, 'canGoBack')}
        center={someRouteHasProperty(match, 'center')}
      />
    )}
  >
    <Route
      Component={EducationVideoListPage}
      query={graphql`
        query education_EducationVideoListPage_Query(
          $practiceType: [EducationVideoPracticeType!]
        ) {
          ...EducationVideoListPage_query
            @arguments(practiceType: $practiceType)
          viewer {
            ...EducationVideoListPage_viewer
          }
        }
      `}
      prepareVariables={(_variables, match) => {
        const practiceType = getLastShownVideoType(match);
        return {
          practiceType: [practiceType || 'HUMAN', 'NONE'],
        };
      }}
      renderFetched={({ Component, props }) => (
        <Component {...props} query={props} />
      )}
    >
      <LaunchDarklyRoute
        getVariation={() => ({
          Component: EducationContentGrid,
          prepareVariables: (_variables, match) => {
            const { sort, category, ...filters } = deserializeContentFilters({
              ...match.location.query,
            });
            // NOTE: converts a user's courseLanguage to 'EN' if we don't support their locale for edu content
            // this is a safe-guard to keep the page alive
            // will need to edit this hardcoded list when accommodating new languages
            const evaluateCourseLanguageEnumValue = (
              courseLanguage: string,
            ) => {
              return [
                'EN',
                'DE',
                'FR',
                'IT',
                'ES',
                'DA',
                'NL',
                'FI',
                'NO',
                'PL',
                'PT',
                'SV',
              ].includes(courseLanguage)
                ? courseLanguage
                : 'EN';
            };
            return {
              ...filters,
              sort: sort && [sort],
              categoryHandle: category,
              courseLanguage: evaluateCourseLanguageEnumValue(
                navigator.language.substring(0, 2).toUpperCase(),
              ),
            };
          },
          query: graphql`
            query education_EducationContentGrid_EducationContentQuery(
              $sort: [EducationContentSorting!]
              $search: String
              $categoryHandle: [String!]
              $contentType: [EducationContentType!]
              $videoType: [EducationVideoType!]
              $duration: [EducationContentDuration!]
              $viewerHasCompleted: [Boolean!]
              $courseLanguage: LmsCourseLanguageEnum
            ) {
              viewer {
                ...EducationContentGrid_viewer
              }
              lms {
                ...EducationContentGrid_lms
                  @arguments(
                    sort: $sort
                    search: $search
                    categoryHandle: $categoryHandle
                    contentType: $contentType
                    videoType: $videoType
                    duration: $duration
                    viewerHasCompleted: $viewerHasCompleted
                    courseLanguage: $courseLanguage
                  )
              }
            }
          `,
        })}
        renderFetched={({ Component, props }) => {
          return <Component {...props} query={props} />;
        }}
      />
    </Route>
    <LaunchDarklyRoute
      getVariation={() => ({
        Component: EducationContentBookmarks,
        query: graphql`
          query education_EducationAssignedPage_InfoQuery {
            lms {
              ...EducationContentBookmarks_lms
            }
          }
        `,
      })}
      renderFetched={({ Component, props }) => (
        <Component {...props} query={props} />
      )}
      allowPublic={false}
      canGoBack
    >
      <LaunchDarklyRoute
        path="assigned"
        Component={AssignedCoursesList}
        renderFetched={({ Component, props }) => <Component {...props} />}
        allowPublic={false}
        prerender={checkFlagsOr404(
          (ld) => getVariation(ld, 'butterfly-academy-access') === 'enabled',
        )}
      />
      <LaunchDarklyRoute
        path="completed"
        Component={CompletedCoursesList}
        query={graphql`
          query education_CompletedCoursesList_Query {
            lms {
              ...CompletedCoursesList_lms
            }
          }
        `}
        renderFetched={({ Component, props }) => <Component {...props} />}
        allowPublic={false}
        prerender={checkFlagsOr404(
          (ld) => getVariation(ld, 'butterfly-academy-access') === 'enabled',
        )}
      />
      <LaunchDarklyRoute
        path="saved"
        getVariation={() => ({
          Component: SavedContentList,
          query: graphql`
            query education_SavedContentList_Query {
              lms {
                ...SavedContentList_lms
              }
            }
          `,
        })}
        renderFetched={({ Component, props }) => (
          <Component {...props} query={props} />
        )}
        allowPublic={false}
        emptyPlaceholder={
          <BlankSlate>
            <BlankSlate.Title>
              <FormattedMessage {...educationMessages.savedVideosEmpty} />
            </BlankSlate.Title>
          </BlankSlate>
        }
      />
    </LaunchDarklyRoute>

    <LaunchDarklyRoute
      path="dashboard"
      name="edu-dashboard"
      allowPublic={false}
      prerender={chainPrerenders(
        checkFlagsOr404(
          (ld) => getVariation(ld, 'butterfly-academy-access') === 'enabled',
        ),
        ({ viewerState }) => {
          if (viewerState && !canAccessEduDashboard(viewerState))
            throw new HttpError(404);
        },
      )}
    >
      <Route
        path="course-overview"
        name="course-overview"
        Component={CourseOverviewPage}
      >
        <Route
          getComponent={() =>
            import(
              /* webpackChunkName: "edudashboard" */ './components/CourseOverviewGrid'
            ).then((m) => m.default)
          }
          query={graphql`
            query education_CourseOverviewGrid_Query(
              $search: String
              $sort: [LmsCourseSorting!]
              $organizationId: [String!]
              $groupId: [ID!]
            ) {
              lms {
                ...CourseOverviewGrid_lms
                  @arguments(
                    search: $search
                    sort: $sort
                    organizationId: $organizationId
                    groupId: $groupId
                  )
              }
            }
          `}
          prepareVariables={(_variables, match) => {
            const { sort, search, organizationIds, groupId } =
              deserializeDashboardFilters({
                ...match.location.query,
              });
            return {
              sort: [serializeSort(sort)],
              search: search || null,
              organizationId: organizationIds,
              groupId,
            };
          }}
          renderFetched={({ Component, props }) => (
            <Component {...props} query={props} />
          )}
        />
      </Route>
      <Route path="user-overview" Component={UsersPage}>
        <Route name="user-overview" Component={UsersOverviewPage}>
          <Route
            getComponent={() =>
              import(
                /* webpackChunkName: "edudashboard" */ './components/UsersOverviewGrid'
              ).then((m) => m.default)
            }
            query={graphql`
              query education_UsersOverviewGrid_Query(
                $search: String
                $sort: [LmsUserStatisticSorting!]
                $organizationId: [String!]
                $groupId: [ID!]
              ) {
                lms {
                  ...UsersOverviewGrid_lms
                    @arguments(
                      search: $search
                      sort: $sort
                      organizationId: $organizationId
                      groupId: $groupId
                    )
                }
              }
            `}
            prepareVariables={(_variables, match) => {
              const { sort, search, organizationIds, groupId } =
                deserializeDashboardFilters({
                  ...match.location.query,
                });
              return {
                sort: [serializeSort(sort)],
                search: search || null,
                organizationId: organizationIds,
                groupId,
              };
            }}
            renderFetched={({ Component, props }) => (
              <Component {...props} query={props} />
            )}
          />
        </Route>
        <Route
          path="select-courses"
          getComponent={() =>
            import(
              /* webpackChunkName: "edudashboard" */ './components/SelectCoursesPage'
            ).then((m) => m.default)
          }
          query={graphql`
            query education_SelectCoursesPage_Query {
              lms {
                ...SelectCoursesPage_lms
              }
            }
          `}
        >
          <Route
            getComponent={() =>
              import(
                /* webpackChunkName: "edudashboard" */ './components/SelectCoursesGrid'
              ).then((m) => m.default)
            }
            query={graphql`
              query education_SelectCoursesGrid_Query(
                $search: String
                $sort: [LmsCourseSorting!]
                $tagHandles: [String!]
              ) {
                lms {
                  ...SelectCoursesGrid_lms
                    @arguments(
                      search: $search
                      sort: $sort
                      tagHandles: $tagHandles
                    )
                }
              }
            `}
            prepareVariables={(_variables, match) => {
              const { sort, search, tags } = deserializeDashboardFilters({
                ...match.location.query,
              });
              return {
                search: search || null,
                sort: [serializeSort(sort)],
                tagHandles: tags || null,
              };
            }}
            renderFetched={({ Component, props }) => (
              <Component {...props} query={props} />
            )}
          />
        </Route>
        <Route
          path="select-due-dates"
          getComponent={() =>
            import(
              /* webpackChunkName: "edudashboard" */ './components/CourseDueDatePage'
            ).then((m) => m.default)
          }
        />
      </Route>
    </LaunchDarklyRoute>
    <Route
      path="lms"
      allowPublic
      Component={LmsRedirect}
      prerender={({ match }) => {
        if (match.location.query.absorb_login_token) {
          sessionStore.set(SHOW_SIGN_IN_CONFIRMATION_STATE_KEY, false);
        }
      }}
    />
    <Route path="lms-login" Component={LmsForceLoginRedirect} allowPublic />
    <Route
      pageLayout="fill"
      path=":handle"
      Component={EducationContentPage}
      query={graphql`
        query education_EducationContentPage_Query($handle: String!) {
          lms {
            educationContent(handle: $handle) {
              ...EducationContentPage_educationContent
            }
          }
          viewer {
            ...EducationContentPage_viewer
          }
        }
      `}
      renderFetched={({ Component, props, match }) => {
        const educationRoutes = useEducationRoutes();
        const { location } = match;
        const isDashboardRoute = location.pathname.includes(
          educationRoutes.dashboard(),
        );
        const pageProps = props.lms?.educationContent || props.educationVideo;

        if (!pageProps && !isDashboardRoute) {
          return <EducationVideoNotFoundPage />;
        }
        if (isDashboardRoute) {
          throw new HttpError(404);
        }
        return (
          <Component
            {...props}
            educationContent={props.lms?.educationContent}
          />
        );
      }}
      canGoBack
    />
  </Route>
);
