import { ScrollManager } from 'found-scroll';
import createFarceRouter from 'found/createFarceRouter';
import createRender from 'found/createRender';
import { useMemo } from 'react';
import { QueryRenderer, ReactRelayContext } from 'react-relay';
import { Environment } from 'relay-runtime';

import AppPage from 'components/AppPage';
import { AuthContextValue } from 'components/AuthContext';
import DomainLoginPage from 'components/DomainLoginPage';
import LoginPage from 'components/LoginPage';
import { AppPageQuery } from 'components/__generated__/AppPageQuery.graphql';
import { DomainLoginPage_Query as DomainQuery } from 'components/__generated__/DomainLoginPage_Query.graphql';
import { ViewerStateManager } from 'utils/viewerState';

import store from '../configureStore';
import LaunchDarklyManager from '../utils/LaunchDarklyManager';
import Resolver from '../utils/Resolver';
import ConnectionErrorPage from './ConnectionErrorPage';
import DeviceTooSmallPage from './DeviceTooSmallPage';
import ErrorPage from './ErrorPage';
import LoadingPage from './LoadingPage';
import NotFoundPage from './NotFoundPage';

export interface MatchContext {
  environment: Environment;
  domainSubdomainLabel: string | null;
  viewerLocalId: string | null;
  launchDarkly: LaunchDarklyManager;
  viewerManager: ViewerStateManager;
}

interface Props extends MatchContext {
  auth: AuthContextValue;
}

const render = createRender({
  renderError: ({ error, context }) => {
    switch (error.status) {
      case 500:
        return <ConnectionErrorPage />;
      case 400:
      case 403:
        return <ErrorPage />;
      case 404:
        return (
          <QueryRenderer<AppPageQuery>
            environment={context.environment}
            fetchPolicy="store-and-network"
            query={AppPage.query}
            variables={{}}
            render={({ props }) =>
              props ? (
                <AppPage {...props} center>
                  <NotFoundPage />
                </AppPage>
              ) : (
                <LoadingPage />
              )
            }
          />
        );
      case 401:
        if (context.domainSubdomainLabel) {
          return (
            <QueryRenderer<DomainQuery>
              environment={context.environment}
              fetchPolicy="store-and-network"
              query={DomainLoginPage.query}
              variables={{
                domainSubdomainLabel: context.domainSubdomainLabel,
              }}
              render={({ props }) =>
                props ? (
                  <DomainLoginPage {...props} inviteInfo={null} />
                ) : (
                  <LoadingPage />
                )
              }
            />
          );
        }
        return <LoginPage inviteInfo={null} />;
      case 'loading' as any:
        return <LoadingPage />;
      case 'device_too_small' as any:
        return <DeviceTooSmallPage />;
      default:
        return null;
    }
  },
});

const FarceRouter = createFarceRouter({
  store,
  render: (renderArgs) => (
    <ScrollManager renderArgs={renderArgs as any}>
      {render(renderArgs)}
    </ScrollManager>
  ),
} as any);

function Router({
  environment,
  auth,
  domainSubdomainLabel,
  viewerLocalId,
  launchDarkly,
  viewerManager,
}: Props) {
  // Use memoized resolver to avoid spuriously rerunning match resolution.
  const resolver = useMemo(
    () => new Resolver(environment, auth),
    [environment, auth],
  );

  const context: MatchContext = {
    environment,
    domainSubdomainLabel,
    viewerLocalId,
    launchDarkly,
    viewerManager,
  };

  const relayContext = useMemo(() => ({ environment }), [environment]);

  return (
    <ReactRelayContext.Provider value={relayContext}>
      <FarceRouter resolver={resolver as any} matchContext={context as any} />
    </ReactRelayContext.Provider>
  );
}

Router.displayName = 'Router';

export default Router;
