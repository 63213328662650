// We need to load these explicitly so they are first and the interfaces merge properly
import 'astroturf'; // eslint-disable-line import/order
import './@types/found.d';

import './styles/tailwind.css';

import 'intl';
import 'intl/locale-data/jsonp/en';

import './yupConfig';

import DialogProvider from '@bfly/ui2/DialogProvider';
import ToastContainer from '@bfly/ui2/ToastContainer';
import ToastProvider from '@bfly/ui2/ToastProvider';
import checkForObtrusiveScrollbars from '@bfly/ui2/utils/checkForObtrusiveScrollbars';
import DateLocalizer from '@bfly/ui2/utils/dateLocalizer';
import { IntlProvider } from 'react-intl';
import Localization from 'react-widgets/Localization';
import { RelayEnvironmentProvider } from 'relay-hooks';

import useMountMemo from 'hooks/useMountMemo';
import getDomainSubdomainLabel from 'utils/getDomainSubdomainLabel';
import { ViewerStateManager } from 'utils/viewerState';

import AppDocumentTitle from './components/AppDocumentTitle';
import ApplicationErrorBoundary from './components/ApplicationErrorBoundary';
import AuthProvider from './components/AuthProvider';
import DeveloperControls from './components/DeveloperControls';
import Router from './components/Router';
import store from './configureStore';
import LaunchDarklyManager from './utils/LaunchDarklyManager';

function ClientApplicationInner() {
  const routerProps = useMountMemo(() => {
    return {
      domainSubdomainLabel: getDomainSubdomainLabel(),
      launchDarkly: LaunchDarklyManager.getInstance(),
      viewerManager: ViewerStateManager.getInstance(),
    };
  });

  return (
    <>
      <AppDocumentTitle />
      <DialogProvider>
        <ToastProvider>
          {!bflyConfig.IS_PROD && <DeveloperControls />}
          <ApplicationErrorBoundary>
            <AuthProvider store={store}>
              {({ environment, viewerLocalId, auth }) => (
                <RelayEnvironmentProvider environment={environment}>
                  <Router
                    auth={auth}
                    environment={environment}
                    viewerLocalId={viewerLocalId}
                    {...routerProps}
                  />
                </RelayEnvironmentProvider>
              )}
            </AuthProvider>
          </ApplicationErrorBoundary>
          <ToastContainer dismissAfter={5000} />
        </ToastProvider>
      </DialogProvider>
    </>
  );
}

interface Props {
  locale: string;
  messages: Record<string, string> | undefined;
}

function ClientApplication({ locale, messages }: Props) {
  return (
    <Localization date={new DateLocalizer({ culture: navigator.language })}>
      <IntlProvider
        locale={navigator.language}
        messages={messages}
        defaultLocale={locale}
        onError={() => false}
      >
        <ApplicationErrorBoundary>
          <ClientApplicationInner />
        </ApplicationErrorBoundary>
      </IntlProvider>
    </Localization>
  );
}

export default function createClientApplication(
  locale: string,
  messages: Record<string, string> | undefined,
) {
  checkForObtrusiveScrollbars();

  return <ClientApplication locale={locale} messages={messages} />;
}
