import Combobox from '@bfly/ui2/Combobox';
import { FormVariant } from '@bfly/ui2/Form';
import useMutationWithError, {
  RelayMutationError,
  isMutationError,
} from '@bfly/ui2/useMutationWithError';
import getNodes from '@bfly/utils/getNodes';
import rangeDeleteUpdater from '@bfly/utils/rangeDeleteUpdater';
import { useCallback, useMemo, useState } from 'react';
import { defineMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import { useVariation } from 'components/LaunchDarklyContext';
import { renderUserSuggestion } from 'components/UserSuggestionListItem';
import useSearchQuery from 'hooks/useSearchQuery';
import updateNumStudies from 'utils/updateNumStudies';
import { useViewerContext } from 'utils/viewerState';

import { StudyAuthorSearchControl_Mutation as Mutation } from './__generated__/StudyAuthorSearchControl_Mutation.graphql';
import { StudyAuthorSearchControl_Query as Query } from './__generated__/StudyAuthorSearchControl_Query.graphql';
import { StudyAuthorSearchControl_ReplaceMutation as ReplaceMutation } from './__generated__/StudyAuthorSearchControl_ReplaceMutation.graphql';
import { StudyAuthorSearchControl_study$data as Study } from './__generated__/StudyAuthorSearchControl_study.graphql';

interface Props {
  study: Study;
  variant?: FormVariant;
}

function useStudyAuthorSearchData(archiveId: string, search: string | null) {
  return useSearchQuery<Query>(
    graphql`
      query StudyAuthorSearchControl_Query($archiveId: ID!, $search: String) {
        archive: node(id: $archiveId) {
          ... on Archive {
            membershipConnection(first: 30, search: $search)
              @connection(key: "Archive_membershipConnection") {
              edges {
                node {
                  userProfile {
                    id
                  }
                  email
                  ...UserSuggestionListItem_userInfo
                }
              }
            }
          }
        }
      }
    `,
    search,
    { archiveId },
  );
}

const replaceMutation = graphql`
  mutation StudyAuthorSearchControl_ReplaceMutation(
    $input: ReplaceStudyPrimaryAuthorInput!
  ) {
    replaceStudyPrimaryAuthorOrError(input: $input) {
      ... on ReplaceStudyPrimaryAuthorPayload {
        study {
          organization {
            studyStatistics {
              numDraftStudiesFromViewer
              numDraftStudiesFromViewerAll
              numSignatureRequestedFromViewer
              numNeedsReviewFromViewer
              numUnassignedStudies
            }
          }
          createdBy {
            name
            ...Avatar_userProfile
          }
          ...StudyPermissions_allStudyPermissions
        }
      }
      ...mutationError_error @relay(mask: false)
    }
  }
`;

const addMutation = graphql`
  mutation StudyAuthorSearchControl_Mutation($input: AddStudyAuthorInput!) {
    addStudyAuthorOrError(input: $input) {
      ... on AddStudyAuthorPayload {
        study {
          organization {
            studyStatistics {
              numDraftStudiesFromViewer
              numDraftStudiesFromViewerAll
              numSignatureRequestedFromViewer
              numNeedsReviewFromViewer
              numUnassignedStudies
            }
          }
          ...StudyPermissions_allStudyPermissions
        }
      }
      ...mutationError_error @relay(mask: false)
    }
  }
`;

function StudyAuthorSearchControl({ study, variant }: Props) {
  const canUseMultipleAuthors = useVariation('multiple-study-authors');
  const viewer = useViewerContext();

  const organizationId = study.organization!.id!;

  const viewerId = viewer.profile!.id!;

  const [search, setSearch] = useState('');
  const { data, loading } = useStudyAuthorSearchData(
    study.archive!.id,
    search,
  );

  const archiveMemberships = useMemo(() => {
    if (!data) return [];

    return getNodes(data?.archive!.membershipConnection);
  }, [data]);

  const [error, setError] = useState<RelayMutationError<Mutation> | null>(
    null,
  );

  const [addAuthor, addUserLoading] = useMutationWithError<Mutation>(
    addMutation,
    {
      updater: (store, payloadData) => {
        updateNumStudies(
          organizationId,
          store,
          payloadData.addStudyAuthorOrError!.study!.organization!
            .studyStatistics!,
          viewerId,
        );
        rangeDeleteUpdater(store, {
          parentId: study.organization!.id,
          connectionKey: 'Organization_studyConnection',
          connectionFilters: (vars) => {
            const authorFilter = vars?.author || vars?.primaryAuthor || [];
            return Array.isArray(authorFilter)
              ? authorFilter.includes('@@UNASSIGNED')
              : false;
          },
          deletedId: study.id,
        });
        rangeDeleteUpdater(store, {
          parentId: study.organization!.id,
          connectionKey: 'Organization_studySearchConnection',
          connectionFilters: (vars) => {
            const authorFilter = vars?.search?.author || [];
            return Array.isArray(authorFilter)
              ? authorFilter.includes('@@UNASSIGNED')
              : false;
          },
          deletedId: study.id,
        });
      },
    },
  );

  const [replaceAuthor, replaceUserLoading] =
    useMutationWithError<ReplaceMutation>(replaceMutation, {
      updater: (store, payloadData) =>
        updateNumStudies(
          organizationId,
          store,
          payloadData.replaceStudyPrimaryAuthorOrError!.study!.organization!
            .studyStatistics!,
          viewerId,
        ),
    });

  const handleSelect = useCallback(
    async (nextValue) => {
      try {
        const input = {
          studyId: study.id,
          authorUserId: nextValue.userProfile.id,
        };
        if (canUseMultipleAuthors) {
          await addAuthor({ input });
        } else {
          await replaceAuthor({ input });
        }
      } catch (err: any) {
        if (isMutationError(err)) setError(err);
        else throw err;
      } finally {
        setSearch('');
      }
    },
    [study, canUseMultipleAuthors, addAuthor, replaceAuthor],
  );

  const handleChange = (value: string) => {
    if (error) {
      setError(null);
    }
    if (typeof value === 'string') {
      setSearch(value);
    }
  };

  return (
    <>
      <Combobox
        focusFirstItem
        variant={variant}
        data={archiveMemberships}
        textField="email"
        filter={false}
        renderListItem={renderUserSuggestion}
        data-bni-id="StudyAuthorsSearchControl"
        placeholder={defineMessage({
          id: 'studyAuthorSearchControl.placeholder',
          defaultMessage: 'Search author by name',
        })}
        readOnly={addUserLoading || replaceUserLoading}
        busy={loading}
        value={search}
        invalid={!!error}
        onChange={handleChange}
        onSelect={handleSelect}
      />
      {error && <span className="text-sm text-danger">{error.message}</span>}
    </>
  );
}

export default createFragmentContainer(StudyAuthorSearchControl, {
  study: graphql`
    fragment StudyAuthorSearchControl_study on Study {
      finalizedAt
      organization {
        id
      }
      id
      archive {
        id
      }
      ...studyAuthors_study
    }
  `,
});
