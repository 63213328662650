/**
 * @generated SignedSource<<d13e93979f18895ed42138d4b80d4841>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type PermissionLevel = "ADMIN" | "BASIC" | "NONE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type checkPermission_viewerPermissions$data = {
  readonly accessAllOrganizations: PermissionLevel | null;
  readonly archiveManagement: PermissionLevel | null;
  readonly bulkDataExport: PermissionLevel | null;
  readonly connectivityManagement: PermissionLevel | null;
  readonly credentialManagement: PermissionLevel | null;
  readonly dataDeletion: PermissionLevel | null;
  readonly dataExport: PermissionLevel | null;
  readonly educationManagement: PermissionLevel | null;
  readonly examTypeManagement: PermissionLevel | null;
  readonly fleetManagement: PermissionLevel | null;
  readonly iqCareRestrictedExperience: PermissionLevel | null;
  readonly loginAndSecurityManagement: PermissionLevel | null;
  readonly organizationManagement: PermissionLevel | null;
  readonly qa: PermissionLevel | null;
  readonly qaManagement: PermissionLevel | null;
  readonly signing: PermissionLevel | null;
  readonly studyDocumentation: PermissionLevel | null;
  readonly studyDocumentationManagement: PermissionLevel | null;
  readonly studyReversion: PermissionLevel | null;
  readonly userManagement: PermissionLevel | null;
  readonly userPermissions: PermissionLevel | null;
  readonly " $fragmentType": "checkPermission_viewerPermissions";
};
export type checkPermission_viewerPermissions$key = {
  readonly " $data"?: checkPermission_viewerPermissions$data;
  readonly " $fragmentSpreads": FragmentRefs<"checkPermission_viewerPermissions">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "checkPermission_viewerPermissions"
};

(node as any).hash = "a58173c2bd9863aacf3b821308acc908";

export default node;
