/**
 * @generated SignedSource<<730045fed7e6fd9ef3927eaa156cfdf3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PermissionLevel = "ADMIN" | "BASIC" | "NONE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AllTimeStatsPage_tenant$data = {
  readonly viewerCanQa: boolean | null;
  readonly viewerPermissions: {
    readonly qa: PermissionLevel | null;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"StatsFilters_tenant" | "useStudyStatisticsExport_tenant">;
  readonly " $fragmentType": "AllTimeStatsPage_tenant";
};
export type AllTimeStatsPage_tenant$key = {
  readonly " $data"?: AllTimeStatsPage_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"AllTimeStatsPage_tenant">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AllTimeStatsPage_tenant",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StatsFilters_tenant"
    },
    {
      "kind": "InlineDataFragmentSpread",
      "name": "useStudyStatisticsExport_tenant",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "args": null,
      "argumentDefinitions": []
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "viewerCanQa",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ViewerPermissions",
      "kind": "LinkedField",
      "name": "viewerPermissions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "qa",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "TenantInterface",
  "abstractKey": "__isTenantInterface"
};

(node as any).hash = "f98da9373e20e0e29d37450366d6ea1f";

export default node;
