import HttpError from 'found/HttpError';
import { graphql } from 'react-relay';

import RenameRedirect from 'components/RenameRedirect';
import Route from 'components/Route';
import routes from 'routes/admin';

import { subscription_SubscriptionContentQuery as SubscriptionContentQuery } from './__generated__/subscription_SubscriptionContentQuery.graphql';

export default (
  <>
    <RenameRedirect
      from="subscription"
      to={(match) =>
        routes(match, {
          organizationSlug: match.params.organizationSlug,
        }).organizationSubscription()
      }
    />
    <Route<SubscriptionContentQuery>
      path="membership"
      getComponent={() =>
        import(
          /* webpackChunkName: "admin" */ './components/SubscriptionContent'
        ).then((m) => m.default)
      }
      query={graphql`
        query subscription_SubscriptionContentQuery(
          $organizationSlug: String!
        ) {
          organization: organizationBySlug(slug: $organizationSlug) {
            ...SubscriptionContent_organization
            subscription {
              billingProvider
            }
            viewerPermissions {
              organizationManagement
            }
          }
        }
      `}
      prerender={(renderArgs) => {
        const { props } = renderArgs;

        if (!props) return;
        // prevent access to the page if no organizationManagement
        if (
          props.organization?.viewerPermissions?.organizationManagement ===
          'NONE'
        ) {
          throw new HttpError(404);
        }

        if (
          props.organization?.subscription?.billingProvider === 'SALESFORCE'
        ) {
          throw new HttpError(404);
        }
      }}
    />
  </>
);
