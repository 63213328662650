import Page from '@bfly/ui2/Page';
import SideMenu from '@bfly/ui2/SideMenu';
import { createFragmentContainer, graphql } from 'react-relay';

import BaseAppPage, { PageLayout } from 'components/BaseAppPage';
import { useVariation } from 'components/LaunchDarklyContext';
import { usePermissions } from 'components/PermissionsProvider';
import { RoutePageProps } from 'components/Route';
import { useActiveRoute } from 'components/isRouteActive';
import { useArchiveRoutes } from 'routes/archive';
import { useExamRoutes } from 'routes/exam';
import { StudyListFilter } from 'utils/StudyConstants';

import DeletedStudySection from './DeletedStudySection';
import NewExamSection from './NewExamSection';
import NewTodoSection from './NewTodoSection';
import { AppPageWithSidePanel_archive$data as Archive } from './__generated__/AppPageWithSidePanel_archive.graphql';
import { AppPageWithSidePanel_organization$data as Organization } from './__generated__/AppPageWithSidePanel_organization.graphql';
import { AppPageWithSidePanel_searchNodes$data as SearchNodes } from './__generated__/AppPageWithSidePanel_searchNodes.graphql';
import { AppPageWithSidePanel_viewer$data as Viewer } from './__generated__/AppPageWithSidePanel_viewer.graphql';

interface Props extends RoutePageProps {
  viewer: Viewer;
  organization: Organization;
  archive: Archive | null;
  searchNodes: SearchNodes | null;
  pageLayout: PageLayout;
}

function AppPageWithSidePanel({
  viewer,
  organization,
  archive,
  children,
  searchNodes,
  pageLayout,
}: Props) {
  const canUseGlobalSearch = useVariation('global-search');
  const { hasBasicPermission } = usePermissions();

  const examRoutes = useExamRoutes();

  const isRouteActive = useActiveRoute();
  const routes = useArchiveRoutes();
  const isSearchRoute = isRouteActive(routes.studySearch());
  const hideSidePanel = isSearchRoute && canUseGlobalSearch;

  return (
    <BaseAppPage
      tenant={organization}
      viewer={viewer}
      archive={archive}
      searchNodes={searchNodes}
      organization={organization}
      pageLayout={pageLayout}
      sidePanel={
        !hideSidePanel && (
          <Page.SidePanel
            data-bni-id="AppSidePanel"
            className="flex flex-col justify-between pb-0"
          >
            <SideMenu className="mb-auto mt-3">
              <NewTodoSection tenant={organization} />
              <NewExamSection
                organization={organization}
                tenant={organization}
              />
            </SideMenu>
            {hasBasicPermission('dataDeletion') && (
              <DeletedStudySection
                className="bottom-0 sticky bg-body py-0"
                tenant={organization}
                to={examRoutes.examLists({
                  organizationSlug: organization.slug!,
                  status: StudyListFilter.DELETED,
                })}
              />
            )}
          </Page.SidePanel>
        )
      }
    >
      {children}
    </BaseAppPage>
  );
}

export default createFragmentContainer(AppPageWithSidePanel, {
  viewer: graphql`
    fragment AppPageWithSidePanel_viewer on Viewer {
      ...BaseAppPage_viewer
      profile {
        handle
      }
    }
  `,
  organization: graphql`
    fragment AppPageWithSidePanel_organization on Organization {
      slug
      worklists {
        __typename
      }
      ...BaseAppPage_organization
      ...BaseAppPage_tenant
      ...DeletedStudySection_tenant
      ...NewExamSection_organization
      ...NewTodoSection_tenant
      ...NewExamSection_tenant
    }
  `,
  archive: graphql`
    fragment AppPageWithSidePanel_archive on Archive {
      ...BaseAppPage_archive
    }
  `,
  searchNodes: graphql`
    fragment AppPageWithSidePanel_searchNodes on Node @relay(plural: true) {
      ...BaseAppPage_searchNodes
    }
  `,
});
