/**
 * @generated SignedSource<<8e9138f9eaf83d89b5b14b31c4b59ae6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PermissionsProvider_viewer$data = {
  readonly profile: {
    readonly id: string;
  } | null;
  readonly " $fragmentType": "PermissionsProvider_viewer";
};
export type PermissionsProvider_viewer$key = {
  readonly " $data"?: PermissionsProvider_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"PermissionsProvider_viewer">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PermissionsProvider_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserProfile",
      "kind": "LinkedField",
      "name": "profile",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};

(node as any).hash = "ae4fc484fef062f4e1f097d61eb4f34d";

export default node;
