import Layout from '@4c/layout';
import SendStudy from '@bfly/icons/Dicom';
import Button from '@bfly/ui2/Button';
import Pagination from '@bfly/ui2/Pagination';
import Spinner from '@bfly/ui2/Spinner';
import Text from '@bfly/ui2/Text';
import useDialog from '@bfly/ui2/useDialog';
import clsx from 'clsx';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';

import DataGrid from 'components/DataGrid';
import LoadingIndicatorPage from 'components/LoadingIndicatorPage';
import NProgress from 'components/NProgress';
import { RelayRouteRenderArgs } from 'components/Route';
import { useViewerAllowMissingContext } from 'utils/viewerState';

import { DEFAULT_SORT, PAGE_SIZE } from '../constants';
import { useScanLabContext } from '../context';
import eduStudiesColumns from '../eduStudiesColumns';
import { STATUS_MESSAGES, STUDIES_MESSAGES } from '../messages';
import { EduStudy, EduStudySort } from '../types';
import useRequestReview from '../useRequestReview';
import useStudiesData from '../useStudiesData';
import Filters from './EduStudiesFilters';
import EduStudiesPortfolioIndicators from './EduStudiesPortfolioIndicators';
import EduStudiesPortfolioNoQaIndicators from './EduStudiesPortfolioNoQaIndicators';
import EduStudiesReviewIndicators from './EduStudiesReviewIndicators';

export default function EduStudiesPage({ match }: RelayRouteRenderArgs) {
  const dialog = useDialog();

  const { listRoute } = match.params;

  const {
    queryParams,
    reviewStatus,
    setSort,
    toStudyLocation,
    canRequestReview,
  } = useScanLabContext();

  const {
    index,
    loadNext,
    loadPrevious,
    fetching,
    numEdges,
    studies,
    studyImages,
    reload,
  } = useStudiesData(match);

  const [selectedItems, setSelectedItems] = useState<
    { id: string; authorId: string }[]
  >([]);

  const { requestReview, requestReviewWorking } = useRequestReview({
    onCompleted: () => {
      setSelectedItems([]);
      reload?.();
    },
  });

  const viewer = useViewerAllowMissingContext();

  if (!viewer || !listRoute) {
    return <LoadingIndicatorPage />;
  }

  const handleRowSelect = (nextStudies: EduStudy[]) => {
    setSelectedItems(
      nextStudies.map(({ id, createdBy }) => ({
        id,
        authorId: createdBy!.id!,
      })),
    );
  };

  const showBulkSubmitForReview =
    selectedItems.length && reviewStatus === 'unsubmitted';

  return (
    <Layout data-bni-id="ListPage" direction="column" className="flex-grow">
      <Layout
        className="px-app-panel-sm  md:px-app-panel pt-8"
        direction="column"
      >
        <Text
          as="div"
          data-bni-id="studiesPageTitle"
          className="text-xl text-headline"
        >
          <FormattedMessage {...STUDIES_MESSAGES[listRoute].title} />
        </Text>
        <Layout direction="column" className="pt-4">
          <Text as="div" className="text-lg text-headline mb-3">
            <FormattedMessage
              id="scanLab.performanceHeader"
              defaultMessage="Performance Indicators"
            />
          </Text>
          <Layout direction="row" justify="flex-start" className="space-x-2">
            {listRoute === 'review' && <EduStudiesReviewIndicators />}
            {listRoute === 'portfolio' && canRequestReview && (
              <EduStudiesPortfolioIndicators />
            )}
            {listRoute === 'portfolio' && !canRequestReview && (
              <EduStudiesPortfolioNoQaIndicators />
            )}
          </Layout>
        </Layout>
        <Layout direction="row" className=" py-4 items-center">
          <h2
            data-bni-id="subtitle"
            className="text-lg text-headline truncate"
          >
            {canRequestReview ? (
              <FormattedMessage
                {...STUDIES_MESSAGES[listRoute].subTitle}
                values={{
                  status: (
                    <FormattedMessage {...STATUS_MESSAGES[reviewStatus]} />
                  ),
                }}
              />
            ) : (
              <FormattedMessage
                {...STUDIES_MESSAGES[listRoute].subTitleNoStatus}
              />
            )}
          </h2>
          <Filters viewer={viewer} />
          <Pagination
            data-bni-id="PaginationControls"
            index={index || 0}
            onNextPage={loadNext}
            onPreviousPage={loadPrevious}
            pageSize={PAGE_SIZE}
            numItems={numEdges || 0}
            className="ml-3"
          />
        </Layout>
      </Layout>
      <NProgress loading={fetching} />
      <Layout
        className={clsx(
          'bg-grey-85 h-10 items-center z-40 -mb-[4rem] ml-[4.5rem]',
          !showBulkSubmitForReview && 'hidden opacity-0 pointer-events-none',
        )}
      >
        <Button
          variant="text-secondary"
          data-bni-id="BulkSubmitForReviewButton"
          onClick={async () => {
            const confirmed = await dialog.open(
              <FormattedMessage
                id="scanLab.confirmReviewRequest.body"
                defaultMessage={
                  '<b>{count, plural, one {# exam} other {# exams}}</b>  will be submitted for review. ' +
                  'Please confirm that you would like to submit {count, plural, one {this exam} other {these exams}}.'
                }
                values={{
                  count: selectedItems.length,
                  b: (str: string) => <Text variant="body-bold">{str}</Text>,
                }}
              />,
              {
                size: 'sm',
                confirmButtonProps: { size: 'lg' },
                modalVariant: 'dark',
                title: (
                  <FormattedMessage
                    id="scanLab.confirmReviewRequest.title"
                    defaultMessage="Submit {count, plural, one {exam} other {exams}} for review?"
                    values={{ count: selectedItems.length }}
                  />
                ),
                confirmLabel: (
                  <FormattedMessage
                    id="scanLab.confirmReviewRequest.confirmLabel"
                    defaultMessage="Submit for Review"
                  />
                ),
              },
            );

            if (confirmed) requestReview(selectedItems);
          }}
        >
          {requestReviewWorking ? (
            <Spinner className="w-5 mr-2" />
          ) : (
            <SendStudy width={18} className="mr-2" />
          )}
          <FormattedMessage
            id="scanLab.submitForReview"
            defaultMessage="Submit for Review"
          />
        </Button>
      </Layout>
      <DataGrid<EduStudy, { studyImages: Record<string, any> }>
        columnVisibility={{
          actions: reviewStatus === 'unsubmitted' && canRequestReview,
          author: listRoute === 'review',
          capturedAt: true,
          reviewRequested: reviewStatus === 'reviewRequested',
          reviewed: reviewStatus === 'reviewed',
          readyAt: reviewStatus === 'unsubmitted',
          examType: true,
          zonesCompleted: true,
          reviewedImageQuality:
            listRoute === 'portfolio' && reviewStatus === 'reviewed',
        }}
        selectedItems={studies.filter(({ id }) =>
          selectedItems.some((s) => s.id === id),
        )}
        onRowSelect={handleRowSelect}
        sort={queryParams?.sort?.[0] || DEFAULT_SORT}
        data-bni-id="PortfolioDataGridTable"
        onSort={(nextSort) => setSort(nextSort as EduStudySort)}
        columnContext={{ studyImages }}
        data={studies}
        columns={eduStudiesColumns}
        getRowLocation={(study) =>
          toStudyLocation({
            studyHandle: study.handle!,
            cursor: study.cursor,
          })
        }
        scrollKey="scanLabPortfolio"
        showColPicker={false}
        loading={fetching}
      />
    </Layout>
  );
}
