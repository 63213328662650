import { graphql } from 'react-relay';

import Route, { chainPrerenders } from 'components/Route';
import {
  checkFlagsOr404,
  checkIsScopedUnderTenantOr404,
  checkTenantPermission,
} from 'utils/RouteAccessControl';

import { historicalProficiency_Query as HistoricalProficiencyQuery } from './__generated__/historicalProficiency_Query.graphql';

export default (
  <Route<HistoricalProficiencyQuery>
    path="historical-proficiency"
    prerender={chainPrerenders(
      checkFlagsOr404('historical-proficiency'),
      checkIsScopedUnderTenantOr404,
      checkTenantPermission<HistoricalProficiencyQuery['response']>(
        'credentialManagement',
        'BASIC',
      ),
    )}
    query={graphql`
      query historicalProficiency_Query($organizationSlug: String) {
        tenant(slug: $organizationSlug) {
          ...HistoricalProficiencyPage_tenant
          viewerPermissions {
            ...RouteAccessControl_viewerPermissions
          }
          ...RouteAccessControl_tenant
        }
      }
    `}
    getComponent={() =>
      import(
        /* webpackChunkName: "admin" */ './components/HistoricalProficiencyPage'
      ).then((m) => m.default)
    }
    fullScreen
  />
);
