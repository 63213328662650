import Layout from '@4c/layout';
import Heading from '@bfly/ui2/Heading';
import MainContent from '@bfly/ui2/MainContent';
import Page from '@bfly/ui2/Page';
import { Link } from 'found';
import { FormattedMessage, defineMessages } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import { usePermissions } from 'components/PermissionsProvider';
import actionMessages from 'messages/actions';
import { useArchiveRoutes } from 'routes/archive';

import ArchiveInviteForm from './ArchiveInviteForm';
import ArchiveMemberItemGrid from './ArchiveMemberItemGrid';
import ArchivePageTitle from './ArchivePageTitle';
import ArchivePrivacyDescription from './ArchivePrivacyDescription';
import { ArchiveMembersPage_archive$data as Archive } from './__generated__/ArchiveMembersPage_archive.graphql';

const messages = defineMessages({
  title: {
    id: 'archiveMembers.title',
    defaultMessage: '{archive} Members',
  },
});

interface Props {
  archive: Archive;
}

function ArchiveMembersPage({ archive }: Props) {
  const archiveRoutes = useArchiveRoutes();

  const { hasBasicPermission } = usePermissions();

  return (
    <>
      <Page.Header backTo={archiveRoutes.archive()}>
        <ArchivePageTitle archive={archive} title={messages.title} />
      </Page.Header>
      <MainContent data-bni-id="MembersPage">
        {archive.isPrivate && (
          <div className="mb-6">
            {hasBasicPermission('archiveManagement') ? (
              <ArchiveInviteForm archive={archive} />
            ) : (
              <div className="rounded bg-grey-85 text-white p-6">
                <FormattedMessage
                  id="archiveMembers.nonAdminMessage"
                  defaultMessage="Contact an administrator to invite or edit members for this archive."
                />
              </div>
            )}
          </div>
        )}

        <Heading ruled>
          <Layout justify="space-between" align="flex-end">
            <FormattedMessage
              id="archiveMembers.numMembers"
              defaultMessage="{numMembers} {numMembers, plural, one {Member} other {Members}}"
              values={{
                numMembers: archive.numMemberships!,
              }}
            />
          </Layout>
        </Heading>
        <Layout
          align="center"
          className="p-4 mt-6 mb-3 border border-grey-85 rounded"
        >
          <ArchivePrivacyDescription
            organization={archive.organization!}
            isPrivate={archive.isPrivate!}
          />
          {hasBasicPermission('archiveManagement') && (
            <Link to={archiveRoutes.archiveSettings()}>
              <FormattedMessage {...actionMessages.edit} />
            </Link>
          )}
        </Layout>

        <ArchiveMemberItemGrid archive={archive} />
      </MainContent>
    </>
  );
}

export default createFragmentContainer(ArchiveMembersPage, {
  archive: graphql`
    fragment ArchiveMembersPage_archive on Archive {
      label
      isPrivate
      organization {
        ...ArchivePrivacyDescription_organization
      }
      numMemberships
      ...ArchiveMemberItemGrid_archive
      ...ArchivePageTitle_archive
      ...ArchiveInviteForm_archive
    }
  `,
});
