/**
 * @generated SignedSource<<3f514b03dbf91aa150c8d369d0a7a287>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type PermissionLevel = "ADMIN" | "BASIC" | "NONE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type RouteAccessControl_tenant$data = {
  readonly slug?: string | null;
  readonly subdomainLabel?: string | null;
  readonly viewerPermissions?: {
    readonly accessAllOrganizations: PermissionLevel | null;
    readonly archiveManagement: PermissionLevel | null;
    readonly bulkDataExport: PermissionLevel | null;
    readonly connectivityManagement: PermissionLevel | null;
    readonly credentialManagement: PermissionLevel | null;
    readonly dataDeletion: PermissionLevel | null;
    readonly dataExport: PermissionLevel | null;
    readonly educationManagement: PermissionLevel | null;
    readonly examTypeManagement: PermissionLevel | null;
    readonly fleetManagement: PermissionLevel | null;
    readonly iqCareRestrictedExperience: PermissionLevel | null;
    readonly loginAndSecurityManagement: PermissionLevel | null;
    readonly organizationManagement: PermissionLevel | null;
    readonly qa: PermissionLevel | null;
    readonly qaManagement: PermissionLevel | null;
    readonly signing: PermissionLevel | null;
    readonly studyDocumentation: PermissionLevel | null;
    readonly studyDocumentationManagement: PermissionLevel | null;
    readonly studyReversion: PermissionLevel | null;
    readonly userManagement: PermissionLevel | null;
    readonly userPermissions: PermissionLevel | null;
  } | null;
  readonly " $fragmentType": "RouteAccessControl_tenant";
};
export type RouteAccessControl_tenant$key = {
  readonly " $data"?: RouteAccessControl_tenant$data;
  readonly " $fragmentSpreads": FragmentRefs<"RouteAccessControl_tenant">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "RouteAccessControl_tenant"
};

(node as any).hash = "22f152dbca62fd7c04246268dcf8a3a8";

export default node;
