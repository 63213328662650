import Link from '@bfly/ui2/Link';
import Navbar from '@bfly/ui2/Navbar';
import getNodes from '@bfly/utils/getNodes';
import clsx from 'clsx';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { FormattedMessage, useIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import useDeviceTooSmall from 'hooks/useIsTinyViewport';
import { routes } from 'routes/exam';

import messages from '../messages/brand';
import AppSearchGlobal from './AppSearchGlobal';
import { useVariationAllowMissingLdContext } from './LaunchDarklyContext';
import OrganizationSwitcherButton from './OrganizationSwitcherButton';
import type { AppLeadingNav_organization$data as Organization } from './__generated__/AppLeadingNav_organization.graphql';
import { AppLeadingNav_searchNodes$data as SearchNodes } from './__generated__/AppLeadingNav_searchNodes.graphql';
import { AppLeadingNav_tenant$data as Tenant } from './__generated__/AppLeadingNav_tenant.graphql';
import type { AppLeadingNav_viewer$data as Viewer } from './__generated__/AppLeadingNav_viewer.graphql';
import { useActiveRoute } from './isRouteActive';

interface Props {
  viewer: Viewer | null;
  organization: Organization | null;
  tenant: Tenant | null;
  searchNodes: SearchNodes | null;
}

function AppLeadingNav({ viewer, organization, tenant, searchNodes }: Props) {
  const isRouteActive = useActiveRoute();
  const [showSearch, setShowSearch] = useState(false);

  const isTooSmall = useDeviceTooSmall();
  const brandProps = isTooSmall ? null : { as: Link, to: '/' };

  const intl = useIntl();
  const butterflyCloud = intl.formatMessage(messages.butterflyCloud);

  const organizations = viewer?.domain?.viewerPermissions
    ?.accessAllOrganizations
    ? getNodes(viewer?.domain?.organizationConnection)
    : viewer?.memberships!.map((membership) => membership!.organization!) ||
      [];

  const areManyOrganizations = organizations.length > 1;

  const canUseGlobalSearch =
    useVariationAllowMissingLdContext('global-search') && viewer;

  const canUseOrgSwitcher = viewer && (organization || tenant);

  const isSearchRoute = isRouteActive(
    routes.search({ slug: organization?.slug || '-' }),
  );

  const canUseAnalytics = useVariationAllowMissingLdContext(
    'credentialing-analytics-page',
  );

  const canDisplayOrgName =
    canUseAnalytics && organization && !areManyOrganizations;
  const canDisplayOrgSwitcher = canUseOrgSwitcher && areManyOrganizations;

  function handleToggle(nextShowSearch: boolean) {
    if (isSearchRoute) {
      return;
    }
    setShowSearch(nextShowSearch);
  }

  function handleBack() {
    setShowSearch(false);
  }

  const brand = canUseAnalytics ? (
    <>
      <Navbar.Brand style={{ flexShrink: 0 }} {...brandProps}>
        <FormattedMessage id="appLeadingNav.cloud" defaultMessage="Cloud" />
      </Navbar.Brand>
      {(canDisplayOrgName || canDisplayOrgSwitcher) && <Navbar.Divider />}
    </>
  ) : (
    <Navbar.Brand style={{ flexShrink: 0, marginRight: 10 }} {...brandProps}>
      {organization && !areManyOrganizations && (
        <div className="mr-5">{organization.name}</div>
      )}
    </Navbar.Brand>
  );

  return (
    <>
      <div
        className={clsx(
          'flex items-center transition-visibility duration-300 mr-1',
          canUseGlobalSearch &&
            (showSearch || isSearchRoute) &&
            'invisible opacity-0',
        )}
      >
        {organization && (
          <Helmet
            defaultTitle={`${organization.name} - ${butterflyCloud}`}
            titleTemplate={`%s - ${organization.name} - ${butterflyCloud}`}
          />
        )}
        {brand}
        {canDisplayOrgName && (
          <div className="text-lg mr-2">{organization.name}</div>
        )}
        {!canDisplayOrgName && canDisplayOrgSwitcher && (
          <OrganizationSwitcherButton organization={organization!} />
        )}
      </div>
      {canUseGlobalSearch && (
        <AppSearchGlobal
          show={showSearch || isSearchRoute}
          onBack={handleBack}
          onToggle={handleToggle}
          searchNodes={searchNodes}
          organization={organization}
          tenant={viewer.domain || organization!}
          isSearchRoute={isSearchRoute}
        />
      )}
    </>
  );
}

export default createFragmentContainer(AppLeadingNav, {
  viewer: graphql`
    fragment AppLeadingNav_viewer on Viewer {
      hasUnexpiredMembership
      memberships {
        organization {
          __typename
        }
      }
      domain {
        viewerPermissions {
          accessAllOrganizations
        }
        organizationConnection {
          edges {
            node {
              __typename
            }
          }
        }
        ...AppSearchGlobal_tenant
      }
    }
  `,
  organization: graphql`
    fragment AppLeadingNav_organization on Organization {
      name
      slug
      ...AppSearchGlobal_tenant
      ...AppSearchGlobal_organization
      ...OrganizationSwitcherButton_organization
    }
  `,
  tenant: graphql`
    fragment AppLeadingNav_tenant on TenantInterface {
      ...AppSearchGlobal_tenant
    }
  `,
  searchNodes: graphql`
    fragment AppLeadingNav_searchNodes on Node @relay(plural: true) {
      ...AppSearchGlobal_searchNodes
    }
  `,
});
