import Layout from '@4c/layout';
import FailureIcon from '@bfly/icons/Failure';
import Button from '@bfly/ui2/Button';
import FieldSet from '@bfly/ui2/FieldSet';
import Text from '@bfly/ui2/Text';
import useDialog from '@bfly/ui2/useDialog';
import useMutationWithError from '@bfly/ui2/useMutationWithError';
import useToast from '@bfly/ui2/useToast';
import rangeDeleteUpdater from '@bfly/utils/rangeDeleteUpdater';
import useRouter from 'found/useRouter';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import { useVariation } from 'components/LaunchDarklyContext';
import PermissionTooltip from 'components/PermissionTooltip';
import { usePermissions } from 'components/PermissionsProvider';
import actionMessages from 'messages/actions';
import { useAdminRoutes } from 'routes/admin';

import { DomainMemberDeleteFieldSet_domain$data as Domain } from './__generated__/DomainMemberDeleteFieldSet_domain.graphql';
import { DomainMemberDeleteFieldSet_domainUser$data as DomainUser } from './__generated__/DomainMemberDeleteFieldSet_domainUser.graphql';

const mutation = graphql`
  mutation DomainMemberDeleteFieldSetMutation($input: DeleteDomainUserInput!) {
    deleteDomainUserOrError(input: $input) {
      ... on DeleteDomainUserPayload {
        deletedId
      }
      ...mutationError_error @relay(mask: false)
    }
  }
`;

interface Props {
  domainUser: DomainUser;
  domain: Domain;
}

function DomainMemberDeleteFieldSet({ domainUser, domain }: Props) {
  const dialog = useDialog();
  const toast = useToast();
  const { router } = useRouter();
  const domainRoutes = useAdminRoutes({ organizationSlug: null });
  const { hasAdminPermission } = usePermissions();
  const hasPermission = hasAdminPermission('userManagement');
  const inactiveUsersEnabled = useVariation('inactive-users');

  const [attemptedDeactivateLastAdmin, setAttemptedDeactivateLastAdmin] =
    useState(false);

  const [mutate] = useMutationWithError(mutation, {
    input: {
      domainUserId: domainUser.id,
    },
    onError: (e: any) => {
      if (e.errorType === 'CannotDeleteLastAdminError') {
        setAttemptedDeactivateLastAdmin(true);
      }

      toast.error(
        <Text>
          {inactiveUsersEnabled ? (
            <FormattedMessage
              id="domainMemberDeleteFieldSet.deactivate.error"
              defaultMessage="Failed to deactivate {name}"
              values={{ name: domainUser.profile!.name }}
            />
          ) : (
            <FormattedMessage
              id="domainMemberDeleteFieldSet.delete.error"
              defaultMessage="Failed to remove {name}"
              values={{ name: domainUser.profile!.name }}
            />
          )}
        </Text>,
      );
    },
    onCompleted: () => {
      toast.success(
        <Text>
          {inactiveUsersEnabled ? (
            <FormattedMessage
              id="domainMemberDeleteFieldSet.deactivate.success"
              defaultMessage="Enterprise member {name} was successfully deactivated."
              values={{ name: domainUser.profile!.name }}
            />
          ) : (
            <FormattedMessage
              id="domainMemberDeleteFieldSet.delete.success"
              defaultMessage="Enterprise member {name} was successfully removed."
              values={{ name: domainUser.profile!.name }}
            />
          )}
        </Text>,
      );
      router.replace(domainRoutes.domainMembers());
    },
    updater: (store) => {
      rangeDeleteUpdater(store, {
        parentId: domain.id,
        connectionKey: 'Domain_domainUserConnection',
        deletedId: domainUser.id,
      });
    },
  });

  const handleClick = () => {
    return dialog.open(
      <Text data-bni-id="DeactivateConfirmationDialogue">
        {inactiveUsersEnabled ? (
          <FormattedMessage
            id="domainMemberDeleteFieldSet.deactivate.dialog.content"
            defaultMessage="{name} will be deactivated from {domainName} and their access revoked from all organizations.  They will be demoted to the Non Attesting User access role (i.e. given the lowest permissions).  Additionally, their organization memberships, integration configs, and DICOM mappings will be deleted."
            values={{
              name: domainUser.profile!.name,
              domainName: domain.name,
            }}
          />
        ) : (
          <FormattedMessage
            id="domainMemberDeleteFieldSet.delete.dialog.content"
            defaultMessage="{name} will be removed from {domainName} and their access revoked from all organizations."
            values={{
              name: domainUser.profile!.name,
              domainName: domain.name,
            }}
          />
        )}
      </Text>,
      {
        runConfirm: mutate,
        confirmButtonProps: { variant: 'danger', size: 'lg' },
        modalVariant: 'dark',
        title: (
          <Text data-bni-id="DeactivateConfirmationHeader">
            {inactiveUsersEnabled ? (
              <FormattedMessage
                id="domainMemberDeleteFieldSet.deactivate.dialog.title"
                defaultMessage="Are you sure you want to deactivate {name}?"
                values={{ name: domainUser.profile!.name }}
              />
            ) : (
              <FormattedMessage
                id="domainMemberDeleteFieldSet.delete.dialog.title"
                defaultMessage="Are you sure you want to remove {name}?"
                values={{ name: domainUser.profile!.name }}
              />
            )}
          </Text>
        ),
        confirmLabel: (
          <Text>
            {inactiveUsersEnabled ? (
              <FormattedMessage {...actionMessages.deactivate} />
            ) : (
              <FormattedMessage {...actionMessages.delete} />
            )}
          </Text>
        ),
      },
    );
  };

  return (
    <FieldSet
      legend={
        <Text data-bni-id="DeactivateMemberHeader">
          {inactiveUsersEnabled ? (
            <FormattedMessage
              id="domainMemberDeleteFieldSet.deactivate.header"
              defaultMessage="Deactivate Enterprise User"
            />
          ) : (
            <FormattedMessage
              id="domainMemberDeleteFieldSet.delete.header"
              defaultMessage="Remove Enterprise User"
            />
          )}
        </Text>
      }
    >
      <Layout justify="space-between" align="center">
        {!attemptedDeactivateLastAdmin ? (
          <Text>
            {inactiveUsersEnabled ? (
              <FormattedMessage
                id="domainMemberDeleteFieldSet.deactivate.warning"
                defaultMessage="Deactivate the user from {domainName} and revoke their access to all organizations"
                values={{ domainName: domain.name }}
              />
            ) : (
              <FormattedMessage
                id="domainMemberDeleteFieldSet.delete.warning"
                defaultMessage="Remove the user from {domainName} and revoke their access to all organizations"
                values={{ domainName: domain.name }}
              />
            )}
          </Text>
        ) : (
          <Layout>
            <FailureIcon className="mr-1 text-danger" css="width: 1.5rem" />
            <Layout direction="column">
              <Text color="danger" variant="body-bold">
                {inactiveUsersEnabled ? (
                  <FormattedMessage
                    id="domainMemberDeleteFieldSet.deactivate.lastAdminError"
                    defaultMessage="You cannot deactivate the only admin in your enterprise"
                  />
                ) : (
                  <FormattedMessage
                    id="domainMemberDeleteFieldSet.delete.lastAdminError"
                    defaultMessage="You cannot remove the only admin in your enterprise"
                  />
                )}
              </Text>
              <Text>
                {inactiveUsersEnabled ? (
                  <FormattedMessage
                    id="domainMemberDeleteFieldSet.deactivate.lastItNextStep"
                    defaultMessage="Please assign at least 1 active user the Enterprise Administrator Butterfly Access Role before deactivating this member."
                  />
                ) : (
                  <FormattedMessage
                    id="domainMemberDeleteFieldSet.delete.lastItNextStep"
                    defaultMessage="Please assign at least 1 active user the Enterprise Administrator Butterfly Access Role before removing this member."
                  />
                )}
              </Text>
            </Layout>
          </Layout>
        )}
        <PermissionTooltip wrap hide={hasPermission}>
          <Button
            variant="danger"
            onClick={handleClick}
            disabled={!hasPermission || attemptedDeactivateLastAdmin}
            data-bni-id="DeactivateUserButton"
          >
            {inactiveUsersEnabled ? (
              <FormattedMessage
                id="domainMemberDeleteFieldSet.deactivate.control"
                defaultMessage="Deactivate User"
              />
            ) : (
              <FormattedMessage
                id="domainMemberDeleteFieldSet.delete.control"
                defaultMessage="Remove User"
              />
            )}
          </Button>
        </PermissionTooltip>
      </Layout>
    </FieldSet>
  );
}

export default createFragmentContainer(DomainMemberDeleteFieldSet, {
  domainUser: graphql`
    fragment DomainMemberDeleteFieldSet_domainUser on DomainUser {
      id
      profile {
        name
      }
    }
  `,
  domain: graphql`
    fragment DomainMemberDeleteFieldSet_domain on Domain {
      id
      name
    }
  `,
});
