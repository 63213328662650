/**
 * @generated SignedSource<<0355d7d0eb2673db175e16272f64ebee>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type PermissionLevel = "ADMIN" | "BASIC" | "NONE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type exams_redirect_organization$data = {
  readonly slug: string | null;
  readonly viewerPermissions: {
    readonly qa: PermissionLevel | null;
  } | null;
  readonly " $fragmentType": "exams_redirect_organization";
};
export type exams_redirect_organization$key = {
  readonly " $data"?: exams_redirect_organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"exams_redirect_organization">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "exams_redirect_organization"
};

(node as any).hash = "404052ea31531c6d93c241014e1b24f4";

export default node;
