import { ViewerState, useViewerAllowMissingContext } from 'utils/viewerState';

export default function isEnterpriseOrEducation(
  viewer: ViewerState | null,
): boolean {
  return (
    !!viewer?.domain ||
    !!viewer?.memberships?.some(
      (membership) =>
        membership?.organization?.subscription?.planType === 'EDUCATION',
    )
  );
}

export function useIsEnterpriseOrEducation() {
  const viewer = useViewerAllowMissingContext();
  return isEnterpriseOrEducation(viewer);
}
