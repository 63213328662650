import Layout from '@4c/layout';
import Button from '@bfly/ui2/Button';
import DropdownList from '@bfly/ui2/DropdownList';
import Form from '@bfly/ui2/Form';
import FormControlWithAddon from '@bfly/ui2/FormControlWithAddon';
import getNodes from '@bfly/utils/getNodes';
import { css } from 'astroturf';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import { DeepNonNullable } from 'utility-types';

import { useVariation } from 'components/LaunchDarklyContext';
import { usePermissions } from 'components/PermissionsProvider';

import useStudyStatisticsExport from '../../../../analytics/utils/useStudyStatisticsExport';
import { WorksheetsExportControl_archive$data as Archive } from './__generated__/WorksheetsExportControl_archive.graphql';
import { WorksheetsExportControl_tenant$data as Tenant } from './__generated__/WorksheetsExportControl_tenant.graphql';

interface Props {
  archive: Archive;
  tenant: Tenant;
}

type Worksheet =
  DeepNonNullable<Archive>['organization']['worksheetTemplateConnection']['edges'][0]['node'];

function WorksheetsExportControl({ archive, tenant }: Props) {
  const canUseExport = useVariation('export-archive-worksheets');
  const [selectedTemplate, setSelectedTemplate] = useState<Worksheet | null>(
    null,
  );
  const { hasBasicPermission } = usePermissions();

  const worksheetTemplates = getNodes(
    archive.organization!.worksheetTemplateConnection,
  );

  const [requestDownloadUrl, isExportLoading] = useStudyStatisticsExport({
    tenant,
  });

  if (!canUseExport || !worksheetTemplates.length) {
    return null;
  }

  return (
    <Form.FieldSet
      legend={
        <FormattedMessage
          id="worksheets.export.header"
          defaultMessage="Export Worksheet Results"
        />
      }
      className="mb-6"
    >
      <Layout direction="column" pad>
        <FormattedMessage
          tagName="span"
          id="worksheets.export.help"
          defaultMessage="Select the worksheet template for which you'd like to export data"
        />

        <FormControlWithAddon
          name="export"
          value={selectedTemplate as any}
          onChange={setSelectedTemplate}
        >
          {(inputProps) => (
            <>
              <DropdownList
                {...inputProps}
                disabled={!hasBasicPermission('bulkDataExport')}
                allowEmpty
                data={worksheetTemplates as Worksheet[]}
                dataKey="id"
                textField={(t: any) => t?.latestVersion?.title}
                value={selectedTemplate}
                onChange={setSelectedTemplate}
                data-bni-id="BulkDataExportDropdownList"
                css={css`
                  flex: 1;

                  & :global(.rw-widget-picker) {
                    border: 0;
                    box-shadow: none; // the focus shadow
                    // FIXME: use theme(formControl.height)
                    min-height: calc(4rem - 2px);
                  }
                `}
              />
              <Button
                busy={isExportLoading}
                disabled={!selectedTemplate || isExportLoading}
                onClick={() =>
                  requestDownloadUrl({
                    exportType: 'DETAILED',
                    filter: {
                      worksheetTemplate: [selectedTemplate!.id],
                      archive: [archive.id],
                    },
                  })
                }
                variant="primary"
              >
                Export
              </Button>
            </>
          )}
        </FormControlWithAddon>
      </Layout>
    </Form.FieldSet>
  );
}

export default createFragmentContainer(WorksheetsExportControl, {
  archive: graphql`
    fragment WorksheetsExportControl_archive on Archive {
      id
      organization {
        worksheetTemplateConnection {
          edges {
            node {
              id
              latestVersion {
                title
              }
            }
          }
        }
      }
    }
  `,
  tenant: graphql`
    fragment WorksheetsExportControl_tenant on TenantInterface {
      id
      ...useStudyStatisticsExport_tenant
    }
  `,
});
