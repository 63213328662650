import Layout from '@4c/layout';
import Form from '@bfly/ui2/Form';
import Text from '@bfly/ui2/Text';
import clsx from 'clsx';
import React, { ChangeEvent } from 'react';
import { FormattedMessage, defineMessage, useIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import { useVariation } from 'components/LaunchDarklyContext';
import PermissionTooltip from 'components/PermissionTooltip';
import { usePermissions } from 'components/PermissionsProvider';

import { DomainMemberDetailFieldSet_domain$data as Domain } from './__generated__/DomainMemberDetailFieldSet_domain.graphql';
import { DomainMemberDetailFieldSet_domainUser$data as DomainUser } from './__generated__/DomainMemberDetailFieldSet_domainUser.graphql';

interface Props {
  fluid?: boolean;
  domainUser: DomainUser | null;
  domain: Domain;
}

/** takes up a whole line like a block element but the label only extends to the end of the text */
function StubbyCheckboxField({
  containerClassName,
  children,
  subtitle,
  ...props
}: any) {
  return (
    <div className={containerClassName}>
      <Form.Field
        {...props}
        className={clsx(
          props.className,
          'mt-2 inline-flex items-center justify-start',
        )}
      >
        <div>{children}</div>
        <Text variant="sm" color="subtitle">
          {subtitle}
        </Text>
      </Form.Field>
    </div>
  );
}

const eventValueToBoolean = (e: ChangeEvent<HTMLInputElement>) =>
  e.target.value === 'true';

function DomainMemberDetailFieldSet({ domain, domainUser, fluid }: Props) {
  const { formatMessage } = useIntl();

  const hasNurses = useVariation('has-nurses');

  const { hasAdminPermission } = usePermissions();
  const hasPermission = hasAdminPermission('userManagement');

  return (
    <>
      <Form.FieldSet
        legend={formatMessage(
          defineMessage({
            id: 'domainMemberDetailFieldSet.legend',
            defaultMessage: 'Details',
          }),
        )}
      >
        <PermissionTooltip hide={hasPermission} wrap>
          <Form.FieldGroup
            fluid={fluid}
            name="email"
            validateOn="blur" // we do an "expensive" remote check on this so validate infrequently
            disabled={!!domainUser || !hasPermission}
            label={defineMessage({
              id: 'domainMemberDetailFieldSet.email',
              defaultMessage: 'Email',
            })}
            data-bni-id="DomainUserEmailDetail"
          />
        </PermissionTooltip>

        <PermissionTooltip hide={hasPermission} wrap>
          <Form.FieldGroup
            fluid={fluid}
            name="integrationDisplayNameFirst"
            disabled={!hasPermission}
            label={defineMessage({
              id: 'domainMemberDetailFieldSet.nameFirst',
              defaultMessage: 'First name (optional)',
            })}
          />
        </PermissionTooltip>

        <PermissionTooltip hide={hasPermission} wrap>
          <Form.FieldGroup
            fluid={fluid}
            name="integrationDisplayNameMiddle"
            disabled={!hasPermission}
            label={defineMessage({
              id: 'domainMemberDetailFieldSet.nameMiddle',
              defaultMessage: 'Middle initial (optional)',
            })}
          />
        </PermissionTooltip>

        <PermissionTooltip hide={hasPermission} wrap>
          <Form.FieldGroup
            fluid={fluid}
            name="integrationDisplayNameLast"
            disabled={!hasPermission}
            label={defineMessage({
              id: 'domainMemberDetailFieldSet.nameLast',
              defaultMessage: 'Last name (optional)',
            })}
          />
        </PermissionTooltip>

        {hasNurses && (
          <PermissionTooltip wrap>
            <StubbyCheckboxField
              disabled
              name="isNurse"
              subtitle={
                <FormattedMessage
                  id="domainMemberDetailFieldSet.isNurseSubtitle"
                  defaultMessage="Member is a nurse."
                />
              }
            >
              <FormattedMessage
                id="domainMemberDetailFieldSet.isNurse"
                defaultMessage="Is a nurse"
              />
            </StubbyCheckboxField>
          </PermissionTooltip>
        )}
      </Form.FieldSet>
      {domain.enableSamlLogin && domain.enableButterflyLogin && !domainUser && (
        <Form.Group
          data-bni-id="loginChoiceForm"
          className="items-start"
          label={defineMessage({
            id: 'domainMemberDetailFieldSet.loginOptions',
            defaultMessage: 'Login type',
          })}
        >
          <Layout direction="column">
            <Form.Field
              type="radio"
              name="createButterflyLogin"
              value={false}
              mapFromValue={eventValueToBoolean}
            >
              <FormattedMessage
                id="domainMemberDetailFieldSet.ssoLogin"
                defaultMessage="SSO (recommended)"
              />
            </Form.Field>
            <Form.Field
              type="radio"
              name="createButterflyLogin"
              // eslint-disable-next-line react/jsx-boolean-value
              value={true}
              mapFromValue={eventValueToBoolean}
            >
              <div>
                <FormattedMessage
                  id="domainMemberDetailFieldSet.bflyLogin"
                  defaultMessage="Butterfly login"
                />
              </div>
              <Text variant="sm" color="subtitle">
                <FormattedMessage
                  id="domainMemberDetailFieldSet.bflyLoginSubtitle"
                  defaultMessage="The user will be required to accept an invite"
                />
              </Text>
            </Form.Field>
          </Layout>
        </Form.Group>
      )}
    </>
  );
}

export default createFragmentContainer(DomainMemberDetailFieldSet, {
  domain: graphql`
    fragment DomainMemberDetailFieldSet_domain on Domain {
      enableButterflyLogin
      enableSamlLogin
    }
  `,
  domainUser: graphql`
    fragment DomainMemberDetailFieldSet_domainUser on DomainUser {
      id
      email
      type
      canQa
      canFinalize
      isNurse
      integrationDisplayNameFirst
      integrationDisplayNameMiddle
      integrationDisplayNameLast
    }
  `,
});
