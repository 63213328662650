/**
 * @generated SignedSource<<81834f82a38ba53836ccc4f04eedd82b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PermissionLevel = "ADMIN" | "BASIC" | "NONE" | "%future added value";
export type domainMembers_DomainMemberPage_Query$variables = {
  domainUserId: string;
};
export type domainMembers_DomainMemberPage_Query$data = {
  readonly domainUser: {
    readonly " $fragmentSpreads": FragmentRefs<"DomainMemberPage_domainUser">;
  } | null;
  readonly viewer: {
    readonly domain: {
      readonly viewerPermissions: {
        readonly userManagement: PermissionLevel | null;
      } | null;
    } | null;
  } | null;
};
export type domainMembers_DomainMemberPage_Query = {
  response: domainMembers_DomainMemberPage_Query$data;
  variables: domainMembers_DomainMemberPage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "domainUserId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "domainUserId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "ViewerPermissions",
  "kind": "LinkedField",
  "name": "viewerPermissions",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "userManagement",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "domainMembers_DomainMemberPage_Query",
    "selections": [
      {
        "alias": "domainUser",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DomainMemberPage_domainUser"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Domain",
            "kind": "LinkedField",
            "name": "domain",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "domainMembers_DomainMemberPage_Query",
    "selections": [
      {
        "alias": "domainUser",
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserProfile",
                "kind": "LinkedField",
                "name": "profile",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "integrationDisplayNameFirst",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "integrationDisplayNameMiddle",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "integrationDisplayNameLast",
                "storageKey": null
              }
            ],
            "type": "DomainUser",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Domain",
            "kind": "LinkedField",
            "name": "domain",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a67f7c733a32ee45a1a07a569d214ea0",
    "id": null,
    "metadata": {},
    "name": "domainMembers_DomainMemberPage_Query",
    "operationKind": "query",
    "text": "query domainMembers_DomainMemberPage_Query(\n  $domainUserId: ID!\n) {\n  domainUser: node(id: $domainUserId) {\n    __typename\n    ...DomainMemberPage_domainUser\n    id\n  }\n  viewer {\n    domain {\n      viewerPermissions {\n        userManagement\n      }\n      id\n    }\n    id\n  }\n}\n\nfragment DomainMemberPage_domainUser on DomainUser {\n  id\n  profile {\n    name\n    id\n  }\n  ...formatDomainUserName_domainUser\n}\n\nfragment formatDomainUserName_domainUser on DomainUser {\n  integrationDisplayNameFirst\n  integrationDisplayNameMiddle\n  integrationDisplayNameLast\n  profile {\n    name\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "053ffaf55540c2b9cc47616c7684b71b";

export default node;
