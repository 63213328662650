import { HttpError } from 'found';
import { graphql } from 'react-relay';

import Route, { chainPrerenders } from 'components/Route';
import { checkFlagsOr404 } from 'utils/RouteAccessControl';

import { studyProtocols_checkIsAdmin_Query as CheckIsAdminQuery } from './__generated__/studyProtocols_checkIsAdmin_Query.graphql';
import { StudyProtocolType } from './components/helpers/studyProtocolHelpers';

export const organizationStudyProtocolsRoute = (
  <Route<CheckIsAdminQuery>
    path="study-protocols"
    query={graphql`
      query studyProtocols_checkIsAdmin_Query($organizationSlug: String!) {
        organization: organizationBySlug(slug: $organizationSlug) {
          viewerPermissions {
            studyDocumentation
          }
        }
      }
    `}
    prerender={chainPrerenders(
      checkFlagsOr404('study-protocols'),
      (renderArgs) => {
        const { props } = renderArgs;
        if (!props) return;
        if (
          props.organization?.viewerPermissions?.studyDocumentation === 'NONE'
        ) {
          throw new HttpError(404);
        }
      },
    )}
  >
    <Route
      getComponent={() =>
        import(
          /* webpackChunkName: "admin" */ './components/StudyProtocolsPage'
        ).then((m) => m.default)
      }
      query={graphql`
        query studyProtocols_StudyProtocolsPage_Query(
          $organizationSlug: String!
        ) {
          organization: organizationBySlug(slug: $organizationSlug) {
            ...StudyProtocolsPage_tenant
            ...StudyProtocolsPage_organization
          }
        }
      `}
      renderFetched={({ Component, props, match }) => (
        <Component {...props} match={match} tenant={props.organization} />
      )}
    />
    <Route
      path=":studyProtocolHandle"
      getComponent={() =>
        import(
          /* webpackChunkName: "admin" */ './components/StudyProtocolPage'
        ).then((m) => m.default)
      }
      query={graphql`
        query studyProtocols_StudyProtocolPage_Query(
          $studyProtocolHandle: String!
          $organizationSlug: String!
        ) {
          studyProtocol(handle: $studyProtocolHandle) {
            ...StudyProtocolPage_studyProtocol
          }
          organization: organizationBySlug(slug: $organizationSlug) {
            id
          }
        }
      `}
    />
    <Route
      path="-/workflow/new"
      getComponent={() =>
        import(
          /* webpackChunkName: "admin" */ './components/CreateStudyProtocolPage'
        ).then((m) => m.default)
      }
      renderFetched={({ Component, props, match }) => (
        <Component
          {...props}
          match={match}
          type={StudyProtocolType.WORKFLOW}
        />
      )}
      query={graphql`
        query studyProtocols_CreateStudyWorkflowProtocolPage_Query(
          $organizationSlug: String!
        ) {
          tenant: organizationBySlug(slug: $organizationSlug) {
            id
            ...CreateStudyProtocolPage_tenant
          }
        }
      `}
    />
    <Route
      path="-/indication/new"
      getComponent={() =>
        import(
          /* webpackChunkName: "admin" */ './components/CreateStudyProtocolPage'
        ).then((m) => m.default)
      }
      renderFetched={({ Component, props, match }) => (
        <Component
          {...props}
          match={match}
          type={StudyProtocolType.INDICATION}
        />
      )}
      query={graphql`
        query studyProtocols_CreateStudyIndicationProtocolPage_Query(
          $organizationSlug: String!
        ) {
          tenant: organizationBySlug(slug: $organizationSlug) {
            id
            ...CreateStudyProtocolPage_tenant
          }
        }
      `}
    />
  </Route>
);
