import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import { PermissionsProvider_viewer$data as Viewer } from 'components/__generated__/PermissionsProvider_viewer.graphql';
import { PermissionsProvider_viewerPermissions$data as ViewerPermissions } from 'components/__generated__/PermissionsProvider_viewerPermissions.graphql';
import {
  Permission,
  PermissionLevel,
  checkPermission,
} from 'utils/checkPermission';

export type { ViewerPermissions };

interface PermissionsContextValues {
  viewerProfileId?: string;
  hasPermission: (
    permission: Permission,
    permissionLevel: PermissionLevel,
  ) => boolean;
  hasBasicPermission: (permission: Permission) => boolean;
  hasAdminPermission: (permission: Permission) => boolean;
}

export const PermissionsContext =
  React.createContext<PermissionsContextValues | null>(null);

function PermissionsProvider({
  viewer,
  viewerPermissions,
  children,
}: PropsWithChildren<{
  viewer: Viewer | null;
  viewerPermissions?: ViewerPermissions | null;
}>) {
  const viewerProfileId = viewer?.profile?.id;

  const hasPermission = useCallback(
    (permission: Permission, requestedLevel: PermissionLevel): boolean => {
      if (!viewerPermissions) {
        return false;
      }

      return checkPermission(viewerPermissions, permission, requestedLevel);
    },
    [viewerPermissions],
  );

  const hasBasicPermission = useCallback(
    (permission: Permission) => hasPermission(permission, 'BASIC'),
    [hasPermission],
  );

  const hasAdminPermission = useCallback(
    (permission: Permission) => hasPermission(permission, 'ADMIN'),
    [hasPermission],
  );

  return (
    <PermissionsContext.Provider
      value={useMemo(
        () => ({
          viewerProfileId,
          hasPermission,
          hasBasicPermission,
          hasAdminPermission,
        }),
        [
          hasAdminPermission,
          hasBasicPermission,
          hasPermission,
          viewerProfileId,
        ],
      )}
    >
      {children}
    </PermissionsContext.Provider>
  );
}

export function usePermissions(safe?: boolean): PermissionsContextValues {
  const context = useContext(PermissionsContext);

  if (!context) {
    if (safe)
      return {
        hasAdminPermission: () => false,
        hasBasicPermission: () => false,
        hasPermission: () => false,
      };
    throw new Error('usePermissions must be called within PermissionsContext');
  }

  return context;
}

export default createFragmentContainer(PermissionsProvider, {
  viewer: graphql`
    fragment PermissionsProvider_viewer on Viewer {
      profile {
        id
      }
    }
  `,
  viewerPermissions: graphql`
    fragment PermissionsProvider_viewerPermissions on ViewerPermissions {
      ...checkPermission_viewerPermissions
    }
  `,
});
