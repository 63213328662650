import FeatureFlags, { LDClient } from 'config/FeatureFlags.d';
import featureFlagDefaults from 'config/featureFlagDefaults';
import { LocalStore } from 'utils/localStore';
import { Viewer } from 'utils/viewerState';

import register from './test-mock';

export const featureFlagOverridesStore = LocalStore<Partial<FeatureFlags>>(
  'bfly:featureFlagOverrides',
);
export const featureFlagsStore = LocalStore<FeatureFlags>('bfly:featureFlags');

/**
 * This function takes the viewer object and returns the
 * merged state of the feature flags from the the public cloud user's organizations
 */
export function stateMergedFromViewer(
  viewer: Viewer | null | undefined,
): Record<string, any> {
  const nextFlagState: Record<string, any> = {};

  viewer?.memberships?.forEach((membership) => {
    Object.entries(
      membership?.organization?.viewerLaunchDarklyConfig?.state || {},
    ).forEach(([key, value]) => {
      if (nextFlagState[key] === true || nextFlagState[key] === 'enabled')
        return; // already at the highest level continue

      nextFlagState[key] = value;
    });
  });

  return nextFlagState;
}

export default class LaunchDarklyManager {
  client: LDClient | null = null;

  overrides = featureFlagOverridesStore.get({});

  public static instance: LaunchDarklyManager | null = null;

  public static getInstance(): LaunchDarklyManager {
    if (!LaunchDarklyManager.instance) {
      LaunchDarklyManager.instance = new LaunchDarklyManager();
    }
    return LaunchDarklyManager.instance;
  }

  static clear() {
    LaunchDarklyManager.instance = new LaunchDarklyManager();
    featureFlagsStore.clear();
  }

  constructor() {
    featureFlagOverridesStore.subscribe((next) => {
      if (this.client) this.overrides = next || {};
    });
  }

  init(
    flagState: Record<string, any>,
    flagStateMerged?: Record<string, any> | null,
  ): LDClient {
    const getVariation = (state: Record<string, any>) => {
      return <TKey extends keyof FeatureFlags>(key: TKey) => {
        if (
          !bflyConfig.IS_PROD &&
          Object.prototype.hasOwnProperty.call(this.overrides, key)
        ) {
          return this.overrides[key];
        }

        if (Object.prototype.hasOwnProperty.call(state, key))
          return state[key];

        return featureFlagDefaults[key];
      };
    };

    this.client = {
      variation: getVariation(flagState),
      variationAnyOrganization: getVariation(flagStateMerged || flagState),
    };

    featureFlagsStore.set(flagState as FeatureFlags);

    return this.client;
  }
}

register(LaunchDarklyManager);
