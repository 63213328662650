import { graphql } from 'react-relay';

import Route, { chainPrerenders } from 'components/Route';
import { checkTenantPermission } from 'utils/RouteAccessControl';

import { inactivity_InactivityTimeoutPageQuery as InactivityTimeoutPageQuery } from './__generated__/inactivity_InactivityTimeoutPageQuery.graphql';

export default (
  <Route<InactivityTimeoutPageQuery>
    path="inactivity"
    getComponent={() =>
      import(
        /* webpackChunkName: "admin" */ './components/InactivityTimeoutPage'
      ).then((m) => m.default)
    }
    query={graphql`
      query inactivity_InactivityTimeoutPageQuery {
        viewer {
          ...InactivityTimeoutPage_viewer
        }
        tenant {
          viewerPermissions {
            ...RouteAccessControl_viewerPermissions
          }
          ...RouteAccessControl_tenant
        }
      }
    `}
    prerender={chainPrerenders(
      checkTenantPermission<InactivityTimeoutPageQuery['response']>(
        'loginAndSecurityManagement',
        'BASIC',
      ),
    )}
  />
);
