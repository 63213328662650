import Layout from '@4c/layout';
import CheckMedalIcon from '@bfly/icons/CheckMedal';
import Caret from '@bfly/ui2/Caret';
import Combobox from '@bfly/ui2/Combobox';
import Dropdown, { DropdownMenuProps } from '@bfly/ui2/Dropdown';
import MeatballDropdownButton from '@bfly/ui2/MeatballDropdownButton';
import Text from '@bfly/ui2/Text';
import useQuery from '@bfly/ui2/useQuery';
import getNodes from '@bfly/utils/getNodes';
import notNullish from '@bfly/utils/notNullish';
import { ConnectionNodeType } from '@bfly/utils/types';
import useCallbackRef from '@restart/hooks/useCallbackRef';
import { ToggleMetadata } from '@restart/ui/Dropdown';
import { css } from 'astroturf';
import clsx from 'clsx';
import { useEffect, useMemo, useState } from 'react';
import { FormattedMessage, defineMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import Avatar from 'components/Avatar';
import { useVariation } from 'components/LaunchDarklyContext';
import PopoverList from 'components/PopoverList';
import RelatedUsersPopoverListItems from 'components/RelatedUsersPopoverListItems';
import useSearchQuery from 'hooks/useSearchQuery';
import useToggler from 'hooks/useToggler';
import Analytics from 'utils/Analytics';
import { useViewerContext } from 'utils/viewerState';

import ListCardContainer from './ListCardContainer';
import { renderUserSuggestion } from './UserSuggestionListItem';
import { ExamAuthorsFieldUsers_Query as InitialQuery } from './__generated__/ExamAuthorsFieldUsers_Query.graphql';
import { ExamAuthorsField_Query as Query } from './__generated__/ExamAuthorsField_Query.graphql';
import { ExamAuthorsField_users$data as Users } from './__generated__/ExamAuthorsField_users.graphql';

export type ArchiveUser = ConnectionNodeType<
  NonNullable<Query['response']['archive']>,
  'membershipConnection'
>;

function useUserSearchQuery(archiveId: string, search: string | null) {
  return useSearchQuery<Query>(
    graphql`
      query ExamAuthorsField_Query($archiveId: ID!, $search: String) {
        archive: node(id: $archiveId) {
          ... on Archive {
            membershipConnection(first: 30, search: $search)
              @connection(key: "Archive_membershipConnection") {
              edges {
                node {
                  id
                  ...UserSuggestionListItem_userInfo
                  userProfile {
                    id
                    name
                    ...Avatar_userProfile
                    ...RelatedUsersPopoverListItems_users
                  }
                }
              }
            }
          }
        }
      }
    `,
    search,
    { archiveId },
  );
}

type User = Users[number];

export interface Authors {
  createdBy: string | undefined;
  secondaryAuthors: string[];
  transcribedBy: string | undefined;
}

export type Actions =
  | 'select'
  | 'toggleScribe'
  | 'removeAuthor'
  | 'setPrimaryAuthor';

interface Props {
  // XXX: ExamAuthorsField accepts IDs rather than `study` because it gets wrapped
  // by ExamAuthorsFormField field, which isn't a fragment container and therefore can't
  // accept relay types
  studyId: string;
  archiveId: string;
  organizationId: string;
  readOnly?: boolean;
  value: Authors;
  showMenu?: boolean;
  placement?: DropdownMenuProps['placement'];
  onChangeAction: (user: User, action: Actions) => void;
  users: Users;
  loading?: boolean;
  showDefaultUser?: boolean;
}

function ExamAuthorsField({
  studyId,
  archiveId,
  organizationId,
  readOnly = false,
  placement = 'bottom-start',
  onChangeAction,
  users,
  showDefaultUser,
  ...props
}: Props) {
  const showingDefaultUser = !!showDefaultUser && !users.length;

  const authors = {
    ...props.value,
    secondaryAuthors: props.value.secondaryAuthors.filter(
      (id) => id !== props.value.transcribedBy,
    ),
  };

  const createdBy = users.find((u) => u.id === authors.createdBy);

  const canUseMultipleAuthors = useVariation('multiple-study-authors');

  // allow multiple author controls if
  // flag enabled
  // OR there is no primary author
  // OR there is more than one user
  const allowMultipleAuthors =
    canUseMultipleAuthors || !createdBy || users.length > 1;

  const canUseScribes = useVariation('study-scribes') && canUseMultipleAuthors;

  const [showMenu, toggleShowMenu] = useToggler(!!props.showMenu);

  const viewer = useViewerContext();

  const [search, setSearch] = useState('');
  const { data, loading: searchLoading } = useUserSearchQuery(
    archiveId,
    !search && showingDefaultUser ? viewer.email : search,
  );

  const [showSearchMenu, toggleShowSearchMenu] = useToggler();

  const archiveMemberships: ArchiveUser[] = useMemo(() => {
    const membershipConnection = data?.archive?.membershipConnection;
    return membershipConnection ? getNodes(membershipConnection) : [];
  }, [data?.archive]);

  const handleSearchChange = (value: string) => {
    if (typeof value === 'string') {
      setSearch(value);
    }
  };

  const handleSearchSelect = (nextValue: ArchiveUser) => {
    setSearch('');
    const user = nextValue.userProfile as User;
    onChangeAction(user, 'select');
  };

  const initialCreatedBy = users.find((u) => u.id === props.value.createdBy);

  const transcribedBy = users.find((u) => u.id === authors.transcribedBy);
  const secondaryAuthors = users.filter(
    (u) => u.id !== authors.transcribedBy && u.id !== authors.createdBy,
  );

  const numSecondaryAuthors =
    secondaryAuthors.length || 0 + (transcribedBy ? 1 : 0);

  const [dropdownElement, attachRef] = useCallbackRef<HTMLElement>();
  useEffect(() => {
    if (showMenu && !users.length) {
      setTimeout(() => {
        dropdownElement?.querySelector('input')?.focus();
      }, 100);
    }
  }, [dropdownElement, showMenu, users]);

  const handleToggle = (show: boolean, meta: ToggleMetadata) => {
    const within = dropdownElement!.contains(
      meta.originalEvent?.currentTarget as HTMLElement,
    );
    const nextShow = within || show;
    if (!nextShow) setSearch('');
    toggleShowMenu(nextShow);
    if (showMenu !== nextShow) {
      const event = nextShow ? 'authorFieldOpened' : 'authorFieldDismissed';
      Analytics.track(event, {
        studyId,
        organizationId,
      });
    }
  };

  return (
    <PopoverList
      variant="dark"
      // hide if there are no other authors
      show={
        !showMenu && allowMultipleAuthors && users?.length ? undefined : false
      }
      listItems={
        <RelatedUsersPopoverListItems
          users={users}
          createdBy={props.value.createdBy}
          transcribedBy={props.value.transcribedBy}
        />
      }
    >
      <Dropdown
        ref={attachRef}
        placement={placement}
        show={showMenu}
        onToggle={handleToggle}
      >
        <Dropdown.Toggle
          variant="text-secondary"
          data-bni-id="ManageStudyAuthorsButton"
          className="rounded bg-grey-80 px-3 justify-start w-full max-w-[max-content] lg:max-w-sm"
        >
          {initialCreatedBy ? (
            <>
              <Avatar
                width={20}
                className="mr-1"
                userProfile={initialCreatedBy}
              />
              {allowMultipleAuthors ? (
                <>
                  <span className="truncate">{initialCreatedBy.name}</span>
                  <CheckMedalIcon className="ml-1 flex-none" height={14} />
                </>
              ) : (
                initialCreatedBy.name
              )}
            </>
          ) : (
            <Text color="body" className="text-body">
              <FormattedMessage
                id="examAuthorsField.none"
                defaultMessage="No Primary"
              />
            </Text>
          )}
          {!!numSecondaryAuthors && (
            <strong className="ml-1">
              <FormattedMessage
                id="examAuthorsField.secondaryAuthors"
                defaultMessage="+{numSecondaryAuthors}"
                values={{ numSecondaryAuthors }}
              />
            </strong>
          )}
          <Caret className="ml-4 text-headline w-[7px]" flipped={showMenu} />
        </Dropdown.Toggle>
        <Dropdown.Menu variant="dark" className="pt-0 pb-2 w-80">
          {!readOnly && (
            <Combobox<ArchiveUser>
              focusFirstItem
              menuVariant="dark"
              variant="secondary"
              data={archiveMemberships.filter(
                (m) => !users.find((u) => u.id === m.userProfile?.id),
              )}
              textField="email"
              filter={false}
              renderListItem={renderUserSuggestion}
              data-bni-id="StudyAuthorsSearchControl"
              placeholder={defineMessage({
                id: 'ExamAuthorsField.placeholder',
                defaultMessage: 'Search author by name',
              })}
              busy={props.loading || searchLoading}
              value={search}
              onChange={handleSearchChange}
              onSelect={handleSearchSelect}
              className={clsx(
                'pt-3 px-3',
                css`
                  & :global(.rw-popup-container) {
                    right: 2px;
                    left: 2px;
                  }
                `,
              )}
              open={showSearchMenu || showingDefaultUser}
              onToggle={toggleShowSearchMenu}
            />
          )}
          <ListCardContainer
            data-bni-id="StudyAuthorsList"
            variant={null}
            className="min-h-auto px-1 mt-2 overflow-x-hidden"
          >
            {!users.length && (
              <Layout direction="column" className="px-3">
                <Text variant="body" color="subtitle">
                  <FormattedMessage
                    id="examAuthorsField.noAuthorSelected"
                    defaultMessage="No authors selected"
                  />
                </Text>
              </Layout>
            )}
            {[createdBy, ...secondaryAuthors, transcribedBy].map((user) => {
              if (!user) return null;

              const isScribe = user!.id === transcribedBy?.id;
              const isPrimary = user!.id === createdBy?.id;
              return (
                <Layout align="center" pad key={user.id}>
                  <Avatar width={24} userProfile={user} className="ml-2" />
                  <Layout
                    align="center"
                    justify="space-between"
                    grow
                    className="py-2 ml-2"
                  >
                    <Layout align="center" justify="center">
                      <Text
                        variant="body-bold"
                        color="headline"
                        className="overflow-ellipsis"
                      >
                        {user!.name}
                      </Text>
                      {isPrimary && (
                        <CheckMedalIcon
                          className="ml-2 flex-none text-headline"
                          height={15}
                        />
                      )}
                    </Layout>
                    {allowMultipleAuthors && (
                      <Layout
                        pad
                        align="center"
                        className="ml-2 flex-shrink-0"
                      >
                        {isPrimary && (
                          <Text color="subtitle">
                            <FormattedMessage
                              id="examAuthorsField.primary"
                              defaultMessage="Primary"
                            />
                          </Text>
                        )}
                        {canUseScribes && isScribe && (
                          <Text color="subtitle">
                            <FormattedMessage
                              id="examAuthorsField.scribe"
                              defaultMessage="Scribe"
                            />
                          </Text>
                        )}
                        {!readOnly && (
                          <MeatballDropdownButton
                            data-bni-id="ExamAuthorsFieldListItemSettings"
                            id={`author-actions-${user.id}`}
                            popperConfig={{ strategy: 'fixed' }}
                          >
                            {!isPrimary && !isScribe && (
                              <Dropdown.Item
                                onClick={() =>
                                  onChangeAction(user, 'setPrimaryAuthor')
                                }
                              >
                                <FormattedMessage
                                  id="examAuthorsField.action.setPrimary"
                                  defaultMessage="Set as primary author"
                                />
                              </Dropdown.Item>
                            )}
                            {!isPrimary && canUseScribes && (
                              <Dropdown.Item
                                onClick={() =>
                                  onChangeAction(user, 'toggleScribe')
                                }
                              >
                                {isScribe ? (
                                  <FormattedMessage
                                    id="examAuthorsField.action.unsetScribe"
                                    defaultMessage="Remove study scribe"
                                  />
                                ) : (
                                  <FormattedMessage
                                    id="examAuthorsField.action.setScribe"
                                    defaultMessage="Set as study scribe"
                                  />
                                )}
                              </Dropdown.Item>
                            )}
                            <Dropdown.Divider />
                            <Dropdown.Item
                              variant="danger"
                              onClick={() =>
                                onChangeAction(user, 'removeAuthor')
                              }
                            >
                              <div>
                                <FormattedMessage
                                  id="examAuthorsField.action.delete"
                                  defaultMessage="Remove author"
                                />
                              </div>
                            </Dropdown.Item>
                          </MeatballDropdownButton>
                        )}
                      </Layout>
                    )}
                  </Layout>
                </Layout>
              );
            })}
          </ListCardContainer>
        </Dropdown.Menu>
      </Dropdown>
    </PopoverList>
  );
}

export default createFragmentContainer(ExamAuthorsField, {
  users: graphql`
    fragment ExamAuthorsField_users on UserProfile @relay(plural: true) {
      id
      name
      ...Avatar_userProfile
      ...RelatedUsersPopoverListItems_users
    }
  `,
});

interface FormProps extends Omit<Props, 'users' | 'onChangeAction'> {
  onChange: (value: Authors) => void;
}

export function ExamAuthorsFormField({
  onChange,
  value,
  ...props
}: FormProps) {
  const userIds: string[] = useMemo(
    () =>
      Object.values(value)
        .flat()
        .filter(
          (userId, index, arr) =>
            userId && index === arr.findIndex((u) => u === userId),
        ),
    [value],
  );

  const { data: usersData } = useQuery<InitialQuery>(
    graphql`
      query ExamAuthorsFieldUsers_Query($ids: [ID!]!) {
        users: nodes(ids: $ids) {
          ... on UserProfile {
            id
            name
            ...Avatar_userProfile
            ...RelatedUsersPopoverListItems_users
          }
        }
      }
    `,
    {
      fetchPolicy: 'store-and-network',
      variables: {
        ids: userIds,
      },
      skip: userIds.length === 0,
    },
  );

  const [users, setUsers] = useState<Users>([]);

  useEffect(() => {
    if (!usersData?.users.length) return;
    const newUsers = usersData.users.filter(notNullish) as unknown as Users;
    setUsers(newUsers);
  }, [usersData?.users]);

  const onToggleScribe = (user: User, authors: FormProps['value']) => {
    const userId = user.id;
    const newAuthors = {
      ...authors,
      secondaryAuthors: [...authors.secondaryAuthors],
    };

    // Remove

    if (authors.transcribedBy === userId) {
      newAuthors.transcribedBy = undefined;
      newAuthors.secondaryAuthors = [...newAuthors.secondaryAuthors, userId];
      onChange(newAuthors);
      return;
    }

    // Add

    if (newAuthors.transcribedBy)
      // move existing transcribedBy to secondary
      newAuthors.secondaryAuthors = [
        ...newAuthors.secondaryAuthors,
        newAuthors.transcribedBy,
      ];

    newAuthors.transcribedBy = userId;
    onChange(newAuthors);
  };

  const onRemoveAuthor = (user: User, authors: FormProps['value']) => {
    const userId = user.id;
    const { createdBy, transcribedBy, secondaryAuthors } = authors;

    onChange({
      createdBy: createdBy === userId ? undefined : createdBy,
      transcribedBy: transcribedBy === userId ? undefined : transcribedBy,
      secondaryAuthors: secondaryAuthors.filter((id) => id !== userId),
    });

    setUsers((prevUsers) => prevUsers.filter((u) => u.id !== userId));
  };

  const onSetPrimaryAuthor = (user: User, authors: FormProps['value']) => {
    const userId = user.id;
    let { createdBy, transcribedBy, secondaryAuthors } = authors;

    // if user was a scribe remove them from that role
    if (transcribedBy === userId) transcribedBy = undefined;

    // if user was secondary remove them from that role
    secondaryAuthors = secondaryAuthors.filter((id) => id !== userId);

    // move existing primary to secondary
    if (createdBy && createdBy !== userId) {
      secondaryAuthors = [createdBy, ...secondaryAuthors];
    }

    onChange({
      createdBy: userId,
      transcribedBy,
      secondaryAuthors,
    });
  };

  const onSelect = (user: User, authors: FormProps['value']) => {
    setUsers((prevUsers) => [
      ...prevUsers.filter((u) => u.id !== user.id),
      user,
    ]);

    if (!authors.createdBy) {
      onSetPrimaryAuthor(user, authors);
      return;
    }

    onChange({
      ...authors,
      secondaryAuthors: [
        ...authors.secondaryAuthors.filter((id) => user.id !== id),
        user.id,
      ],
    });
  };

  const actions: {
    [key in Actions]: (user: User, authors: FormProps['value']) => void;
  } = {
    toggleScribe: onToggleScribe,
    removeAuthor: onRemoveAuthor,
    setPrimaryAuthor: onSetPrimaryAuthor,
    select: onSelect,
  };

  return (
    <ExamAuthorsField
      users={users}
      value={value}
      {...props}
      onChangeAction={(user, action) => actions[action](user, value)}
    />
  );
}
