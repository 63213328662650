import Layout from '@4c/layout';
import Caret from '@bfly/icons/Caret';
import Dropdown from '@bfly/ui2/Dropdown';
import Page from '@bfly/ui2/Page';
import Text from '@bfly/ui2/Text';
import Tooltip from '@bfly/ui2/Tooltip';
import { ReactElement } from 'react';
import { FormattedMessage, defineMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import HistoricalProficiencyLink from 'components/HistoricalProficiencyLink';
import PageTitle from 'components/PageTitle';
import actionMessages from 'messages/actions';
import { StudyStatus } from 'utils/StudyConstants';

import { serialize, useStatsFilters } from '../schemas/statsFilters';
import useStudyStatisticsExport from '../utils/useStudyStatisticsExport';
import AnalyticsPageStickySection from './AnalyticsPageStickySection';
import StatsFilters from './StatsFilters';
import { AllTimeStatsPage_tenant$data as Tenant } from './__generated__/AllTimeStatsPage_tenant.graphql';

interface AllTimeStatsPageProps {
  tenant: Tenant;
  usersList?: ReactElement;
  topLevelStats?: ReactElement;
}

function AllTimeStatsPage({
  tenant,
  usersList,
  topLevelStats,
}: AllTimeStatsPageProps) {
  const filters = useStatsFilters();

  const [requestDetailedDownloadUrl, isDetailedExportLoading] =
    useStudyStatisticsExport({
      filter: serialize({
        ...filters,
        status: [StudyStatus.REVIEWED],
      }),
      tenant,
    });
  const [requestSimpleDownloadUrl, isSimpleExportLoading] =
    useStudyStatisticsExport({
      filter: serialize({
        ...filters,
      }),
      tenant,
    });

  const isExportLoading = isSimpleExportLoading || isDetailedExportLoading;
  const hasQaPerms = tenant?.viewerPermissions?.qa === 'BASIC';

  return (
    <div data-bni-id="AllTimeStatsPageContainer" className="w-[fit-content]">
      <AnalyticsPageStickySection pad={2} direction="column" className="z-10">
        <Page.Header className="h-20">
          <PageTitle
            title={defineMessage({
              id: 'AllTimeStatsPage.header',
              defaultMessage: 'Stats',
            })}
            variant="display-sm"
          />
          <HistoricalProficiencyLink />
          <Layout className="ml-auto">
            <StatsFilters tenant={tenant} />
          </Layout>
          {hasQaPerms && (
            <Dropdown
              data-bni-id="ExportDropdownAllTimeStats"
              className="ml-6"
            >
              <Tooltip.Trigger
                tooltip={
                  <div className="text-center">
                    <FormattedMessage
                      id="AllTimeStatsPage.exportTooltip"
                      defaultMessage="Export data from QA reviewed exams"
                    />
                  </div>
                }
                placement="bottom"
              >
                <Dropdown.Toggle
                  variant="primary"
                  busy={isExportLoading}
                  className="pl-app-panel-sm"
                >
                  <FormattedMessage {...actionMessages.export} />
                  <Caret width={8} height={8} className="ml-2" />
                </Dropdown.Toggle>
              </Tooltip.Trigger>
              <Dropdown.Menu className="w-8" variant="dark">
                <Dropdown.Item
                  className="flex flex-col whitespace-normal"
                  onClick={() =>
                    requestSimpleDownloadUrl({ exportType: 'SIMPLE' })
                  }
                  disabled={isExportLoading}
                >
                  <Text variant="body-bold">
                    <FormattedMessage
                      id="AllTimeStatsPage.simpleExport"
                      defaultMessage="Simple export"
                    />
                  </Text>
                  <Text color="subtitle">
                    <FormattedMessage
                      id="AllTimeStatsPage.simpleExportDescription"
                      defaultMessage="Only export data currently shown in the dashboard"
                    />
                  </Text>
                </Dropdown.Item>
                <Dropdown.Item
                  className="flex flex-col whitespace-normal"
                  onClick={() =>
                    requestDetailedDownloadUrl({ exportType: 'DETAILED' })
                  }
                  disabled={isExportLoading}
                >
                  <Text variant="body-bold">
                    <FormattedMessage
                      id="AllTimeStatsPage.detailedExport"
                      defaultMessage="Detailed export"
                    />
                  </Text>
                  <Text color="subtitle">
                    <FormattedMessage
                      id="AllTimeStatsPage.detailedExportDescription"
                      defaultMessage="Export raw data"
                    />
                  </Text>
                </Dropdown.Item>
                <Dropdown.Item
                  className="flex flex-col whitespace-normal"
                  onClick={() =>
                    requestDetailedDownloadUrl({
                      exportType: 'DETAILED',
                      pivot: 'EXAM_TYPE',
                    })
                  }
                  disabled={isExportLoading}
                >
                  <Text variant="body-bold">
                    <FormattedMessage
                      id="AllTimeStatsPage.examTypeExport"
                      defaultMessage="Exam Type Export"
                    />
                  </Text>
                  <Text color="subtitle">
                    <FormattedMessage
                      id="AllTimeStatsPage.examTypeExportDescription"
                      defaultMessage="Export raw data by exam type"
                    />
                  </Text>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </Page.Header>
        {topLevelStats}
      </AnalyticsPageStickySection>
      {usersList}
    </div>
  );
}

export default createFragmentContainer(AllTimeStatsPage, {
  tenant: graphql`
    fragment AllTimeStatsPage_tenant on TenantInterface {
      ...StatsFilters_tenant
      ...useStudyStatisticsExport_tenant
      viewerCanQa
      viewerPermissions {
        qa
      }
    }
  `,
});
