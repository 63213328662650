/**
 * @generated SignedSource<<9e5276d7077befc9fb42127108f165e8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type annotationExtensions_AnnotationExtensionsSettingsPrerenderQuery$variables = {
  organizationSlug: string;
};
export type annotationExtensions_AnnotationExtensionsSettingsPrerenderQuery$data = {
  readonly organization: {
    readonly viewerPermissions: {
      readonly " $fragmentSpreads": FragmentRefs<"RouteAccessControl_viewerPermissions">;
    } | null;
  } | null;
};
export type annotationExtensions_AnnotationExtensionsSettingsPrerenderQuery = {
  response: annotationExtensions_AnnotationExtensionsSettingsPrerenderQuery$data;
  variables: annotationExtensions_AnnotationExtensionsSettingsPrerenderQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationSlug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "organizationSlug"
  }
],
v2 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "accessAllOrganizations",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "archiveManagement",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "bulkDataExport",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "connectivityManagement",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "credentialManagement",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "dataDeletion",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "dataExport",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "educationManagement",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "examTypeManagement",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "fleetManagement",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "iqCareRestrictedExperience",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "loginAndSecurityManagement",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "organizationManagement",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "qa",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "qaManagement",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "signing",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "studyDocumentation",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "studyDocumentationManagement",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "studyReversion",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "userManagement",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "userPermissions",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "annotationExtensions_AnnotationExtensionsSettingsPrerenderQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationBySlug",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ViewerPermissions",
            "kind": "LinkedField",
            "name": "viewerPermissions",
            "plural": false,
            "selections": [
              {
                "kind": "InlineDataFragmentSpread",
                "name": "RouteAccessControl_viewerPermissions",
                "selections": [
                  {
                    "kind": "InlineDataFragmentSpread",
                    "name": "checkPermission_viewerPermissions",
                    "selections": (v2/*: any*/),
                    "args": null,
                    "argumentDefinitions": []
                  }
                ],
                "args": null,
                "argumentDefinitions": []
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "annotationExtensions_AnnotationExtensionsSettingsPrerenderQuery",
    "selections": [
      {
        "alias": "organization",
        "args": (v1/*: any*/),
        "concreteType": "Organization",
        "kind": "LinkedField",
        "name": "organizationBySlug",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ViewerPermissions",
            "kind": "LinkedField",
            "name": "viewerPermissions",
            "plural": false,
            "selections": (v2/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "075fd6642b5933e4658f41a3764219ad",
    "id": null,
    "metadata": {},
    "name": "annotationExtensions_AnnotationExtensionsSettingsPrerenderQuery",
    "operationKind": "query",
    "text": "query annotationExtensions_AnnotationExtensionsSettingsPrerenderQuery(\n  $organizationSlug: String!\n) {\n  organization: organizationBySlug(slug: $organizationSlug) {\n    viewerPermissions {\n      ...RouteAccessControl_viewerPermissions\n    }\n    id\n  }\n}\n\nfragment RouteAccessControl_viewerPermissions on ViewerPermissions {\n  ...checkPermission_viewerPermissions\n}\n\nfragment checkPermission_viewerPermissions on ViewerPermissions {\n  accessAllOrganizations\n  archiveManagement\n  bulkDataExport\n  connectivityManagement\n  credentialManagement\n  dataDeletion\n  dataExport\n  educationManagement\n  examTypeManagement\n  fleetManagement\n  iqCareRestrictedExperience\n  loginAndSecurityManagement\n  organizationManagement\n  qa\n  qaManagement\n  signing\n  studyDocumentation\n  studyDocumentationManagement\n  studyReversion\n  userManagement\n  userPermissions\n}\n"
  }
};
})();

(node as any).hash = "cc3a0792480604c23d0a5845cf1a5315";

export default node;
