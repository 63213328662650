/**
 * @generated SignedSource<<30af6bde3e6d28b0495abdd1a5416917>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
export type PermissionLevel = "ADMIN" | "BASIC" | "NONE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type useStudyFinalization_study$data = {
  readonly archive: {
    readonly attestationMessage: string | null;
    readonly ehrAssociations: ReadonlyArray<{
      readonly __typename: "EhrArchiveAssociation";
    } | null> | null;
    readonly examTypeRequiredToFinalize: boolean | null;
    readonly studyAuthorRequiredToRequestFinalization: boolean | null;
    readonly worklistRequiredToFinalize: boolean | null;
    readonly worksheetRequiredToFinalize: boolean | null;
  } | null;
  readonly createdBy: {
    readonly __typename: "UserProfile";
    readonly id: string;
  } | null;
  readonly examTypes: ReadonlyArray<{
    readonly __typename: "ExamType";
  } | null> | null;
  readonly finalizationRequiresAttestation: boolean | null;
  readonly hasAssociatedWorklistItem: boolean | null;
  readonly hasBeenFinalized: boolean | null;
  readonly id: string;
  readonly isPendingFinalization: boolean | null;
  readonly latestStudyEvent: {
    readonly id?: string;
    readonly type: string;
  } | null;
  readonly organization: {
    readonly id: string;
    readonly viewerCanFinalize: boolean | null;
    readonly viewerPermissions: {
      readonly accessAllOrganizations: PermissionLevel | null;
      readonly archiveManagement: PermissionLevel | null;
      readonly bulkDataExport: PermissionLevel | null;
      readonly connectivityManagement: PermissionLevel | null;
      readonly credentialManagement: PermissionLevel | null;
      readonly dataDeletion: PermissionLevel | null;
      readonly dataExport: PermissionLevel | null;
      readonly educationManagement: PermissionLevel | null;
      readonly examTypeManagement: PermissionLevel | null;
      readonly fleetManagement: PermissionLevel | null;
      readonly iqCareRestrictedExperience: PermissionLevel | null;
      readonly loginAndSecurityManagement: PermissionLevel | null;
      readonly organizationManagement: PermissionLevel | null;
      readonly qa: PermissionLevel | null;
      readonly qaManagement: PermissionLevel | null;
      readonly signing: PermissionLevel | null;
      readonly studyDocumentation: PermissionLevel | null;
      readonly studyDocumentationManagement: PermissionLevel | null;
      readonly studyReversion: PermissionLevel | null;
      readonly userManagement: PermissionLevel | null;
      readonly userPermissions: PermissionLevel | null;
    } | null;
  } | null;
  readonly transcribedBy: {
    readonly __typename: "UserProfile";
    readonly id: string;
  } | null;
  readonly viewerCanFinalize: boolean | null;
  readonly worksheets: ReadonlyArray<{
    readonly __typename: "Worksheet";
  } | null> | null;
  readonly " $fragmentSpreads": FragmentRefs<"StudyFilters_study" | "studyAuthors_study" | "useRefreshStudyDialog_study">;
  readonly " $fragmentType": "useStudyFinalization_study";
};
export type useStudyFinalization_study$key = {
  readonly " $data"?: useStudyFinalization_study$data;
  readonly " $fragmentSpreads": FragmentRefs<"useStudyFinalization_study">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useStudyFinalization_study"
};

(node as any).hash = "5c25c1cd446f4bbc7fe65244507d6c20";

export default node;
