import { graphql } from 'react-relay';

import Route, { chainPrerenders } from 'components/Route';
import {
  checkFlagsOr404,
  checkIsScopedUnderTenantOr404,
  checkTenantPermission,
} from 'utils/RouteAccessControl';

import { examTypes_ExamTypesPageQuery as ExamTypesPageQuery } from './__generated__/examTypes_ExamTypesPageQuery.graphql';
import DomainExamTypesPage from './components/DomainExamTypesPage';

export const organizationExamTypesRoutes = (
  <Route<ExamTypesPageQuery>
    path="exam-types"
    getComponent={() =>
      import(
        /* webpackChunkName: "admin" */ './components/ExamTypesPage'
      ).then((m) => m.default)
    }
    query={graphql`
      query examTypes_ExamTypesPageQuery($organizationSlug: String!) {
        tenant(slug: $organizationSlug) {
          viewerPermissions {
            ...RouteAccessControl_viewerPermissions
          }
          ...ExamTypesPage_tenant
          ...RouteAccessControl_tenant
        }
      }
    `}
    prerender={chainPrerenders(
      checkFlagsOr404('exam-types'),
      checkIsScopedUnderTenantOr404,
      checkTenantPermission<ExamTypesPageQuery['response']>(
        'examTypeManagement',
        'BASIC',
      ),
    )}
  />
);

export const domainExamTypesRoutes = (
  <Route Component={DomainExamTypesPage}>{organizationExamTypesRoutes}</Route>
);
