import { graphql } from 'react-relay';

import Route, { chainPrerenders } from 'components/Route';
import {
  checkFlagsOr404,
  checkTenantPermission,
} from 'utils/RouteAccessControl';

import { domainConnectivityRoutes_CheckDomainPermissionQuery as CheckDomainPermissionQuery } from './__generated__/domainConnectivityRoutes_CheckDomainPermissionQuery.graphql';
import { domainConnectionsRoute } from './routes/connections';
import { domainIntegrationsRoute } from './routes/integrations';

export const domainConnectivityRoutes = (
  <Route<CheckDomainPermissionQuery>
    path="connectivity"
    getComponent={() =>
      import(
        /* webpackChunkName: "admin" */ './components/DomainConnectivityPage'
      ).then((m) => m.default)
    }
    query={graphql`
      query domainConnectivityRoutes_CheckDomainPermissionQuery {
        tenant {
          viewerPermissions {
            ...RouteAccessControl_viewerPermissions
          }
        }
      }
    `}
    prerender={chainPrerenders(
      checkFlagsOr404('dicom-connector'),
      checkFlagsOr404('domain-connectivity-settings'),
      checkTenantPermission<CheckDomainPermissionQuery['response']>(
        'connectivityManagement',
        'BASIC',
      ),
    )}
  >
    <Route
      getComponent={() =>
        import(
          /* webpackChunkName: "admin" */ './components/ConnectivityPage'
        ).then((m) => m.default)
      }
      query={graphql`
        query domainConnectivityRoutes_DomainConnectivityPage_Query {
          viewer {
            domain {
              ...ConnectivityPage_connectivityConfig
            }
          }
        }
      `}
      renderFetched={({ Component, props }) => (
        <Component {...props} connectivityConfig={props.viewer.domain} />
      )}
    />
    {domainConnectionsRoute}
    {domainIntegrationsRoute}
  </Route>
);
