import BlankSlate from '@bfly/ui2/BlankSlate';
import LoadingIndicator from '@bfly/ui2/LoadingIndicator';
import Text from '@bfly/ui2/Text';
import useQuery from '@bfly/ui2/useQuery';
import getNodes from '@bfly/utils/getNodes';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { graphql } from 'react-relay';
import { DeepNonNullable } from 'utility-types';

import { useIsEnterpriseOrEducation } from 'utils/isEnterpriseOrEducation';

import educationMessages from '../messages/educationMessages';
import EducationContentTile from './EducationContentTile';
import ShareEducationContentModal from './ShareEducationContentModal';
import { AssignedCoursesListQuery as ContentQuery } from './__generated__/AssignedCoursesListQuery.graphql';

type EducationContent =
  DeepNonNullable<ContentQuery>['response']['assignments']['edges'][0]['node'];

export default function AssignedCoursesList() {
  const isViewerEnterpriseOrEducation = useIsEnterpriseOrEducation();
  const [sharedRecord, setSharedRecord] = useState<EducationContent | null>(
    null,
  );

  const { data } = useQuery<ContentQuery>(
    graphql`
      query AssignedCoursesListQuery(
        $viewerIsSelfEnrolled: [Boolean!]
        $skipAssignments: Boolean!
      ) {
        assignments: educationContentConnection(
          first: 2147483647
          sort: [DUE_DATE_ASC]
          viewerIsAssigned: true
          viewerHasCompleted: false
          viewerIsSelfEnrolled: false
        )
          @connection(key: "AssignedCoursesList_assignments")
          @skip(if: $skipAssignments) {
          edges {
            node {
              id
              ...EducationContentTile_educationContent
              ...ShareEducationContentModal_educationContent
            }
          }
        }
        selfEnrollments: educationContentConnection(
          first: 2147483647
          sort: [ENROLLED_AT_ASC]
          viewerIsAssigned: true
          viewerHasCompleted: false
          viewerIsSelfEnrolled: $viewerIsSelfEnrolled
        ) @connection(key: "AssignedCoursesList_selfEnrollments") {
          edges {
            node {
              id
              ...EducationContentTile_educationContent
              ...ShareEducationContentModal_educationContent
            }
          }
        }
      }
    `,
    {
      fetchPolicy: 'store-and-network',
      variables: {
        skipAssignments: !isViewerEnterpriseOrEducation,
        viewerIsSelfEnrolled: isViewerEnterpriseOrEducation
          ? [true]
          : [true, false],
      },
    },
  );

  const assignments = data?.assignments ? getNodes(data?.assignments) : [];
  const selfEnrollments = data?.selfEnrollments
    ? getNodes(data?.selfEnrollments)
    : [];

  if (!data) {
    return <LoadingIndicator />;
  }

  return (
    <div data-bni-id="AssignedCoursesList">
      <ShareEducationContentModal
        show={!!sharedRecord}
        educationContent={sharedRecord}
        onHide={() => setSharedRecord(null)}
      />
      {isViewerEnterpriseOrEducation && (
        <>
          <Text
            as="div"
            variant="sm-bold"
            transform="uppercase"
            className="py-4"
            data-bni-id="AssignmentsLabel"
          >
            <FormattedMessage {...educationMessages.assignments} />
          </Text>
          {assignments.length ? (
            <div className="grid gap-3 mb-6 auto-rows-[1fr] grid-cols-[repeat(auto-fill,minmax(27rem,_1fr))]">
              {assignments.map((educationContent: EducationContent) => (
                <EducationContentTile
                  key={educationContent.id}
                  educationContent={educationContent}
                  onShare={() => setSharedRecord(educationContent)}
                />
              ))}
            </div>
          ) : (
            <BlankSlate>
              <BlankSlate.Title>
                <FormattedMessage {...educationMessages.noAssignedCourses} />
              </BlankSlate.Title>
            </BlankSlate>
          )}
        </>
      )}

      <Text
        as="div"
        variant="sm-bold"
        transform="uppercase"
        className="py-4"
        data-bni-id="SelfEnrollmentsLabel"
      >
        <FormattedMessage {...educationMessages.selfEnrollments} />
      </Text>
      {selfEnrollments.length ? (
        <div className="grid gap-3 mb-6 auto-rows-[1fr] grid-cols-[repeat(auto-fill,minmax(27rem,_1fr))]">
          {selfEnrollments.map((educationContent: EducationContent) => (
            <EducationContentTile
              key={educationContent.id}
              educationContent={educationContent}
              onShare={() => setSharedRecord(educationContent)}
            />
          ))}
        </div>
      ) : (
        <BlankSlate>
          <BlankSlate.Title>
            <FormattedMessage {...educationMessages.noSelfEnrollments} />
          </BlankSlate.Title>
        </BlankSlate>
      )}
    </div>
  );
}
