/**
 * @generated SignedSource<<76911fe419b19be9923f44c1a43cb590>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type domainRoles_DomainRolesPage_Query$variables = {};
export type domainRoles_DomainRolesPage_Query$data = {
  readonly tenant: {
    readonly viewerPermissions: {
      readonly " $fragmentSpreads": FragmentRefs<"RouteAccessControl_viewerPermissions">;
    } | null;
  } | null;
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"DomainRolesPage_viewer">;
  } | null;
};
export type domainRoles_DomainRolesPage_Query = {
  response: domainRoles_DomainRolesPage_Query$data;
  variables: domainRoles_DomainRolesPage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "accessAllOrganizations",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "archiveManagement",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "bulkDataExport",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "connectivityManagement",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "credentialManagement",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "dataDeletion",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "dataExport",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "educationManagement",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "examTypeManagement",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "fleetManagement",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "iqCareRestrictedExperience",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "loginAndSecurityManagement",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "organizationManagement",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "qa",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "qaManagement",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "signing",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "studyDocumentation",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "studyDocumentationManagement",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "studyReversion",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "userManagement",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "userPermissions",
    "storageKey": null
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "domainRoles_DomainRolesPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "DomainRolesPage_viewer"
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ViewerPermissions",
            "kind": "LinkedField",
            "name": "viewerPermissions",
            "plural": false,
            "selections": [
              {
                "kind": "InlineDataFragmentSpread",
                "name": "RouteAccessControl_viewerPermissions",
                "selections": [
                  {
                    "kind": "InlineDataFragmentSpread",
                    "name": "checkPermission_viewerPermissions",
                    "selections": (v0/*: any*/),
                    "args": null,
                    "argumentDefinitions": []
                  }
                ],
                "args": null,
                "argumentDefinitions": []
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "domainRoles_DomainRolesPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "roleType",
                "value": [
                  "SYSTEM_DEFINED",
                  "USER_DEFINED"
                ]
              },
              {
                "kind": "Literal",
                "name": "sort",
                "value": "NAME_ASC"
              }
            ],
            "concreteType": "MembershipRoleConnection",
            "kind": "LinkedField",
            "name": "membershipRoles",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "MembershipRoleEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "MembershipRole",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "membershipRoles(roleType:[\"SYSTEM_DEFINED\",\"USER_DEFINED\"],sort:\"NAME_ASC\")"
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ViewerPermissions",
            "kind": "LinkedField",
            "name": "viewerPermissions",
            "plural": false,
            "selections": (v0/*: any*/),
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "38e07ea6bd7fca841ecbc304b9cebb60",
    "id": null,
    "metadata": {},
    "name": "domainRoles_DomainRolesPage_Query",
    "operationKind": "query",
    "text": "query domainRoles_DomainRolesPage_Query {\n  viewer {\n    ...DomainRolesPage_viewer\n    id\n  }\n  tenant {\n    __typename\n    viewerPermissions {\n      ...RouteAccessControl_viewerPermissions\n    }\n    id\n  }\n}\n\nfragment AddDomainRoleModal_viewer on Viewer {\n  membershipRoles(sort: NAME_ASC, roleType: [SYSTEM_DEFINED, USER_DEFINED]) {\n    edges {\n      node {\n        id\n        name\n      }\n    }\n  }\n}\n\nfragment DomainRolesPage_viewer on Viewer {\n  ...AddDomainRoleModal_viewer\n}\n\nfragment RouteAccessControl_viewerPermissions on ViewerPermissions {\n  ...checkPermission_viewerPermissions\n}\n\nfragment checkPermission_viewerPermissions on ViewerPermissions {\n  accessAllOrganizations\n  archiveManagement\n  bulkDataExport\n  connectivityManagement\n  credentialManagement\n  dataDeletion\n  dataExport\n  educationManagement\n  examTypeManagement\n  fleetManagement\n  iqCareRestrictedExperience\n  loginAndSecurityManagement\n  organizationManagement\n  qa\n  qaManagement\n  signing\n  studyDocumentation\n  studyDocumentationManagement\n  studyReversion\n  userManagement\n  userPermissions\n}\n"
  }
};
})();

(node as any).hash = "ff778c0dafded83662be41a58956c07d";

export default node;
