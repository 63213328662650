/**
 * @generated SignedSource<<a4ea3102307a55e1503c44e4e000431a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type RouteAccessControl_viewerPermissions$data = {
  readonly " $fragmentSpreads": FragmentRefs<"checkPermission_viewerPermissions">;
  readonly " $fragmentType": "RouteAccessControl_viewerPermissions";
};
export type RouteAccessControl_viewerPermissions$key = {
  readonly " $data"?: RouteAccessControl_viewerPermissions$data;
  readonly " $fragmentSpreads": FragmentRefs<"RouteAccessControl_viewerPermissions">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "RouteAccessControl_viewerPermissions"
};

(node as any).hash = "8754fb67887f10d8a40e7812ec13c575";

export default node;
