/**
 * @generated SignedSource<<8abe899d86310591c828768490809667>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type organizations_OrganizationSettingsContent_organization$data = {
  readonly " $fragmentSpreads": FragmentRefs<"canAccessOrganizationSettings_organization">;
  readonly " $fragmentType": "organizations_OrganizationSettingsContent_organization";
};
export type organizations_OrganizationSettingsContent_organization$key = {
  readonly " $data"?: organizations_OrganizationSettingsContent_organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"organizations_OrganizationSettingsContent_organization">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "organizations_OrganizationSettingsContent_organization"
};

(node as any).hash = "5bcc8eea8f11f9a45ae6a6966a3ccc74";

export default node;
