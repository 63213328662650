import FeatureFlags from 'config/FeatureFlags.d';

const featureFlagDefaults: FeatureFlags = {
  'bft-prototype': false,
  'alter-shared-bundle-expiration': false,
  'editable-study-author': false,
  'ambra-enabled': false,
  telemed: false,
  'permanently-delete-studies': false,
  'draft-studies': false,
  worksheets: false,
  'worksheets-review': false,
  'export-archive-worksheets': false,
  'restricted-study-finalization': false,
  'number-field-definitions': false,
  'dicom-connector': true,
  'hl7-connector': false,
  'encounter-based-worklists': false,
  'user-manual-urls': {},
  'deep-learning-sdk': false,
  'show-archive-in-document-title': false,
  'butterfly-link': false,
  'butterfly-link-test-mode': false,
  'barcode-direct-data-entry': false,
  'barcode-accession-number': false,
  'show-ai-features-in-pro-feature-list': false,
  'tpus-archive-configuration': false,
  'referral-web': false,
  'referral-incentive-allowed': false,
  'referral-emails-allowed': false,
  'cloud-measurements': false,
  'cloud-measurements-saving': false,
  'reduced-dicom-transfer-options': false,
  'domain-connectivity-settings': false,
  'domain-workflow-settings': false,
  'collect-place-of-work': false,
  'ehr-preliminary-results': false,
  'pacs-preliminary-results': false,
  'study-pdf': false,
  'multiple-study-authors': false,
  'study-scribes': false,
  'study-tags': false,
  'study-restrictions': false,
  'dicom-field-templates': false,
  'primary-author-must-sign': false,
  'auto-assign-finalizer': false,
  'failed-studies-queue': false,
  'discharged-patients': false,
  'split-studies': false,
  'delete-study-captures': false,
  'to-do-list-archive-setting': false,
  'enable-lms-sso': false,
  'butterfly-academy-access': 'hidden',
  'study-description-mapping': false,
  'has-nurses': false,
  'global-search': false,
  'exam-types': false,
  'retroactive-exam-types': false,
  'study-protocols': false,
  'caption-interpretation-report': false,
  'study-protocols-enterprise-settings': false,
  'pdf-carbon': false,
  'worksheet-publish-in-public-cloud': false,
  'secondary-dicom-field': false,
  'secondary-user-email': false,
  'caption-indication-protocols': false,
  'audit-logs-view': false,
  'global-search-bulk-actions': false,
  'can-set-max-cine-length': false,
  'edu-premium-video-access': false,
  'qa-without-a-worksheet': false,
  'study-sharing': 'disabled',
  'recent-search': false,
  'confirm-logged-in-as': false,
  'custom-exam-types': false,
  'global-search-csv-export': false,
  'bulk-assign-exam-types': false,
  'inactive-users': false,
  'require-secondary-email': false,
  'standard-qa-templates': false,
  'use-search-api': false,
  'copy-patient-id': false,
  'credentialing-analytics-page': false,
  'fhir-integrations': false,
  'distributor-tcs': false,
  'qa-dashboard-export-v2': false,
  'columns-for-qa-updates': false,
  'cross-org-study-list': false,
  'granular-qa-notifications': false,
  'disable-phi-entry': false,
  'disable-notes-field': false,
  'configurable-accession-number': false,
  'historical-proficiency': false,
  'auto-finalize-nbd-exams': false,
  'move-study-org': false,
  'demo-area': false,
  'custom-protocols': 'hidden',
  'release-version': '',
  'scanlab-learner-view': false,
  'scanlab-admin-view': false,
} as const;

export default featureFlagDefaults;
