import { graphql } from 'react-relay';

import Route from 'components/Route';
import { checkTenantPermission } from 'utils/RouteAccessControl';

import { fleet_FleetManagementPageQuery as FleetManagementPageQuery } from './__generated__/fleet_FleetManagementPageQuery.graphql';

export default (
  <Route<FleetManagementPageQuery>
    path="fleet"
    getComponent={() =>
      import(
        /* webpackChunkName: "admin" */ './components/FleetManagementPage'
      ).then((m) => m.default)
    }
    query={graphql`
      query fleet_FleetManagementPageQuery {
        tenant {
          viewerPermissions {
            ...RouteAccessControl_viewerPermissions
          }
          ...FleetManagementPage_domain
          ...RouteAccessControl_tenant
        }
      }
    `}
    prerender={checkTenantPermission<FleetManagementPageQuery['response']>(
      'fleetManagement',
      'BASIC',
    )}
    renderFetched={({ Component, props }) => (
      <Component {...props} domain={props.tenant} />
    )}
  />
);
