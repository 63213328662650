import Button from '@bfly/ui2/Button';
import ItemGrid from '@bfly/ui2/ItemGrid';
import getNodes from '@bfly/utils/getNodes';
import { Link } from 'found';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createPaginationContainer, graphql } from 'react-relay';

import { usePermissions } from 'components/PermissionsProvider';
import actionMessages from 'messages/actions';
import { useAdminRoutes } from 'routes/admin';
import canAccessEnterpriseSettings from 'utils/canAccessEnterpriseSettings';

import { DomainOrganizationsList_viewer$data as Viewer } from './__generated__/DomainOrganizationsList_viewer.graphql';

interface Props {
  viewer: Viewer;
}

function DomainOrganizationsList({ viewer }: Props) {
  const { hasBasicPermission } = usePermissions();
  const adminRoutes = useAdminRoutes();

  return (
    <ItemGrid
      role="table"
      templateColumns="minmax(0, 1fr) minmax(0, 1fr) auto"
    >
      <thead>
        <ItemGrid.Row>
          <ItemGrid.Header>
            <FormattedMessage
              id="domainOrganizationsList.name"
              defaultMessage="Organization"
            />
          </ItemGrid.Header>
          <ItemGrid.Header>
            <FormattedMessage
              id="domainOrganizationsList.numMembers"
              defaultMessage="Members"
            />
          </ItemGrid.Header>
          <ItemGrid.Header />
        </ItemGrid.Row>
      </thead>
      <tbody>
        {getNodes(viewer.domain?.organizationConnection).map((o) => (
          <ItemGrid.Row key={o.slug}>
            <ItemGrid.Cell>{o.name}</ItemGrid.Cell>
            <ItemGrid.Cell>{o.numUsedSeats}</ItemGrid.Cell>

            <ItemGrid.ActionsCell>
              {canAccessEnterpriseSettings(viewer.domain!) && (
                <Link
                  to={adminRoutes.organizationAdmin({
                    organizationSlug: o.slug!,
                  })}
                >
                  {(props) => (
                    <Button size="flush" variant="text-primary" {...props}>
                      <FormattedMessage {...actionMessages.settings} />
                    </Button>
                  )}
                </Link>
              )}
              {(o.viewerIsMember ||
                hasBasicPermission('accessAllOrganizations')) && (
                <Link to={`/${o.slug}/`}>
                  {(props) => (
                    <Button size="flush" variant="text-primary" {...props}>
                      <FormattedMessage {...actionMessages.view} />
                    </Button>
                  )}
                </Link>
              )}
            </ItemGrid.ActionsCell>
          </ItemGrid.Row>
        ))}
      </tbody>
    </ItemGrid>
  );
}

export default createPaginationContainer(
  DomainOrganizationsList,
  {
    viewer: graphql`
      fragment DomainOrganizationsList_viewer on Viewer
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 500 } # PAGE_SIZE.
        cursor: { type: "String" }
        name: { type: "String" }
      ) {
        domain {
          ...canAccessEnterpriseSettings_domain
          organizationConnection(first: $count, after: $cursor, name: $name)
            @connection(key: "Domain_organizationConnection") {
            edges {
              node {
                name
                slug
                numUsedSeats # not the correct thing
                viewerIsMember
              }
            }
          }
        }
      }
    `,
  },
  {
    direction: 'forward',
    getConnectionFromProps: ({ viewer }) =>
      viewer.domain!.organizationConnection,
    getVariables: (_props, { count, cursor }) => ({ count, cursor }),
    query: graphql`
      query DomainOrganizationsListQuery(
        $count: Int!
        $cursor: String
        $name: String
      ) {
        viewer {
          ...DomainOrganizationsList_viewer
            @arguments(count: $count, cursor: $cursor, name: $name)
        }
      }
    `,
  },
);
