import Alert from '@bfly/icons/Alert';
import FolderPlus from '@bfly/icons/FolderPlus';
import Star from '@bfly/icons/Star';
import SideMenu from '@bfly/ui2/SideMenu';
import getNodes from '@bfly/utils/getNodes';
import { stylesheet } from 'astroturf';
import { FormattedMessage, defineMessages } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import IconButton from 'components/IconButton';
import { usePermissions } from 'components/PermissionsProvider';
import { StudyListFilter } from 'utils/StudyConstants';

import ArchiveListItem from './ArchiveListItem';
import CreateArchiveControl from './CreateArchiveControl';
import DeletedArchiveSection from './DeletedArchiveSection';
import SectionFilterItem from './SectionFilterItem';
import { NewExamSection_organization$data as Organization } from './__generated__/NewExamSection_organization.graphql';
import { NewExamSection_tenant$data as Tenant } from './__generated__/NewExamSection_tenant.graphql';

const messages = defineMessages({
  all: {
    id: 'newExamSection.all',
    defaultMessage: 'All Exams',
  },
  favorite: {
    id: 'newExamSection.favorite',
    defaultMessage: 'Favorites',
  },
  unassigned: {
    id: 'newExamSection.unassigned',
    defaultMessage: 'Unassigned',
  },
});

interface Props {
  organization: Organization | null;
  tenant: Tenant;
}

const styles = stylesheet`
  .item {
    // FIXME: switch this to default when new exam section becomes default
    padding-left: 2.8rem !important;
  }
`;

const NewExamSection = ({ organization, tenant }: Props) => {
  const { numUnassigned, numViewerFavorite } = tenant;

  const { hasBasicPermission } = usePermissions();

  return (
    <SideMenu.ListItem>
      <SideMenu.Header
        id="exam-header"
        transform="capitalize"
        variant="body-bold"
        className="pb-1 pt-1 ml-2"
      >
        <div
          data-bni-id="ExamsTitle"
          className="flex justify-between items-center btn-toolbar"
        >
          <FormattedMessage
            id="new-exam-section-header-text"
            defaultMessage="Exams"
          />

          {organization && hasBasicPermission('archiveManagement') && (
            <CreateArchiveControl organization={organization}>
              {(createProps) => (
                <IconButton
                  {...createProps}
                  data-bni-id="CreateArchiveBtn"
                  className="text-body"
                  placement="top"
                  icon={<FolderPlus width={19} height={19} />}
                  title={
                    <FormattedMessage
                      id="newExamSection.createArchive"
                      defaultMessage="Create archive"
                    />
                  }
                />
              )}
            </CreateArchiveControl>
          )}
        </div>
      </SideMenu.Header>

      <SideMenu id="exam-section" aria-labelledby="archive-header">
        <SectionFilterItem
          id="exam-section-all-exams"
          message={messages.all}
          routeName={StudyListFilter.ALL}
          className={styles.item}
        />
        {organization &&
          getNodes(organization.archiveConnection).map((archive) => (
            <ArchiveListItem
              archive={archive}
              key={archive.id}
              className={styles.item}
            />
          ))}
        <SideMenu.Divider />
        <SectionFilterItem
          id="exams-section-favorites-section"
          message={messages.favorite}
          routeName={StudyListFilter.FAVORITES}
          badge={numViewerFavorite}
          icon={<Star width={16} />}
          className={styles.item}
        />
        <SectionFilterItem
          id="exams-section-unassigned-section"
          message={messages.unassigned}
          routeName={StudyListFilter.UNASSIGNED}
          badge={numUnassigned}
          icon={<Alert width={16} />}
          className={styles.item}
        />
        {organization && (
          <DeletedArchiveSection
            organization={organization}
            className={styles.item}
          />
        )}
      </SideMenu>

      <SideMenu />
    </SideMenu.ListItem>
  );
};

export default createFragmentContainer(NewExamSection, {
  organization: graphql`
    fragment NewExamSection_organization on Organization {
      archiveConnection(first: 2147483647)
        @connection(key: "Organization_archiveConnection") {
        edges {
          node {
            id
            ...ArchiveListItem_archive
          }
        }
      }
      ...DeletedArchiveSection_organization
      ...CreateArchiveControl_organization
    }
  `,
  tenant: graphql`
    fragment NewExamSection_tenant on TenantInterface {
      numViewerFavorite: numStudies(search: { favoritedBy: $viewerId })
      numUnassigned: numStudies(search: { author: ["@@UNASSIGNED"] })
    }
  `,
});
