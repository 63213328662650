import Layout from '@4c/layout';
import Form from '@bfly/ui2/Form';
import ItemGrid from '@bfly/ui2/ItemGrid';
import Text from '@bfly/ui2/Text';
import React from 'react';

interface Props {
  name: string;
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  disabled?: boolean;
  children?: React.ReactNode;
}

function ArchiveStudyRestrictionListItem({
  name,
  title,
  subtitle,
  disabled,
  children,
}: Props) {
  return (
    <ItemGrid.Row>
      <ItemGrid.Cell>
        <Form.Field
          type="checkbox"
          name={name}
          id={name}
          disabled={disabled}
          className="items-center"
        >
          <Layout direction="column">
            <Text color="headline">{title}</Text>
            {subtitle && (
              <Text variant="sm" color="subtitle">
                {subtitle}
              </Text>
            )}
          </Layout>
        </Form.Field>
      </ItemGrid.Cell>
      {children}
    </ItemGrid.Row>
  );
}

export default ArchiveStudyRestrictionListItem;
