import FeatureFlags from 'config/FeatureFlags';
import HttpError from 'found/HttpError';
import { graphql, readInlineData } from 'react-relay';

import {
  Prerender,
  RelayRouteRenderArgs,
  RouteRenderFetchedArgs,
} from 'components/Route';
import {
  Permission,
  PermissionLevel,
  checkPermission,
} from 'utils/checkPermission';

import { RouteAccessControl_tenant$key as TenantKey } from './__generated__/RouteAccessControl_tenant.graphql';
import { RouteAccessControl_viewerPermissions$key as PermissionsKey } from './__generated__/RouteAccessControl_viewerPermissions.graphql';

type BooleanFeatureFlags = {
  [K in keyof FeatureFlags as FeatureFlags[K] extends boolean
    ? K
    : never]: FeatureFlags[K];
};

type FlagChecker = (ldClient: RouteRenderFetchedArgs['ldClient']) => boolean;

/**
 * Check if a boolean feature flag resolves to `true` otherwise 404
 * @param key A boolean feature flag
 */
export function checkFlagsOr404(key: keyof BooleanFeatureFlags): Prerender;
/**
 * For complex or multiple flag checking provider a function that takes the LD client and
 * returns a boolean value: `true` allows the route, any falsey value will trigger a 404
 * @param flagChecker A function to check for valid flag state
 */
export function checkFlagsOr404(flagChecker: FlagChecker): Prerender;
export function checkFlagsOr404(
  flagCheckerOrFlag: FlagChecker | keyof BooleanFeatureFlags,
): Prerender {
  const flagChecker: FlagChecker =
    typeof flagCheckerOrFlag === 'function'
      ? flagCheckerOrFlag
      : (ldClient) => ldClient.variation(flagCheckerOrFlag);

  return ({ props, ldClient }) => {
    if (props && ldClient && !flagChecker(ldClient!)) {
      if (!bflyConfig.IS_PROD)
        console.error('checkFlagsOr404Fail', flagCheckerOrFlag);
      throw new HttpError(404);
    }
  };
}

const tenantFragment = graphql`
  fragment RouteAccessControl_tenant on TenantInterface @inline {
    ... on Domain {
      subdomainLabel
      viewerPermissions {
        accessAllOrganizations
        archiveManagement
        bulkDataExport
        connectivityManagement
        credentialManagement
        dataDeletion
        dataExport
        educationManagement
        examTypeManagement
        fleetManagement
        iqCareRestrictedExperience
        loginAndSecurityManagement
        organizationManagement
        qa
        qaManagement
        signing
        studyDocumentation
        studyDocumentationManagement
        studyReversion
        userManagement
        userPermissions
      }
    }
    ... on Organization {
      slug
      viewerPermissions {
        accessAllOrganizations
        archiveManagement
        bulkDataExport
        connectivityManagement
        credentialManagement
        dataDeletion
        dataExport
        educationManagement
        examTypeManagement
        fleetManagement
        iqCareRestrictedExperience
        loginAndSecurityManagement
        organizationManagement
        qa
        qaManagement
        signing
        studyDocumentation
        studyDocumentationManagement
        studyReversion
        userManagement
        userPermissions
      }
    }
  }
`;

/**
 * Ensures that a route is not displayed for an Organization
 * when in a Domain. This check depends on `organizationSlug` param
 * as the indicator of the route scoping. If a slug is present AND the
 * tenant is a Domain, the route will 404
 */
export function checkIsScopedUnderTenantOr404({
  props,
  match,
}: RelayRouteRenderArgs<{ tenant: TenantKey | null }>) {
  if (!props || !match) return;
  const tenant = readInlineData(tenantFragment, props.tenant);

  if (!tenant?.slug && match.params.organizationSlug) {
    throw new HttpError(404);
  }
}

const permissionsFragment = graphql`
  fragment RouteAccessControl_viewerPermissions on ViewerPermissions @inline {
    ...checkPermission_viewerPermissions
  }
`;

interface TenantPermissionsProps {
  tenant: { viewerPermissions: PermissionsKey | null } | null;
}

type PermissionChecker = (
  checkPermission: (
    permissionName: Permission,
    requestedLevel: PermissionLevel,
  ) => boolean,
) => boolean;

export function checkTenantPermission<P extends TenantPermissionsProps = any>(
  permissionName: Permission,
  requestedLevel: PermissionLevel,
): Prerender<P> {
  const check: PermissionChecker = (checker) =>
    checker(permissionName, requestedLevel);

  return (prerenderProps: RelayRouteRenderArgs<P>) => {
    const { match, props } = prerenderProps;

    if (!props || !match) return;

    const viewerPermissions = readInlineData(
      permissionsFragment,
      props.tenant?.viewerPermissions ?? null,
    );

    if (!check((p, l) => checkPermission(viewerPermissions, p, l))) {
      throw new HttpError(404);
    }
  };
}

export function checkRoutePermission<P extends Record<string, any> = any>(
  permissionName: Permission,
  requestedLevel: PermissionLevel,
  permissionsOn: 'domain' | 'organization' | 'tenant' = 'domain',
): Prerender<P> {
  return (prerenderProps) => {
    const { match, props } = prerenderProps;

    if (!props || !match) return;

    let frag: any;
    if (permissionsOn === 'domain') {
      frag = props?.viewer?.domain?.viewerPermissions;
    } else if (permissionsOn === 'organization') {
      frag = props?.organization?.viewerPermissions;
    } else {
      frag = props?.tenant?.viewerPermissions;
    }

    const viewerPermissions = readInlineData(permissionsFragment, frag);

    if (!checkPermission(viewerPermissions, permissionName, requestedLevel)) {
      throw new HttpError(404);
    }
  };
}
