import { UserPermission } from '@bfly/olympus-protobuf';
import FeatureFlags from 'config/FeatureFlags';
import { MessageDescriptor, defineMessage } from 'react-intl';

interface PermissionBucketItem {
  label: MessageDescriptor;
  requiredFeatureFlags?: Partial<FeatureFlags>;
}

type PermissionId = keyof UserPermission.BucketMap;

export interface PermissionBucket {
  label: MessageDescriptor;
  permissionId: PermissionId;
  allowReadWrite: boolean;
  items: PermissionBucketItem[];
  parentPermission?: PermissionId;
  requiredFeatureFlags?: Partial<FeatureFlags>;
}

const PERMISSION_BUCKETS: PermissionBucket[] = [
  {
    label: defineMessage({
      id: 'permission.archive.legend',
      defaultMessage: 'Archive Management',
    }),
    permissionId: 'ARCHIVE_MANAGEMENT',
    allowReadWrite: false,
    items: [
      {
        label: defineMessage({
          id: 'permission.archiveSettings.detail',
          defaultMessage: 'Edit Archive Name/Members',
        }),
      },
      {
        label: defineMessage({
          id: 'permission.createArchives.detail',
          defaultMessage: 'Create Archives',
        }),
      },
      {
        label: defineMessage({
          id: 'permission.deleteArchives.detail',
          defaultMessage: 'Delete and Restore Archives',
        }),
      },
      {
        label: defineMessage({
          id: 'permission.viewAllArchives.detail',
          defaultMessage: 'View All Archives (Private/Public)',
        }),
      },
    ],
  },
  {
    requiredFeatureFlags: {
      'restricted-study-finalization': true,
    },
    label: defineMessage({
      id: 'permission.signin.legend',
      defaultMessage: 'Attesting',
    }),
    permissionId: 'SIGNING',
    allowReadWrite: false,
    items: [
      {
        requiredFeatureFlags: {
          'restricted-study-finalization': true,
        },
        label: defineMessage({
          id: 'permission.canAttest.detail',
          defaultMessage:
            'Can Attest (Perform Final Signature) When Attestation Required',
        }),
      },
    ],
  },
  {
    requiredFeatureFlags: {
      'export-archive-worksheets': true,
    },
    label: defineMessage({
      id: 'permission.bulkDataExport.legend',
      defaultMessage: 'Bulk Data Export',
    }),
    permissionId: 'BULK_DATA_EXPORT',
    allowReadWrite: false,
    items: [
      {
        label: defineMessage({
          id: 'permission.exportTabularExam.detail',
          defaultMessage: 'Export Tabular Exam Data',
        }),
      },
    ],
  },
  {
    label: defineMessage({
      id: 'permission.connectivity.legend',
      defaultMessage: 'Connectivity Management',
    }),
    permissionId: 'CONNECTIVITY_MANAGEMENT',
    allowReadWrite: true,
    items: [
      {
        label: defineMessage({
          id: 'permission.addDICOMConnections.detail',
          defaultMessage: 'Add/Edit DICOM/HL7 Connections and Integrations',
        }),
      },
      {
        label: defineMessage({
          id: 'permission.editDICOMFields.detail',
          defaultMessage: 'Edit DICOM Field Templates',
        }),
      },
      {
        label: defineMessage({
          id: 'permission.nonButterflyDevice.detail',
          defaultMessage: 'Non-Butterfly Device Integration',
        }),
      },
      {
        label: defineMessage({
          id: 'permission.primaryWorklist.detail',
          defaultMessage: 'Set Primary Worklist for Organizations',
        }),
      },
      {
        requiredFeatureFlags: {
          'can-set-max-cine-length': true,
        },
        label: defineMessage({
          id: 'permission.maximunCineLength.detail',
          defaultMessage: 'Set Maximum Cine Length',
        }),
      },
      {
        label: defineMessage({
          id: 'permission.assignPACS.detail',
          defaultMessage: 'Assign PACS/EHR Forwarding',
        }),
      },
      {
        label: defineMessage({
          id: 'permission.DICOMManagment.detail',
          defaultMessage: 'Exam Level DICOM/HL7 Management (Resend)',
        }),
      },
      {
        label: defineMessage({
          id: 'permission.connAccessAllOrgs.detail',
          defaultMessage: 'Access to All Organizations',
        }),
      },
    ],
  },
  {
    label: defineMessage({
      id: 'rolePermission.dataDeletion.legend',
      defaultMessage: 'Data Deletion',
    }),
    permissionId: 'DATA_DELETION',
    allowReadWrite: false,
    items: [
      {
        label: defineMessage({
          id: 'permission.deleteComments.detail',
          defaultMessage: 'Delete All Exam Comments',
        }),
      },
      {
        label: defineMessage({
          id: 'permission.deleteExams.detail',
          defaultMessage: 'Delete and Restore Finalized Exams',
        }),
      },
    ],
  },
  {
    label: defineMessage({
      id: 'permission.dataExport.legend',
      defaultMessage: 'Data Export',
    }),
    permissionId: 'DATA_EXPORT',
    allowReadWrite: false,
    items: [
      {
        label: defineMessage({
          id: 'permission.exportExam.detail',
          defaultMessage: 'Export Exam PDF with PHI',
        }),
      },
      {
        label: defineMessage({
          id: 'permission.exportDICOM.detail',
          defaultMessage: 'Export DICOM Images',
        }),
      },
    ],
  },
  {
    requiredFeatureFlags: {
      'enable-lms-sso': true,
    },
    label: defineMessage({
      id: 'permission.education.legend',
      defaultMessage: 'Education Management',
    }),
    permissionId: 'EDUCATION_MANAGEMENT',
    allowReadWrite: false,
    items: [
      {
        label: defineMessage({
          id: 'permission.viewAcademyDashboard.detail',
          defaultMessage: 'View Butterfly Academy Dashboard',
        }),
      },
      {
        label: defineMessage({
          id: 'permission.enrollUsers.detail',
          defaultMessage: 'Enroll Users in Butterfly Academy Courses',
        }),
      },
    ],
  },
  {
    requiredFeatureFlags: {
      worksheets: true,
      'study-tags': true,
    },
    label: defineMessage({
      id: 'permission.examDocumentation.legend',
      defaultMessage: 'Exam Documentation',
    }),
    permissionId: 'STUDY_DOCUMENTATION',
    allowReadWrite: false,
    items: [
      {
        requiredFeatureFlags: {
          worksheets: true,
        },
        label: defineMessage({
          id: 'permission.createWorksheets.detail',
          defaultMessage: 'Create Worksheet Templates',
        }),
      },
      {
        label: defineMessage({
          id: 'permission.addPrefillsPublishedWorksheets.detail',
          defaultMessage: 'Add Prefills to Published Worksheets',
        }),
      },
      {
        label: defineMessage({
          id: 'permission.editUnpublishedWorksheets.detail',
          defaultMessage: 'Edit Unpublished Worksheet Templates ',
        }),
      },
      {
        label: defineMessage({
          id: 'permission.addWorksheet.detail',
          defaultMessage:
            'Add Worksheet Prefills to Unpublished Worksheet Templates',
        }),
      },
      {
        requiredFeatureFlags: {
          'study-tags': true,
        },
        label: defineMessage({
          id: 'permission.createTags.detail',
          defaultMessage: 'Create/Delete Tags',
        }),
      },
      {
        requiredFeatureFlags: {
          'study-protocols': true,
        },
        label: defineMessage({
          id: 'permission.captionProtocols.detail',
          defaultMessage: 'Create Caption Protocols',
        }),
      },
    ],
  },
  {
    label: defineMessage({
      id: 'permission.examDocManagment.legend',
      defaultMessage: 'Exam Documentation Management',
    }),
    permissionId: 'STUDY_DOCUMENTATION_MANAGEMENT',
    allowReadWrite: true,
    parentPermission: 'STUDY_DOCUMENTATION',
    items: [
      {
        requiredFeatureFlags: {
          'study-restrictions': true,
        },
        label: defineMessage({
          id: 'permission.setWorklistRequired.detail',
          defaultMessage: 'Set Worklist Required',
        }),
      },
      {
        requiredFeatureFlags: {
          'restricted-study-finalization': true,
        },
        label: defineMessage({
          id: 'permission.setWorksheetRequired.detail',
          defaultMessage: 'Set Worksheet Required',
        }),
      },
      {
        label: defineMessage({
          id: 'permission.configureAttestation.detail',
          defaultMessage: 'Configure Attestation',
        }),
      },
      {
        requiredFeatureFlags: {
          'study-restrictions': true,
          worksheets: true,
        },
        label: defineMessage({
          id: 'permission.setWorksheetRequired.detail',
          defaultMessage: 'Set Worksheet Required',
        }),
      },
      {
        requiredFeatureFlags: {
          worksheets: true,
        },
        label: defineMessage({
          id: 'permission.manageWorksheetTemplates.detail',
          defaultMessage: 'Publish/Unpublish Worksheet Templates',
        }),
      },
      {
        label: defineMessage({
          id: 'permission.editPublishedWorksheets.detail',
          defaultMessage: 'Edit Published Worksheet Templates',
        }),
      },
      {
        label: defineMessage({
          id: 'permission.allowUsersAddWorksheets.detail',
          defaultMessage: 'Allow Users to Add Multiple Worksheets to an Exam',
        }),
      },
      {
        label: defineMessage({
          id: 'permission.assignWorksheets.detail',
          defaultMessage: 'Assign Worksheets to Organizations',
        }),
      },
      {
        requiredFeatureFlags: {
          'auto-finalize-nbd-exams': true,
        },
        label: defineMessage({
          id: 'permission.autoFinalizeNbd.detail',
          defaultMessage: 'Configure Auto-Finalization for NBD Exams',
        }),
      },
      {
        label: defineMessage({
          id: 'permission.excludesArchives.detail',
          defaultMessage: 'Exclude Archives from To Do List',
        }),
      },
      {
        label: defineMessage({
          id: 'permission.accAllOrg.detail',
          defaultMessage: 'Access to All Organizations',
        }),
      },
    ],
  },
  {
    label: defineMessage({
      id: 'permission.studyReversion.legend',
      defaultMessage: 'Exam Reversion',
    }),
    permissionId: 'STUDY_REVERSION',
    allowReadWrite: false,
    items: [
      {
        label: defineMessage({
          id: 'permission.revertAnyExam.detail',
          defaultMessage: 'Revert Any Exam to Draft (Addend)',
        }),
      },
    ],
  },
  {
    requiredFeatureFlags: {
      'exam-types': true,
    },
    label: defineMessage({
      id: 'permission.examType.legend',
      defaultMessage: 'Exam Type Management',
    }),
    permissionId: 'EXAM_TYPE_MANAGEMENT',
    allowReadWrite: false,
    items: [
      {
        label: defineMessage({
          id: 'permission.createExamTypes.detail',
          defaultMessage: 'Create/Disable Exam Types',
        }),
      },
      {
        label: defineMessage({
          id: 'permission.requireExamTypes.detail',
          defaultMessage: 'Set Requirements for Exam Type(s) to Finalize Exam',
        }),
      },
      {
        label: defineMessage({
          id: 'permission.assignExamTypes.detail',
          defaultMessage: 'Assign Retroactive Exam Type(s)',
        }),
      },
    ],
  },
  {
    label: defineMessage({
      id: 'permission.fleet.legend',
      defaultMessage: 'Fleet Management',
    }),
    permissionId: 'FLEET_MANAGEMENT',
    allowReadWrite: true,
    items: [
      {
        label: defineMessage({
          id: 'permission.modifyFleetManagment.detail',
          defaultMessage: 'Modify Fleet Management',
        }),
      },
    ],
  },
  {
    label: defineMessage({
      id: 'permission.security.legend',
      defaultMessage: 'Login and Security Management',
    }),
    permissionId: 'LOGIN_AND_SECURITY_MANAGEMENT',
    allowReadWrite: true,
    items: [
      {
        label: defineMessage({
          id: 'permission.sso.detail',
          defaultMessage: 'Configure SSO',
        }),
      },
      {
        label: defineMessage({
          id: 'permission.qrSettings.detail',
          defaultMessage: 'View and Edit QR Code Settings',
        }),
      },
      {
        label: defineMessage({
          id: 'permission.mdm.detail',
          defaultMessage: 'Configure MDM',
        }),
      },
      {
        label: defineMessage({
          id: 'permission.desktopSession.detail',
          defaultMessage: 'Configure Desktop Session Timeout',
        }),
      },
      {
        requiredFeatureFlags: {
          'audit-logs-view': true,
        },
        label: defineMessage({
          id: 'permission.auditLogs.detail',
          defaultMessage: 'Access Audit Logs',
        }),
      },
    ],
  },
  {
    label: defineMessage({
      id: 'permission.organization.legend',
      defaultMessage: 'Organization Management',
    }),
    permissionId: 'ORGANIZATION_MANAGEMENT',
    allowReadWrite: false,
    items: [
      {
        label: defineMessage({
          id: 'permission.addUsersToOrgs.detail',
          defaultMessage:
            'Add Users to Organizations (Which You Are a Member Of)',
        }),
      },
    ],
  },
  {
    requiredFeatureFlags: {
      'credentialing-analytics-page': true,
    },
    label: defineMessage({
      id: 'permission.credential.legend',
      defaultMessage: 'Proficiency Management',
    }),
    permissionId: 'CREDENTIAL_MANAGEMENT',
    allowReadWrite: false,
    items: [
      {
        label: defineMessage({
          id: 'permission.editCredentialsRequirements.detail',
          defaultMessage: 'Set Requirements for Proficiency Management',
        }),
      },
      {
        label: defineMessage({
          id: 'permission.viewCredentialProgress.detail',
          defaultMessage: 'View All User Proficiency Results',
        }),
      },
      {
        label: defineMessage({
          id: 'permission.createEditGroups.detail',
          defaultMessage: 'Create/Edit Groups',
        }),
      },
      {
        label: defineMessage({
          id: 'permission.viewEditHistoricalData.detail',
          defaultMessage: 'View/Edit Historical Proficiency',
        }),
      },
      {
        label: defineMessage({
          id: 'permission.viewAllAnalytics.detail',
          defaultMessage: 'View All Analytics',
        }),
      },
    ],
  },
  {
    requiredFeatureFlags: {
      'worksheets-review': true,
    },
    label: defineMessage({
      id: 'permission.qaManagment.legend',
      defaultMessage: 'QA Management',
    }),
    permissionId: 'QA_MANAGEMENT',
    allowReadWrite: false,
    items: [
      {
        requiredFeatureFlags: {
          'worksheets-review': true,
        },
        label: defineMessage({
          id: 'permission.addUnpublishedQACards.detail',
          defaultMessage: 'Add/Edit Unpublished QA Cards',
        }),
      },
      {
        label: defineMessage({
          id: 'permission.publishQA.detail',
          defaultMessage: 'Publish/Unpublish QA Cards',
        }),
      },
      {
        label: defineMessage({
          id: 'permission.setAbilityToQA.detail',
          defaultMessage: 'Set Ability to QA Without Worksheet',
        }),
      },
      {
        requiredFeatureFlags: {
          'worksheets-review': true,
        },
        label: defineMessage({
          id: 'permission.setQACard.detail',
          defaultMessage: 'Set Default QA Card for Organization',
        }),
      },
    ],
  },
  {
    requiredFeatureFlags: {
      'worksheets-review': true,
    },
    label: defineMessage({
      id: 'permission.qa.legend',
      defaultMessage: 'QA',
    }),
    permissionId: 'QA',
    allowReadWrite: false,
    items: [
      {
        requiredFeatureFlags: {
          'worksheets-review': true,
        },
        label: defineMessage({
          id: 'permission.canQAExam.detail',
          defaultMessage: 'Ability to QA Exam',
        }),
      },
      {
        label: defineMessage({
          id: 'permission.viewAllQAExam.detail',
          defaultMessage: 'View All QA Results on Exam',
        }),
      },
      {
        requiredFeatureFlags: {
          'worksheets-review': true,
        },
        label: defineMessage({
          id: 'permission.viewAllQADashoard.detail',
          defaultMessage: 'View All QA Results in Stats Dashboard',
        }),
      },
      {
        label: defineMessage({
          id: 'permission.exportQAResults.detail',
          defaultMessage: 'Export QA Results',
        }),
      },
      {
        requiredFeatureFlags: {
          'worksheets-review': true,
          'scanlab-admin-view': true,
        },
        label: defineMessage({
          id: 'permission.canQAScanLabExam.detail',
          defaultMessage: 'Ability to QA ScanLab Exam',
        }),
      },
    ],
  },
  {
    label: defineMessage({
      id: 'permission.userManagment.legend',
      defaultMessage: 'User Management',
    }),
    permissionId: 'USER_MANAGEMENT',
    allowReadWrite: true,
    items: [
      {
        label: defineMessage({
          id: 'permission.addUsers.detail',
          defaultMessage: 'Add Users',
        }),
      },
      {
        label: defineMessage({
          id: 'permission.deleteUsers.detail',
          defaultMessage: 'Delete Users',
        }),
      },
      {
        label: defineMessage({
          id: 'permission.assignRole.detail',
          defaultMessage: 'Assign Butterfly Access Roles to Users',
        }),
      },
      {
        label: defineMessage({
          id: 'permission.editUserDetails.detail',
          defaultMessage: 'Edit User Details',
        }),
      },
      {
        label: defineMessage({
          id: 'permission.exportUser.detail',
          defaultMessage: 'Export User CSV',
        }),
      },
      {
        label: defineMessage({
          id: 'permission.accUserAllOrg.detail',
          defaultMessage: 'Access to All Organizations',
        }),
      },
    ],
  },
  {
    label: defineMessage({
      id: 'permission.userPermissions.legend',
      defaultMessage: 'User Permissions',
    }),
    permissionId: 'USER_PERMISSIONS',
    allowReadWrite: true,
    items: [
      {
        label: defineMessage({
          id: 'permission.defineAccessRole.detail',
          defaultMessage: 'Define Butterfly Access Roles',
        }),
      },
    ],
  },
];

export default PERMISSION_BUCKETS;
