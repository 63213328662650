/**
 * @generated SignedSource<<3ca972c939d6225dd56b4a63b1c09ab3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type canAccessEnterpriseSettings_domain$data = {
  readonly viewerPermissions: {
    readonly " $fragmentSpreads": FragmentRefs<"checkPermission_viewerPermissions">;
  } | null;
  readonly " $fragmentType": "canAccessEnterpriseSettings_domain";
};
export type canAccessEnterpriseSettings_domain$key = {
  readonly " $data"?: canAccessEnterpriseSettings_domain$data;
  readonly " $fragmentSpreads": FragmentRefs<"canAccessEnterpriseSettings_domain">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "canAccessEnterpriseSettings_domain"
};

(node as any).hash = "889e29d568a63f586928d0a9b7168c27";

export default node;
