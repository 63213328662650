import { graphql } from 'react-relay';

import Route, { chainPrerenders } from 'components/Route';
import {
  checkFlagsOr404,
  checkRoutePermission,
} from 'utils/RouteAccessControl';

import { organizationQaRoutes_checkIsAdmin_Query as CheckIsAdminQuery } from './__generated__/organizationQaRoutes_checkIsAdmin_Query.graphql';

export const organizationQaRoute = (
  <Route
    path="qa"
    prerender={chainPrerenders(
      checkFlagsOr404('worksheets-review'),
      checkRoutePermission<CheckIsAdminQuery['response']>(
        'qaManagement',
        'BASIC',
        'organization',
      ),
    )}
    query={graphql`
      query organizationQaRoutes_checkIsAdmin_Query(
        $organizationSlug: String!
      ) {
        organization: organizationBySlug(slug: $organizationSlug) {
          viewerPermissions {
            ...RouteAccessControl_viewerPermissions
          }
          ...RouteAccessControl_tenant
        }
      }
    `}
  >
    <Route
      getComponent={() =>
        import(
          /* webpackChunkName: "admin" */ './components/OrganizationQaSettingsContent'
        ).then((m) => m.default)
      }
      query={graphql`
        query organizationQaRoutes_QaSettingsContent_Query(
          $organizationSlug: String!
        ) {
          organization: organizationBySlug(slug: $organizationSlug) {
            ...OrganizationQaSettingsContent_organization
          }
        }
      `}
    />
    {/*
    Only the CRUD routes need to check the workflow LD flag, because a
    message is shown on the index page regardless of it's value */}
    <Route>
      <Route
        fullScreen
        path="-/new"
        getComponent={() =>
          import(
            /* webpackChunkName: "admin" */ './components/CreateQaTemplateForm'
          ).then((m) => m.default)
        }
        query={graphql`
          query organizationQaRoutes_CreateQaTemplateForm_Query(
            $organizationSlug: String!
          ) {
            workflowConfig: organizationBySlug(slug: $organizationSlug) {
              ...CreateQaTemplateForm_workflowConfig
            }
          }
        `}
      />
      <Route fullScreen path=":templateHandle">
        <Route
          getComponent={() =>
            import(
              /* webpackChunkName: "admin" */ './components/UpdateQaTemplateForm'
            ).then((m) => m.default)
          }
          query={graphql`
            query organizationQaRoutes_UpdateQaTemplateForm_Query(
              $templateHandle: String!
            ) {
              template: qaTemplate(handle: $templateHandle) {
                ...UpdateQaTemplateForm_template
              }
            }
          `}
        />
      </Route>
    </Route>
  </Route>
);
