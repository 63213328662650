import { graphql } from 'react-relay';

import Route from 'components/Route';
import { checkTenantPermission } from 'utils/RouteAccessControl';

import { domainRoles_DomainRolesList_Query as DomainRolesList_Query } from './__generated__/domainRoles_DomainRolesList_Query.graphql';
import { domainRoles_UpdateDomainRolePage_Query as UpdateDomainRole_Query } from './__generated__/domainRoles_UpdateDomainRolePage_Query.graphql';
import CreateDomainRolePage from './components/CreateDomainRolePage';
import DomainRoleDefinitionPage from './components/DomainRoleDefinitionPage';
import DomainRolesPage from './components/DomainRolesPage';
import DuplicateDomainRolePage from './components/DuplicateDomainRolePage';
import UpdateDomainRolePage from './components/UpdateDomainRolePage';

export default (
  <Route
    path="roles"
    query={graphql`
      query domainRoles_Query {
        tenant {
          viewerPermissions {
            ...RouteAccessControl_viewerPermissions
          }
        }
      }
    `}
    prerender={checkTenantPermission('userPermissions', 'BASIC')}
  >
    <Route
      Component={DomainRolesPage}
      query={graphql`
        query domainRoles_DomainRolesPage_Query {
          viewer {
            ...DomainRolesPage_viewer
          }
          tenant {
            viewerPermissions {
              ...RouteAccessControl_viewerPermissions
            }
          }
        }
      `}
    >
      <Route<DomainRolesList_Query>
        getComponent={() =>
          import(
            /* webpackChunkName: "admin" */ './components/DomainRolesList'
          ).then((m) => m.default)
        }
        query={graphql`
          query domainRoles_DomainRolesList_Query {
            viewer {
              ...DomainRolesList_viewer
            }
          }
        `}
      />
    </Route>
    <Route
      path=":membershipRoleId/definition"
      query={graphql`
        query domainRoles_DomainRoleDefinitionPage_Query(
          $membershipRoleId: ID!
        ) {
          membershipRole: node(id: $membershipRoleId) {
            ... on MembershipRole {
              ...DomainRoleDefinitionPage_membershipRole
            }
          }
        }
      `}
      Component={DomainRoleDefinitionPage}
    />
    <Route path="new" Component={CreateDomainRolePage} />
    <Route
      path=":membershipRoleId/duplicate"
      query={graphql`
        query domainRoles_DuplicateDomainRolePage_Query(
          $membershipRoleId: ID!
        ) {
          membershipRole: node(id: $membershipRoleId) {
            ... on MembershipRole {
              ...DuplicateDomainRolePage_membershipRole
            }
          }
        }
      `}
      Component={DuplicateDomainRolePage}
    />
    <Route<UpdateDomainRole_Query>
      path=":membershipRoleId"
      Component={UpdateDomainRolePage}
      query={graphql`
        query domainRoles_UpdateDomainRolePage_Query($membershipRoleId: ID!) {
          viewer {
            ... on Viewer {
              ...UpdateDomainRolePage_viewer
            }
          }
          membershipRole: node(id: $membershipRoleId) {
            ... on MembershipRole {
              ...UpdateDomainRolePage_membershipRole
            }
          }
        }
      `}
    />
  </Route>
);
