/**
 * @generated SignedSource<<12781e6349164837e72c2ec9c66c55ef>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useStudyFinalization_viewer$data = {
  readonly profile: {
    readonly id: string;
  } | null;
  readonly " $fragmentType": "useStudyFinalization_viewer";
};
export type useStudyFinalization_viewer$key = {
  readonly " $data"?: useStudyFinalization_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"useStudyFinalization_viewer">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "useStudyFinalization_viewer"
};

(node as any).hash = "7b49d937caa72105a9f63f7b2779f163";

export default node;
