import LoadingIndicator from '@bfly/ui2/LoadingIndicator';
import Page from '@bfly/ui2/Page';
import SideMenu from '@bfly/ui2/SideMenu';
import { useRouter } from 'found';
import { createFragmentContainer, graphql } from 'react-relay';

import BaseAppPage from 'components/BaseAppPage';
import { usePermissions } from 'components/PermissionsProvider';
import { RoutePageProps } from 'components/Route';
import { useExamRoutes } from 'routes/exam';
import { StudyListFilter } from 'utils/StudyConstants';

import DeletedStudySection from './DeletedStudySection';
import NewExamSection from './NewExamSection';
import NewTodoSection from './NewTodoSection';
import { AllOrganizationsStudyListPage_viewer$data as Viewer } from './__generated__/AllOrganizationsStudyListPage_viewer.graphql';

interface Props extends RoutePageProps {
  viewer: Viewer;
  loading?: boolean;
}

const AllOrganizationsStudyListPage = ({
  viewer,
  loading,
  children,
}: Props) => {
  const { hasBasicPermission } = usePermissions();
  const examRoutes = useExamRoutes();
  const { match } = useRouter();
  const { params } = match;

  if (loading) {
    return <LoadingIndicator />;
  }
  return (
    <BaseAppPage
      tenant={viewer.domain!}
      organization={null}
      viewer={viewer}
      pageLayout="fill"
      archive={null}
      searchNodes={null}
      sidePanel={
        <Page.SidePanel
          data-bni-id="AppSidePanel"
          className="flex flex-col justify-between pb-0"
        >
          <SideMenu className="mb-auto mt-3">
            <NewTodoSection tenant={viewer.domain!} />
            <NewExamSection tenant={viewer.domain!} organization={null} />
          </SideMenu>
          {hasBasicPermission('dataDeletion') && (
            <DeletedStudySection
              className="bottom-0 sticky bg-body py-0"
              tenant={viewer.domain!}
              to={examRoutes.examLists({
                organizationSlug: params.organizationSlug ?? '-',
                status: StudyListFilter.DELETED,
              })}
            />
          )}
        </Page.SidePanel>
      }
    >
      {children}
    </BaseAppPage>
  );
};

export default createFragmentContainer(AllOrganizationsStudyListPage, {
  viewer: graphql`
    fragment AllOrganizationsStudyListPage_viewer on Viewer {
      ...BaseAppPage_viewer
      domain {
        viewerCanFinalize
        ...NewTodoSection_tenant
        ...NewExamSection_tenant
        ...DeletedStudySection_tenant
        ...BaseAppPage_tenant
      }
    }
  `,
});
