import { graphql, readInlineData } from 'react-relay';

import { canAccessOrganizationSettings_organization$key as Organization } from 'utils/__generated__/canAccessOrganizationSettings_organization.graphql';
import { checkPermission } from 'utils/checkPermission';

export default function canAccessOrganizationSettings(
  organizationRef: Organization | null,
) {
  const organization = readInlineData(
    graphql`
      fragment canAccessOrganizationSettings_organization on Organization
      @inline {
        viewerPermissions {
          ...checkPermission_viewerPermissions
        }
      }
    `,
    organizationRef,
  );

  const permissions = organization?.viewerPermissions;
  return (
    checkPermission(permissions, 'credentialManagement', 'BASIC') ||
    checkPermission(permissions, 'connectivityManagement', 'BASIC') ||
    checkPermission(permissions, 'examTypeManagement', 'BASIC') ||
    checkPermission(permissions, 'fleetManagement', 'BASIC') ||
    checkPermission(permissions, 'loginAndSecurityManagement', 'BASIC') ||
    checkPermission(permissions, 'qaManagement', 'BASIC') ||
    checkPermission(permissions, 'studyDocumentation', 'BASIC') ||
    checkPermission(permissions, 'studyDocumentationManagement', 'BASIC') ||
    checkPermission(permissions, 'userManagement', 'BASIC') ||
    checkPermission(permissions, 'userPermissions', 'BASIC')
  );
}
