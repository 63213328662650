import { graphql } from 'react-relay';

import Route, { chainPrerenders } from 'components/Route';
import {
  checkFlagsOr404,
  checkRoutePermission,
} from 'utils/RouteAccessControl';

import { organizationConnectivityRoutes_CheckPermissionQuery as CheckPermissionQuery } from './__generated__/organizationConnectivityRoutes_CheckPermissionQuery.graphql';
import { organizationConnectionsRoute } from './routes/connections';
import { organizationIntegrationsRoute } from './routes/integrations';

export const organizationConnectivityRoute = (
  <Route<CheckPermissionQuery>
    path="connectivity"
    query={graphql`
      query organizationConnectivityRoutes_CheckPermissionQuery(
        $organizationSlug: String!
      ) {
        organization: organizationBySlug(slug: $organizationSlug) {
          viewerPermissions {
            ...RouteAccessControl_viewerPermissions
          }
        }
      }
    `}
    prerender={chainPrerenders(
      checkFlagsOr404('dicom-connector'),
      checkRoutePermission<CheckPermissionQuery['response']>(
        'connectivityManagement',
        'BASIC',
        'organization',
      ),
    )}
  >
    <Route
      getComponent={() =>
        import(
          /* webpackChunkName: "admin" */ './components/ConnectivityPage'
        ).then((m) => m.default)
      }
      query={graphql`
        query organizationConnectivityRoutes_ConnectivityPage_Query(
          $organizationSlug: String!
        ) {
          connectivityConfig: organizationBySlug(slug: $organizationSlug) {
            ...ConnectivityPage_connectivityConfig
          }
        }
      `}
    />
    {organizationConnectionsRoute}
    {organizationIntegrationsRoute}
  </Route>
);
