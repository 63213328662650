import Layout from '@4c/layout';
import CrayonIcon from '@bfly/icons/Crayon';
import Button from '@bfly/ui2/Button';
import Form from '@bfly/ui2/Form';
import Heading from '@bfly/ui2/Heading';
import useUpdateImmediateEffect from '@restart/hooks/useUpdateImmediateEffect';
import { ReactNode, useMemo, useRef, useState } from 'react';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import ExamQAStandard from 'components/ExamQAStandard';
import IconButton from 'components/IconButton';
import { useVariation } from 'components/LaunchDarklyContext';
import PlusIconButton from 'components/PlusIconButton';
import RelayForm, { FormHandle } from 'components/RelayForm';
import useModalState from 'hooks/useModalState';
import actionMessages from 'messages/actions';
import {
  DeserializedStandardQa,
  ExamTypeProficiencyField,
  StandardQaForm,
  deserialize,
  schema,
  serialize,
  templateVersionToEntry,
} from 'schemas/standardQa';
import Analytics from 'utils/Analytics';
import { useViewerAllowMissingContext } from 'utils/viewerState';

import { useExamSetterContext } from './ExamContext';
import ExamQa from './ExamQa';
import ExamQaProficiency from './ExamQaProficiency';
import ExamQaSelectModal from './ExamQaSelectModal';
import ExamReviewedBy from './ExamReviewedBy';
import ScrollSpy, { ScrollSpyHandle } from './ScrollSpy';
import { ExamPageSidebarQa_study$data as Study } from './__generated__/ExamPageSidebarQa_study.graphql';

const STANDARD_QA_ID = 'examQAStandard';

const PLACEHOLDER_SECTION_ID = 'examPlaceholderSection';

const messages = defineMessages({
  addReviewCard: {
    id: 'examQa.addReviewCard',
    defaultMessage: 'Add Review Card',
  },
  standardQaTitle: {
    id: 'examQa.standardQa.title',
    defaultMessage: 'Butterfly Standard QA',
  },
});

function getPassSuggestion({
  imageQuality,
  accuracyComparedToGoldStandard,
  accuracyAsPresented,
}: Partial<DeserializedStandardQa>) {
  if (!imageQuality || !accuracyComparedToGoldStandard || !accuracyAsPresented)
    return undefined;

  const suggestFail =
    imageQuality === 1 ||
    imageQuality === 2 ||
    accuracyAsPresented === 'FALSE_NEGATIVE' ||
    accuracyAsPresented === 'FALSE_POSITIVE' ||
    accuracyComparedToGoldStandard === 'FALSE_NEGATIVE' ||
    accuracyComparedToGoldStandard === 'FALSE_POSITIVE';

  return !suggestFail;
}

const UpdateQaMutation = graphql`
  mutation ExamPageSidebarQa_UpdateMutation($input: QaStudyInput!) {
    qaStudyOrError(input: $input) {
      ... on QaStudyPayload {
        standardQa {
          study {
            ...ExamPageSidebarQa_study
          }
          imageQuality
          accuracyAsPresented
          accuracyComparedToGoldStandard
          feedback
          comments
          examTypeCredentialInfo {
            examType {
              id
              name
            }
            countsTowardCredentialing
          }
          createdBy {
            ...ExamReviewedBy_userProfile
          }
        }
      }
      ...RelayForm_error @relay(mask: false)
    }
  }
`;

interface Props {
  study: Study;
  className?: string;
  readOnly: boolean;
  onEdit: (nextValue: boolean) => void;
  formRef: (formRef: FormHandle) => void;
}
function ExamPageSidebarQa({
  study,
  className,
  readOnly,
  onEdit,
  formRef,
}: Props) {
  const { formatMessage } = useIntl();
  const viewer = useViewerAllowMissingContext();

  const { setShowErrors, setQaErrors } = useExamSetterContext();

  const scrollSpyRef = useRef<ScrollSpyHandle>(null);

  const canUseStandardQa = useVariation('standard-qa-templates');
  const { examTypes, viewerCanQa, organization } = study;

  const skipStandardQa =
    canUseStandardQa && organization!.workflowSettings?.skipStandardQa;

  const [showAddQaModal, addQaModalProps] = useModalState();

  const showExistingQaCards = useMemo(() => {
    const isAuthor = [study?.createdBy]
      .concat(study?.secondaryAuthors)
      .some((u) => u?.handle === viewer?.profile?.handle);
    return isAuthor || viewerCanQa;
  }, [
    viewerCanQa,
    study?.createdBy,
    study?.secondaryAuthors,
    viewer?.profile?.handle,
  ]);

  const [formValue, setFormValue] = useState(() =>
    deserialize(study, readOnly),
  );

  useUpdateImmediateEffect(() => {
    if (readOnly) return;

    setFormValue((prevValue) => {
      const nextExamTypeProficiency = examTypes!
        // remove deleted examTypes from values
        .filter((examType) => !examType?.deletedAt)
        .map(
          (examType) =>
            (prevValue.examTypeProficiency?.find(
              (v) => v.id === examType!.id,
            ) || {
              id: examType!.id,
              name: examType!.name!,
              countsTowardCredentialing: getPassSuggestion(prevValue),
              deletedAt: examType!.deletedAt,
            }) as ExamTypeProficiencyField,
        );

      return {
        ...prevValue,
        examTypeProficiency: nextExamTypeProficiency,
      };
    });
  }, [readOnly, setFormValue, examTypes]);

  const examTypeProficiencyDirty = useRef(
    // counts values that saved are considered dirty to prevent suggestion updates
    new Set(
      formValue
        .examTypeProficiency!.filter(
          (examType) => examType.countsTowardCredentialing != null,
        )
        .map((examType) => examType!.id!),
    ),
  );

  const passSuggestion = getPassSuggestion(formValue);

  const analyticsAttributes = {
    studyId: study.id,
    organizationId: study.organization?.id,
  };

  const handleFormOnChange = (
    nextValue: StandardQaForm,
    updatedPaths: string[],
  ) => {
    if (
      updatedPaths.includes('imageQuality') ||
      updatedPaths.includes('accuracyComparedToGoldStandard') ||
      updatedPaths.includes('accuracyAsPresented')
    ) {
      const nextPassSuggestion = getPassSuggestion(nextValue);
      nextValue.examTypeProficiency.forEach((examType) => {
        if (
          examType.countsTowardCredentialing !== nextPassSuggestion &&
          !examTypeProficiencyDirty.current.has(examType.id)
        ) {
          examType.countsTowardCredentialing = nextPassSuggestion!;
        }
      });
    }

    setFormValue(nextValue);
    Analytics.track('qaFormChanged', { ...analyticsAttributes, updatedPaths });
  };

  const sections = [] as Array<{ title: ReactNode; id: string }>;
  if (canUseStandardQa)
    sections.push({
      title: formatMessage(messages.standardQaTitle),
      id: STANDARD_QA_ID,
    });

  if (showExistingQaCards) {
    sections.push(
      ...formValue.qaEntries!.map((qa) => ({
        title: qa.templateVersion.title,
        id: qa.id,
      })),
    );
  }
  const defaultSection =
    skipStandardQa && sections.length > 1 ? sections[1] : sections[0];

  return (
    <RelayForm
      schema={schema}
      defaultValue={schema.getDefault()}
      value={formValue}
      formRef={formRef}
      formContext={{ canUseStandardQa }}
      onChange={handleFormOnChange}
      getInput={(nextValue) =>
        serialize(nextValue, study.id, canUseStandardQa)
      }
      mutation={UpdateQaMutation}
      onSubmitFinished={(validationError) => {
        if (!validationError) {
          onEdit(false);
          Analytics.track('qaFormSubmitted', analyticsAttributes);
        } else {
          setShowErrors(true);
          Analytics.track('qaFormSubmittedWithErrors', analyticsAttributes);
        }
      }}
      onError={setQaErrors}
      className={className}
    >
      <Heading
        color="subtitle"
        variant="sm-bold"
        transform="uppercase"
        className="flex justify-between items-center mt-4 mb-4 flex-0 px-5"
        flush
      >
        <FormattedMessage
          id="ExamPageSidebarQa.header"
          defaultMessage="QA Review"
        />

        {viewerCanQa &&
          (readOnly ? (
            <IconButton
              icon={<CrayonIcon height={12} />}
              title={<FormattedMessage {...actionMessages.edit} />}
              data-bni-id="ExamPageSidebarQaEdit"
              onClick={() => {
                onEdit(true);
                Analytics.track('editQaClicked', analyticsAttributes);
              }}
              className="m-0 ml-auto p-0 inline-flex h-auto"
              variant={null}
            />
          ) : (
            <>
              {
                // hide this button since we are showing the large blue button
                (!skipStandardQa || !!formValue.qaEntries?.length) && (
                  <PlusIconButton
                    title={<FormattedMessage {...messages.addReviewCard} />}
                    data-bni-id="AddQaButton"
                    onClick={() => {
                      showAddQaModal();
                      Analytics.track(
                        'addReviewCardClicked',
                        analyticsAttributes,
                      );
                    }}
                  />
                )
              }
            </>
          ))}
      </Heading>
      {study.standardQa?.createdBy && readOnly && (
        <ExamReviewedBy
          userProfile={study.standardQa.createdBy}
          className="flex items-center mb-4 flex-0 px-5"
          data-bni-id="ExamPageSidebarQa.reviewedBy"
          readOnly
        />
      )}
      <ScrollSpy ref={scrollSpyRef} defaultActiveSection={defaultSection?.id}>
        {!readOnly && sections.length > 1 && (
          <ScrollSpy.Tabs>
            {sections.map((section) => (
              <ScrollSpy.Tab key={section.id} sectionId={section.id}>
                {section.title}
              </ScrollSpy.Tab>
            ))}
          </ScrollSpy.Tabs>
        )}
        <ScrollSpy.ScrollView>
          {!showExistingQaCards ? (
            <div>
              <FormattedMessage
                id="ExamPageSidebarQa.body"
                defaultMessage="Only exam authors and QA reviewers are able to see QA results"
              />
            </div>
          ) : (
            <>
              {canUseStandardQa && (
                <ScrollSpy.Section
                  id={STANDARD_QA_ID}
                  data-bni-id="StandardQaSection"
                >
                  <Layout pad={4} direction="column">
                    <Heading flush>
                      <FormattedMessage {...messages.standardQaTitle} />
                    </Heading>
                    <ExamQAStandard
                      readOnly={readOnly}
                      showImageQualityTooltips={
                        !!study.organization?.workflowSettings
                          ?.showStandardQaImageQualityTooltips
                      }
                    />
                  </Layout>
                </ScrollSpy.Section>
              )}
              <Form.FieldArray name="qaEntries">
                {(qaEntries, arrayHelpers) => {
                  // add a placeholder section for the footer UI if no qaEntries sections exist
                  const entrySections = qaEntries.length
                    ? qaEntries
                    : [{ id: PLACEHOLDER_SECTION_ID }];

                  return (
                    <>
                      {entrySections.map((value, index) => {
                        const isLastSection =
                          entrySections.length - 1 === index;
                        return (
                          <ScrollSpy.Section
                            key={value.id}
                            id={value.id}
                            // Setting the last section to full height ensures
                            // that the scroll view has enough scroll area scroll
                            // to shorter last sections. Without it, the trailing
                            // section might be much shorter than the root element
                            // which would only scroll it to the bottom or middle
                            // of the screen
                            className={isLastSection ? 'min-h-full' : ''}
                          >
                            {PLACEHOLDER_SECTION_ID === value.id ? (
                              <>
                                {skipStandardQa && !readOnly && (
                                  <>
                                    <Button
                                      data-bni-id="AddReviewCardButton"
                                      onClick={showAddQaModal}
                                    >
                                      <FormattedMessage
                                        {...messages.addReviewCard}
                                      />
                                    </Button>
                                    <div className="my-4 border-b border-divider" />
                                  </>
                                )}
                              </>
                            ) : (
                              <>
                                <ExamQa
                                  name={`qaEntries[${index}]`}
                                  values={value.values}
                                  templateVersion={
                                    value.templateVersion as any
                                  }
                                  readOnly={readOnly}
                                  onDelete={() => {
                                    arrayHelpers.remove(value);
                                    Analytics.track('reviewCardRemoved', {
                                      ...analyticsAttributes,
                                      templateVersionId:
                                        value.templateVersion.id,
                                    });
                                  }}
                                />
                                {!study.standardQa && value.createdBy && (
                                  <ExamReviewedBy
                                    userProfile={value.createdBy}
                                    className="mt-2 mb-4"
                                    data-bni-id={`qaEntries[${index}]createdBy`}
                                    readOnly={readOnly}
                                  />
                                )}
                                <div className="mb-4 border-b border-divider" />
                              </>
                            )}
                            {/**
                             * Adding this footer UI to the last qa entry section
                             * enables proper scrolling to the last section.
                             */}
                            {isLastSection && (
                              <>
                                {canUseStandardQa && (
                                  <div data-bni-id="ExamQaProficiencySection">
                                    <ExamQaProficiency
                                      readOnly={readOnly}
                                      passSuggestion={passSuggestion}
                                      onChange={(examTypeProficiency) => {
                                        examTypeProficiencyDirty.current.add(
                                          examTypeProficiency.id,
                                        );
                                      }}
                                    />
                                  </div>
                                )}
                                {study.standardQa?.createdBy && !readOnly && (
                                  <ExamReviewedBy
                                    userProfile={study.standardQa.createdBy}
                                    className="mb-2 pt-4 border-t border-divider"
                                    data-bni-id="ExamPageSidebarQa.reviewedBy"
                                    readOnly={false}
                                  />
                                )}
                              </>
                            )}
                          </ScrollSpy.Section>
                        );
                      })}
                      <ExamQaSelectModal
                        {...addQaModalProps}
                        organizationId={study!.organization!.id}
                        existingTemplateIds={qaEntries.map(
                          (t) => t!.templateVersion.id,
                        )}
                        onSelect={(templateVersion) => {
                          const nextEntry = templateVersionToEntry(
                            templateVersion!,
                          );
                          // add the new QA template to the form
                          arrayHelpers.push(nextEntry);

                          scrollSpyRef.current?.scrollIntoView(nextEntry!.id);
                          Analytics.track('reviewCardSelected', {
                            ...analyticsAttributes,
                            templateVersionId: templateVersion.id,
                          });
                        }}
                      />
                    </>
                  );
                }}
              </Form.FieldArray>
            </>
          )}
        </ScrollSpy.ScrollView>
      </ScrollSpy>
    </RelayForm>
  );
}

export default createFragmentContainer(ExamPageSidebarQa, {
  study: graphql`
    fragment ExamPageSidebarQa_study on Study {
      id
      viewerCanQa
      numQaEntries
      createdBy {
        handle
      }
      examTypes {
        id
        name
        deletedAt
      }
      secondaryAuthors {
        handle
      }
      organization {
        id
        workflowSettings {
          showStandardQaImageQualityTooltips
          skipStandardQa
        }
      }
      qaEntries {
        templateVersion {
          ...ExamQa_templateVersion
        }
      }
      standardQa {
        createdAt
        createdBy {
          ...ExamReviewedBy_userProfile
        }
      }
      organization {
        id
      }
      ...standardQa_study
      ...standardQa_examTypeProficiency
    }
  `,
});
