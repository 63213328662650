import Layout from '@4c/layout';
import Button from '@bfly/ui2/Button';
import Modal from '@bfly/ui2/Modal';
import Text from '@bfly/ui2/Text';
import useQuery from '@bfly/ui2/useQuery';
import { useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import ArchiveDropdown from 'components/ArchiveDropdown';
import OrganizationDropdown from 'components/OrganizationDropdown';
import { usePermissions } from 'components/PermissionsProvider';
import useMoveStudies from 'hooks/useMoveStudies';
import actionMessages from 'messages/actions';
import { useViewerContext } from 'utils/viewerState';
import withModal from 'utils/withModal';

import { MoveStudyModalRefresh_Organization_Query } from './__generated__/MoveStudyModalRefresh_Organization_Query.graphql';
import { MoveStudyModalRefresh_domain$data as Domain } from './__generated__/MoveStudyModalRefresh_domain.graphql';
import { MoveStudyModalRefresh_organization$data as Organization } from './__generated__/MoveStudyModalRefresh_organization.graphql';

interface Props {
  organization: Organization;
  domain: Domain | null;
  studyAndArchiveIds: Array<{ studyId: string; archiveId: string }>;
  onHide: () => void;
  onOrganizationChange?: () => void;
}

function MoveStudyModalRefresh({
  organization,
  domain,
  studyAndArchiveIds,
  onHide,
  onOrganizationChange,
}: Props) {
  const [selectedOrganization, setSelectedOrganization] = useState({
    handle: organization.handle!,
    id: organization.id,
  });

  const [selectedArchiveId, setSelectedArchiveId] = useState<null | string>(
    studyAndArchiveIds[0]?.archiveId || null,
  );

  const sameArchive = useMemo(
    () =>
      studyAndArchiveIds.every(
        ({ archiveId }) => archiveId === selectedArchiveId,
      ),
    [selectedArchiveId, studyAndArchiveIds],
  );

  const [moveStudies, loading] = useMoveStudies();

  const { data } = useQuery<MoveStudyModalRefresh_Organization_Query>(
    graphql`
      query MoveStudyModalRefresh_Organization_Query($handle: String!) {
        organization(handle: $handle) {
          ...ArchiveDropdown_organization
        }
      }
    `,
    {
      fetchPolicy: 'store-and-network',
      variables: { handle: selectedOrganization.handle },
      skip: !selectedOrganization,
    },
  );

  const viewer = useViewerContext();
  const { hasBasicPermission } = usePermissions();

  const explicitMemberships = !(
    hasBasicPermission('accessAllOrganizations') &&
    hasBasicPermission('archiveManagement')
  );

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage
            id="moveStudyModalRefresh.title"
            values={{ numStudies: studyAndArchiveIds.length }}
            defaultMessage="{numStudies, plural,
              one {Move Study}
              other {Move Studies}
            }"
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body scrollable={false}>
        <Layout direction="column" pad={4} className="mt-2">
          {viewer.domain && (
            <Layout align="center">
              <Text as="div" className="w-1/4">
                <FormattedMessage
                  id="moveStudyModal.organization"
                  defaultMessage="Organization"
                />
              </Text>
              <Layout className="grow">
                <OrganizationDropdown
                  value={selectedOrganization.id}
                  onChange={(nextOrganization) => {
                    setSelectedOrganization({
                      handle: nextOrganization.handle!,
                      id: nextOrganization.id,
                    });
                    setSelectedArchiveId(null);
                  }}
                  className="w-full"
                  explicitMemberships={explicitMemberships}
                />
              </Layout>
            </Layout>
          )}
          <Layout align="center">
            <Text as="div" className="w-1/4">
              <FormattedMessage
                id="moveStudyModal.archive"
                defaultMessage="Archive"
              />
            </Text>
            <Layout className="grow">
              <ArchiveDropdown
                value={selectedArchiveId}
                organization={data?.organization || organization || null}
                onChange={(nextArchive) => {
                  setSelectedArchiveId(nextArchive.id);
                }}
                disabled={(items) =>
                  items && sameArchive && selectedArchiveId
                    ? items.filter((item) => item.id === selectedArchiveId)
                    : []
                }
                className="w-full"
              />
            </Layout>
          </Layout>
        </Layout>
      </Modal.Body>
      <Modal.Footer>
        <Modal.ButtonGroup>
          <Button
            disabled={!selectedArchiveId || sameArchive}
            busy={loading}
            onClick={async () => {
              if (!selectedArchiveId || !selectedOrganization) return;
              await moveStudies(
                studyAndArchiveIds,
                selectedArchiveId,
                selectedOrganization.id,
                organization.id,
                domain ? domain.id : '',
              );
              if (
                onOrganizationChange &&
                organization.id !== selectedOrganization.id
              ) {
                onOrganizationChange();
              }
              onHide();
            }}
          >
            <FormattedMessage id="moveStudyModal.move" defaultMessage="Move" />
          </Button>
          <Button variant="secondary" onClick={onHide}>
            <FormattedMessage {...actionMessages.cancel} />
          </Button>
        </Modal.ButtonGroup>
      </Modal.Footer>
    </>
  );
}

export default createFragmentContainer(
  withModal(MoveStudyModalRefresh, {
    variant: 'dark',
    size: 'sm',
  }),
  {
    organization: graphql`
      fragment MoveStudyModalRefresh_organization on Organization {
        id
        handle
        ...ArchiveDropdown_organization
      }
    `,
    domain: graphql`
      fragment MoveStudyModalRefresh_domain on Domain {
        id
      }
    `,
  },
);
