/**
 * @generated SignedSource<<d834152b88fa5ebb9cc39a42642c9114>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type PermissionLevel = "ADMIN" | "BASIC" | "NONE" | "%future added value";
import { FragmentRefs } from "relay-runtime";
export type AppLeadingNav_viewer$data = {
  readonly domain: {
    readonly organizationConnection: {
      readonly edges: ReadonlyArray<{
        readonly node: {
          readonly __typename: "Organization";
        } | null;
      } | null> | null;
    } | null;
    readonly viewerPermissions: {
      readonly accessAllOrganizations: PermissionLevel | null;
    } | null;
    readonly " $fragmentSpreads": FragmentRefs<"AppSearchGlobal_tenant">;
  } | null;
  readonly hasUnexpiredMembership: boolean | null;
  readonly memberships: ReadonlyArray<{
    readonly organization: {
      readonly __typename: "Organization";
    } | null;
  } | null> | null;
  readonly " $fragmentType": "AppLeadingNav_viewer";
};
export type AppLeadingNav_viewer$key = {
  readonly " $data"?: AppLeadingNav_viewer$data;
  readonly " $fragmentSpreads": FragmentRefs<"AppLeadingNav_viewer">;
};

const node: ReaderFragment = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "__typename",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AppLeadingNav_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasUnexpiredMembership",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ViewerMembership",
      "kind": "LinkedField",
      "name": "memberships",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Organization",
          "kind": "LinkedField",
          "name": "organization",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Domain",
      "kind": "LinkedField",
      "name": "domain",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ViewerPermissions",
          "kind": "LinkedField",
          "name": "viewerPermissions",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "accessAllOrganizations",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "OrganizationConnection",
          "kind": "LinkedField",
          "name": "organizationConnection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "OrganizationEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Organization",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": (v0/*: any*/),
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AppSearchGlobal_tenant"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();

(node as any).hash = "10ad55c4eeeba58c7da0231ff94234a3";

export default node;
