import React from 'react';
import { graphql } from 'react-relay';

import { getVariation } from 'components/LaunchDarklyContext';
import LaunchDarklyRoute from 'components/LaunchDarklyRoute';
import Route from 'components/Route';
import { checkRoutePermission } from 'utils/RouteAccessControl';

import WorksheetsPageRenderer from './components/WorksheetsPageRenderer';

export const organizationWorksheetsRoute = (
  <LaunchDarklyRoute
    path="worksheets"
    getVariation={(ldClient) =>
      getVariation(ldClient, 'worksheets') &&
      !getVariation(ldClient, 'domain-workflow-settings') && {
        query: graphql`
          query organizationWorksheetRoutes_checkIsAdmin_Query(
            $organizationSlug: String!
          ) {
            organization: organizationBySlug(slug: $organizationSlug) {
              viewerPermissions {
                ...RouteAccessControl_viewerPermissions
              }
            }
          }
        `,
      }
    }
    prerender={checkRoutePermission(
      'studyDocumentation',
      'BASIC',
      'organization',
    )}
  >
    <Route
      getComponent={() =>
        import(
          /* webpackChunkName: "admin" */ './components/OrganizationWorksheetsSettingsContent'
        ).then((m) => m.default)
      }
      query={graphql`
        query organizationWorksheetRoutes_WorksheetsSettingsContent_Query(
          $organizationSlug: String!
          $sort: [WorksheetTemplateSorting!]
        ) {
          organization: organizationBySlug(slug: $organizationSlug) {
            ...OrganizationWorksheetsSettingsContent_organization
              @arguments(sort: $sort)
          }
        }
      `}
      prepareVariables={(variables, { location: { query } }) => ({
        ...variables,
        sort: query.sort,
      })}
      render={({ Component, props, match }) => {
        return (
          <WorksheetsPageRenderer
            Component={Component}
            props={props}
            match={match}
          />
        );
      }}
    />
    <Route
      path="-/new"
      fullScreen
      getComponent={() =>
        import(
          /* webpackChunkName: "admin" */ './components/CreateWorksheetTemplateForm'
        ).then((m) => m.default)
      }
      query={graphql`
        query organizationWorksheetRoutes_CreateWorksheetTemplateForm_Query(
          $organizationSlug: String!
        ) {
          connectivityConfig: organizationBySlug(slug: $organizationSlug) {
            ...CreateWorksheetTemplateForm_connectivityConfig
          }
          workflowConfig: organizationBySlug(slug: $organizationSlug) {
            ...CreateWorksheetTemplateForm_workflowConfig
          }
        }
      `}
      renderFetched={({ Component, props }) => <Component {...props} />}
    />
    <Route fullScreen path=":templateHandle">
      <Route
        getComponent={() =>
          import(
            /* webpackChunkName: "admin" */ './components/UpdateWorksheetTemplateForm'
          ).then((m) => m.default)
        }
        query={graphql`
          query organizationWorksheetRoutes_UpdateWorksheetTemplateForm_Query(
            $templateHandle: String!
            $organizationSlug: String!
          ) {
            template: worksheetTemplate(handle: $templateHandle) {
              ...UpdateWorksheetTemplateForm_template
            }
            connectivityConfig: organizationBySlug(slug: $organizationSlug) {
              ...UpdateWorksheetTemplateForm_connectivityConfig
            }
          }
        `}
      />
      <Route path="prefills">
        <Route
          path="-/new"
          getComponent={() =>
            import(
              /* webpackChunkName: "admin" */ './components/CreateWorksheetPrefillForm'
            ).then((m) => m.default)
          }
          query={graphql`
            query organizationWorksheetRoutes_CreateWorksheetPrefill_Query(
              $templateHandle: String!
            ) {
              template: worksheetTemplate(handle: $templateHandle) {
                ...CreateWorksheetPrefillForm_template
              }
            }
          `}
        />
        <Route
          path=":prefillHandle"
          getComponent={() =>
            import(
              /* webpackChunkName: "admin" */ './components/UpdateWorksheetPrefillForm'
            ).then((m) => m.default)
          }
          query={graphql`
            query organizationWorksheetRoutes_UpdateWorksheetPrefill_Query(
              $prefillHandle: String!
            ) {
              prefill: worksheetPrefill(handle: $prefillHandle) {
                ...UpdateWorksheetPrefillForm_prefill
              }
            }
          `}
        />
      </Route>
    </Route>
  </LaunchDarklyRoute>
);
