import { encodeGlobalId } from '@bfly/utils/codecs';
import HttpError from 'found/HttpError';
import Redirect from 'found/Redirect';
import RedirectException from 'found/RedirectException';
import { graphql } from 'react-relay';

import Route, {
  Prerender,
  RelayRouteRenderArgs,
  chainPrerenders,
} from 'components/Route';
import { routes as scanLabRoutes } from 'routes/scanLab';
import { checkFlagsOr404 } from 'utils/RouteAccessControl';
import { canAccessPortfolio, canAccessReview } from 'utils/canAccessScanLab';

import { examMeasurementRoutes } from '../exams/routes/measurements';
import { scanLab_EduStudy_Query as EduStudyQuery } from './__generated__/scanLab_EduStudy_Query.graphql';
import EduStudiesPage from './components/EduStudiesPage';
import EduStudyImage from './components/EduStudyImage';
import EduStudyPage from './components/EduStudyPage';
import ScanLabAppPage from './components/ScanLabAppPage';

const eduStudiesPagePrerender: Prerender = (prerenderProps) => {
  const { ldClient, viewerState, match } = prerenderProps;

  if (!viewerState || !ldClient || !match.params.listRoute) return;

  const canSeePortfolio = canAccessPortfolio(viewerState, ldClient);

  if (match.params.listRoute === 'portfolio' && canSeePortfolio) return;

  if (
    match.params.listRoute === 'review' &&
    canAccessReview(viewerState, ldClient).canReview
  ) {
    if (match.params.reviewStatus === 'unsubmitted' && canSeePortfolio) {
      throw new RedirectException(scanLabRoutes.portfolio());
    }
    return;
  }

  throw new HttpError(404);
};
export default (
  <Route
    name="scanlab"
    path="scanlab"
    prerender={(prerenderProps) => {
      const { ldClient, viewerState } = prerenderProps;
      if (!viewerState || !ldClient) return;
      if (!canAccessPortfolio(viewerState, ldClient)) throw new HttpError(404);
    }}
    Component={ScanLabAppPage}
  >
    <Redirect
      to={() => ({
        pathname: scanLabRoutes.portfolio(),
      })}
    />
    <Route
      path=":listRoute(portfolio|review)"
      prerender={eduStudiesPagePrerender}
      Component={EduStudiesPage}
    />
    <Route
      hideSidePanel
      path="exams/:studyHandle"
      Component={EduStudyPage}
      query={graphql`
        query scanLab_EduStudy_Query($eduStudyId: ID!) {
          study: node(id: $eduStudyId) {
            ... on EduStudy {
              ...EduStudyPage_study
            }
          }
        }
      `}
      prepareVariables={(variables) => {
        return {
          ...variables,
          eduStudyId: encodeGlobalId('EduStudy', variables.studyHandle),
        };
      }}
      prerender={chainPrerenders(
        checkFlagsOr404('scanlab-learner-view'),
        ({ props }: RelayRouteRenderArgs<EduStudyQuery['response']>) => {
          // manually throw 404 if study does not belong to viewer
          if (props && !props?.study) {
            throw new HttpError(404);
          }
        },
      )}
    >
      {{
        leftSide: (
          <Route
            defer
            Component={EduStudyImage}
            query={graphql`
              query scanLab_ScanLabExamImagePage_Query($eduStudyId: ID!) {
                study: node(id: $eduStudyId) {
                  ...EduStudyImage_study
                }
              }
            `}
            prepareVariables={(variables) => {
              return {
                ...variables,
                eduStudyId: encodeGlobalId('EduStudy', variables.studyHandle),
              };
            }}
          >
            <Route />
            <Route path=":studyImageHandle">
              <Route />
              {examMeasurementRoutes}
            </Route>
          </Route>
        ),
      }}
    </Route>
  </Route>
);
