import Layout from '@4c/layout';
import Button from '@bfly/ui2/Button';
import Caret from '@bfly/ui2/Caret';
import getNodes from '@bfly/utils/getNodes';
import { stylesheet } from 'astroturf/react';
import clsx from 'clsx';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import PermissionTooltip from 'components/PermissionTooltip';
import { usePermissions } from 'components/PermissionsProvider';

import ArchiveListItem from './ArchiveListItem';
import { DeletedArchiveSection_organization$data as Organization } from './__generated__/DeletedArchiveSection_organization.graphql';

const styles = stylesheet`
  .item a {
    @apply text-subtitle #{!important};

    &:hover,
    &:focus,
    &.active {
      @apply text-body #{!important};
    }
  }
`;

interface Props {
  organization: Organization;
  className?: string;
}

function DeletedArchiveSection({ organization, className }: Props) {
  const { deletedArchiveConnection } = organization!;
  const { hasBasicPermission } = usePermissions();

  const [open, setOpen] = useState(false);

  const canSeeDeleteArchives = hasBasicPermission('archiveManagement');

  if (!deletedArchiveConnection!.edges!.length) return null;

  return (
    <>
      <PermissionTooltip wrap hide={canSeeDeleteArchives}>
        <Button
          className={clsx(
            'font-extrabold w-full inline-block px-app-panel-sm items-center text-subtitle hover:text-body mt-4',
            !canSeeDeleteArchives && 'pointer-events-none',
          )}
          disabled={!canSeeDeleteArchives}
          variant="text-primary"
          onClick={() => {
            setOpen((prevOpen) => !prevOpen);
          }}
        >
          <Layout
            align="center"
            justify="space-between"
            className="text-md"
            data-bni-id="DeletedArchivesHeader"
          >
            <FormattedMessage
              id="deletedArchiveSection.header"
              defaultMessage="Deleted Archives"
            />
            <Caret flipped={open} />
          </Layout>
        </Button>
      </PermissionTooltip>

      {open &&
        canSeeDeleteArchives &&
        getNodes(deletedArchiveConnection).map((archive) => (
          <ArchiveListItem
            archive={archive}
            key={archive.id}
            activeClassName={styles.active}
            className={clsx(styles.item, className)}
          />
        ))}
    </>
  );
}

// TODO: This could be a refetch container that lazily loads the deleted
//  archives.
export default createFragmentContainer(DeletedArchiveSection, {
  organization: graphql`
    fragment DeletedArchiveSection_organization on Organization {
      deletedArchiveConnection: archiveConnection(
        first: 2147483647
        isDeleted: true
      )
        @connection(
          key: "Organization_deletedArchiveConnection"
          filters: []
        ) {
        edges {
          node {
            id
            ...ArchiveListItem_archive
          }
        }
      }
    }
  `,
});
