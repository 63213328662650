import Layout from '@4c/layout';
import FormattedDateTime from '@bfly/ui2/FormattedDateTime';
import MeatballDropdownButton from '@bfly/ui2/MeatballDropdownButton';
import Tooltip from '@bfly/ui2/Tooltip';
import { css } from 'astroturf';
import { FormattedRelativeTime, defineMessages, useIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import { readInlineData } from 'relay-runtime';

import { usePermissions } from 'components/PermissionsProvider';

import CommentAuthor from './CommentAuthor';
import CommentBody from './CommentBody';
import DeleteStudyImageCommentControl from './DeleteStudyImageCommentControl';
import { CommentListItem_comment$data as Comment } from './__generated__/CommentListItem_comment.graphql';
import { CommentListItem_isCommentAuthor$key as CommentAuthorKey } from './__generated__/CommentListItem_isCommentAuthor.graphql';

const messages = defineMessages({
  commentActions: {
    id: 'commentListItem.commentActions',
    defaultMessage: 'Comment actions menu',
  },
});
const MS_PER_SEC = 1000;
const getRelativeDifference = (time) => {
  const date = new Date(time);
  const now = new Date();
  const timeDiff = now.getTime() - date.getTime();

  const diffInSeconds = Math.floor(timeDiff / MS_PER_SEC);
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);
  if (diffInDays < 31) {
    return (
      <FormattedRelativeTime
        value={(diffInSeconds || 1) * -1}
        updateIntervalInSeconds={3}
      />
    );
  }

  // we assume that every month has 31 days - this introduces an error gap, where we would display '30 days ago' instead of '1 month ago' but it's negligible
  const diffInMonths = Math.floor(diffInDays / 31);
  if (diffInMonths < 12)
    return <FormattedRelativeTime value={diffInMonths * -1} unit="month" />;

  const diffInYears = Math.floor(diffInMonths / 12);
  if (diffInYears)
    return <FormattedRelativeTime value={diffInYears * -1} unit="year" />;

  // emergency fallback - this should never happen, but if it does we wanna handle it nicely
  return <FormattedDateTime value={time} />;
};

function isCommentAuthor(
  viewerProfileId?: string,
  commentRef?: CommentAuthorKey,
): boolean {
  if (!viewerProfileId || !commentRef) {
    return false;
  }

  const comment = readInlineData(
    graphql`
      fragment CommentListItem_isCommentAuthor on StudyImageComment @inline {
        createdBy {
          id
        }
      }
    `,
    commentRef,
  );

  return comment?.createdBy?.id === viewerProfileId;
}

interface CommentListItemProps {
  comment: Comment;
}

function CommentListItem({ comment }: CommentListItemProps) {
  const { formatMessage } = useIntl();
  const { hasBasicPermission, viewerProfileId } = usePermissions();

  const canDeleteComment =
    hasBasicPermission('dataDeletion') ||
    isCommentAuthor(viewerProfileId, comment);

  return (
    <div data-bni-id="CommentListItem">
      <Layout align="center" pad>
        <CommentAuthor comment={comment} />
        <Tooltip.Trigger
          tooltip={<FormattedDateTime value={comment.createdAt!} />}
        >
          <span className="text-body">
            {getRelativeDifference(comment.createdAt)}
          </span>
        </Tooltip.Trigger>

        {canDeleteComment && (
          <>
            <Layout.Spacer />
            <MeatballDropdownButton
              data-bni-id="CommentActions"
              id={`${comment.id}-dropdown`}
              label={formatMessage(messages.commentActions)}
            >
              <DeleteStudyImageCommentControl comment={comment} />
            </MeatballDropdownButton>
          </>
        )}
      </Layout>

      <CommentBody
        comment={comment}
        css={css`
          /* avatar + padding in the CommentAuthor */
          position: relative;
          padding-left: calc(24px + theme('spacing.3'));
          margin-top: theme('spacing.3');
          white-space: pre-wrap;
        `}
      />
    </div>
  );
}

export default createFragmentContainer(CommentListItem, {
  comment: graphql`
    fragment CommentListItem_comment on StudyImageComment {
      createdBy {
        ...Avatar_userProfile
        name
      }
      id
      createdAt
      viewerCanDelete
      ...CommentAuthor_comment
      ...CommentBody_comment
      ...DeleteStudyImageCommentControl_comment
      ...CommentListItem_isCommentAuthor
    }
  `,
});
