/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/naming-convention */
import SearchIcon from '@bfly/icons/Search';
import TgcIcon from '@bfly/icons/Tgc';
import Button from '@bfly/ui2/Button';
import Link from '@bfly/ui2/Link';
import Multiselect from '@bfly/ui2/Multiselect';
import useMutationWithError from '@bfly/ui2/useMutationWithError';
import useImmediateUpdateEffect from '@restart/hooks/useImmediateUpdateEffect';
import useStableMemo from '@restart/hooks/useStableMemo';
import { css, stylesheet } from 'astroturf';
import clsx from 'clsx';
import sortBy from 'lodash/sortBy';
import uniq from 'lodash/uniq';
import uniqBy from 'lodash/uniqBy';
import {
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import { ChangeHandler } from 'react-widgets/Multiselect';

import useSearchQuery from 'hooks/useSearchQuery';
import useSearchState from 'hooks/useSearchState';

import { routes } from '../routes/exam';
import { SearchConstants } from '../utils/Search';
import getConcreteTenant from '../utils/getConcreteTenant';
import { useVariation } from './LaunchDarklyContext';
import SearchBarGlobalList, {
  FREE_TEXT_SEARCH_ITEM,
  FreeTextItem,
  RecentSearch,
  SavedSearch,
} from './SearchBarGlobalList';
import {
  SearchBarGlobalOrganizationQuery,
  StudySearchSuggestionType,
} from './__generated__/SearchBarGlobalOrganizationQuery.graphql';
import { SearchBarGlobal_Delete_Mutation as DeleteMutation } from './__generated__/SearchBarGlobal_Delete_Mutation.graphql';
import { SearchBarGlobal_organization$data as Organization } from './__generated__/SearchBarGlobal_organization.graphql';
import { SearchBarGlobal_searchNodes$data as SearchNodes } from './__generated__/SearchBarGlobal_searchNodes.graphql';
import { SearchBarGlobal_tenant$data as Tenant } from './__generated__/SearchBarGlobal_tenant.graphql';

export type { StudySearchSuggestionType };

export type SearchSuggestionResult<T extends StudySearchSuggestionType, N> = {
  type: T;
  node: N;
};

interface NodeTypeToSearchType {
  Archive: 'ARCHIVE';
  UserProfile: 'USER_PROFILE';
  DomainMembership: 'DOMAIN_MEMBERSHIP';
  OrganizationMembership: 'ORGANIZATION_MEMBERSHIP';
  StudyTag: 'STUDY_TAG';
  ExamType: 'EXAM_TYPE';
  Organization: 'ORGANIZATION';
  WorksheetTemplate: 'WORKSHEET_TEMPLATE';
}

type ValidTypes = keyof NodeTypeToSearchType;

// Util to ensures that we end up with a union of results instead of unions on type
type DistributeResult<K, V> = K extends ValidTypes
  ? V extends { __typename: K }
    ? SearchSuggestionResult<NodeTypeToSearchType[K], V>
    : never
  : never;

type SearchValue = DistributeResult<ValidTypes, SearchNodes[0]>;

type SearchDataItem = RecentSearch | SavedSearch | SearchValue | FreeTextItem;

function push<T>(value: T[] | null | T, newValue: T) {
  if (value == null) return [newValue];
  return uniq(Array.isArray(value) ? [...value, newValue] : [value, newValue]);
}

const messages = defineMessages({
  search: {
    id: 'studySearchBarGlobal.search',
    defaultMessage: 'Search',
  },
});

const ordering: Array<SearchConstants | StudySearchSuggestionType> = [
  SearchConstants.FREE_TEXT_SEARCH,
  'USER_PROFILE',
  'DOMAIN_MEMBERSHIP',
  'ORGANIZATION_MEMBERSHIP',
  'ORGANIZATION',
  'ARCHIVE',
  'EXAM_TYPE',
  'STUDY_TAG',
  'WORKSHEET_TEMPLATE',
];

function getItemTypeOrdering(item: SearchDataItem) {
  return ordering.indexOf(item.type);
}

function dataKey(item: SearchDataItem): string {
  switch (item.type) {
    case 'DOMAIN_MEMBERSHIP':
    case 'ORGANIZATION_MEMBERSHIP':
      return item.node!.userProfile!.id!;

    case 'USER_PROFILE':
    case 'ARCHIVE':
    case 'STUDY_TAG':
    case 'EXAM_TYPE':
      return item.node!.id!;
    default:
      return item.type!;
  }
}

export interface SearchBarHandle {
  focus(): void;
}

// todo: replace with tailwind classes
const styles = stylesheet`
  .multiselect {
    composes: multiselect from './Search.module.scss';

    & :global(.rw-popup-container) {
      right: -4rem;
    }
  }
  .control {
    composes: control from './Search.module.scss';
    --bni-form-control-bg-color: transparent;
  }
`;

const _ = graphql`
  fragment SearchBarGlobal_suggestionResult on StudySearchSuggestionResult {
    type
    node {
      ...SearchBarGlobal_searchNodes @relay(mask: false)
    }
  }
`;

function useSearchData(searchTerm: string, skip = true, tenant: Tenant) {
  const showTags = useVariation('study-tags');
  const showExamTypes = useVariation('exam-types');

  const isDomain = !!getConcreteTenant(tenant).domain;
  // memo needed because of the array in the variables
  const variables = useMemo(() => {
    return {
      slug: tenant.slug,
      limitTypes: [
        showTags && 'STUDY_TAG',
        showExamTypes && 'EXAM_TYPE',
        'ARCHIVE',
        'WORKSHEET_TEMPLATE',
        isDomain && 'ORGANIZATION',
        isDomain ? 'DOMAIN_MEMBERSHIP' : 'ORGANIZATION_MEMBERSHIP',
      ].filter(Boolean) as StudySearchSuggestionType[],
    };
  }, [tenant.slug, showTags, showExamTypes, isDomain]);

  const { data, loading } = useSearchQuery<SearchBarGlobalOrganizationQuery>(
    graphql`
      query SearchBarGlobalOrganizationQuery(
        $slug: String
        $search: String!
        $limitTypes: [StudySearchSuggestionType!]
      ) {
        tenant(slug: $slug) {
          studySearchSuggestions(search: $search, limitTypes: $limitTypes) {
            ...SearchBarGlobal_suggestionResult @relay(mask: false)
          }
        }
      }
    `,
    searchTerm,
    variables,
  );

  const results = useStableMemo(() => {
    const nextResults: SearchDataItem[] = [FREE_TEXT_SEARCH_ITEM];

    if (data)
      nextResults.push(
        ...(data.tenant!.studySearchSuggestions! as SearchDataItem[]),
      );

    return nextResults;
  }, [data]);

  return [
    sortBy(results, getItemTypeOrdering),
    { loading: !skip && loading },
  ] as const;
}

function useSearchTerm(search: string | null | undefined) {
  const propsSearchTerm = search || '';

  const [searchTerm, setSearchTerm] = useState(propsSearchTerm);
  useImmediateUpdateEffect(() => {
    setSearchTerm(propsSearchTerm);
  }, [propsSearchTerm]);

  return [searchTerm, setSearchTerm] as const;
}

function useInputScrollPosition(ref: React.RefObject<HTMLDivElement>) {
  // In case the search bar is narrow enough to need scrolling, move the
  // scroll position to the end, so the "Search" placeholder is visible.
  useEffect(() => {
    if (!ref.current) return;
    const list = ref.current.querySelector('.rw-multiselect-taglist')!;
    const { scrollWidth } = list;
    if (scrollWidth) list.scrollLeft = scrollWidth;
  }, [ref]);
}

interface Props {
  tenant: Tenant;
  organization: Organization | null;
  searchNodes: SearchNodes | null;
  autoFocus?: boolean;
  className?: string;
  style?: React.CSSProperties;
}

const SearchBarGlobal = forwardRef<SearchBarHandle, Props>(
  (
    { tenant, organization, searchNodes: __, autoFocus, className, style },
    outerRef,
  ) => {
    const { formatMessage } = useIntl();
    const ref = useRef<HTMLDivElement>(null);
    const isDomain = !!getConcreteTenant(tenant).domain;

    const searchRoute = routes.search({
      slug: tenant.slug || '-',
    });
    const shouldDefaultOrganization = !!organization && isDomain;

    const [searchState, setSearchState] = useSearchState(tenant!);

    const [open, setOpen] = useState(false);
    const [shouldFetch, setShouldFetch] = useState(false);

    const [searchTerm, setSearchTerm] = useSearchTerm(
      searchState.freeText || '',
    );

    const [searchResults, { loading }] = useSearchData(
      searchTerm,
      !shouldFetch,
      tenant,
    );

    const listData = useMemo(
      () => sortBy(searchResults, getItemTypeOrdering),
      [searchResults],
    );

    useInputScrollPosition(ref);

    const handleSearchUpdated = useCallback(
      (nextSearchTerm?: string, nextValue?: SearchValue) => {
        const nextSearch = { ...searchState };
        if (nextValue) {
          switch (nextValue.type) {
            case 'ARCHIVE':
              nextSearch.archive = push(nextSearch.archive, nextValue.node.id);
              break;
            case 'STUDY_TAG':
              nextSearch.tag = push(nextSearch.tag, nextValue.node.id);
              break;
            case 'DOMAIN_MEMBERSHIP':
            case 'ORGANIZATION_MEMBERSHIP':
              nextSearch.author = uniqBy(
                [
                  ...(nextSearch?.author ?? []),
                  {
                    label: nextValue.node.userProfile!.name,
                    value: nextValue.node.userProfile!.id,
                  },
                ],
                'value',
              );
              break;
            case 'USER_PROFILE':
              nextSearch.author = uniqBy(
                [
                  ...(nextSearch?.author ?? []),
                  {
                    label: nextValue.node.name,
                    value: nextValue.node.id,
                  },
                ],
                'value',
              );
              break;
            case 'EXAM_TYPE':
              nextSearch.examType = push(
                nextSearch.examType,
                nextValue.node.id,
              );
              break;
            case 'ORGANIZATION':
              nextSearch.organization = push(
                nextSearch.organization,
                nextValue.node.id,
              );
              break;
            case 'WORKSHEET_TEMPLATE':
              nextSearch.worksheetTemplate = push(
                nextSearch.worksheetTemplate,
                nextValue.node.id,
              );
              break;
            default:
            /* nothing */
          }
        }
        if (nextSearchTerm !== undefined) {
          nextSearch.freeText = nextSearchTerm;
        }
        // If coming from an org, default to that org
        if (shouldDefaultOrganization) {
          nextSearch.organization = [organization.id];
        }

        setSearchState(nextSearch);
      },
      [searchState, setSearchState, organization, shouldDefaultOrganization],
    );

    const [deleteMutation] = useMutationWithError<DeleteMutation>(
      graphql`
        mutation SearchBarGlobal_Delete_Mutation(
          $input: DeleteStudySavedSearchInput!
        ) {
          deleteStudySavedSearchOrError(input: $input) {
            ... on DeleteStudySavedSearchOrErrorPayload {
              __typename
            }
          }
        }
      `,
    );

    const handleClearOption = useCallback(
      (searchQuery: SavedSearch) =>
        deleteMutation({ input: { studySavedSearchId: searchQuery.id } }),
      [deleteMutation],
    );

    const handleSelectSearchQuery = (search: RecentSearch | SavedSearch) => {
      setSearchState(search.searchData!);
      setOpen(false);
    };

    const handleChange: ChangeHandler<SearchDataItem> = (
      _value,
      { dataItem },
    ) => {
      if (
        dataItem.type === SearchConstants.RECENT_SEARCH ||
        dataItem.type === SearchConstants.SAVED_SEARCH
      ) {
        handleSelectSearchQuery(dataItem);

        // We don't want to to include the free text sentinel in the value
      } else if (dataItem.type === SearchConstants.FREE_TEXT_SEARCH) {
        handleSearchUpdated(searchTerm);
      } else {
        // if we selected something then consider the searchTerm "used" and clear it
        setSearchTerm('');

        // In the above, update the state immediately so the search UI reflects
        // the selections; no need to wait for navigation query.
        handleSearchUpdated('', dataItem as SearchValue);
      }
      setOpen(false);
    };

    const handleKeyDown = ({ key }: React.KeyboardEvent<HTMLDivElement>) => {
      if (key === 'Enter' && !open) {
        handleSearchUpdated(searchTerm);
      }
    };

    const handleSearch = useCallback(
      (nextSearchTerm, meta) => {
        // Don't let react-widgets automatically clear the search term on blur or
        // on other events. We will manually handle the update.
        if (meta && meta.action === 'clear') {
          return;
        }

        setSearchTerm(nextSearchTerm);
      },
      [setSearchTerm],
    );

    const handleToggle = useCallback((nextOpen) => {
      if (nextOpen) {
        setShouldFetch(true);
      }

      setOpen(nextOpen);
    }, []);

    return (
      <div
        ref={ref}
        data-bni-id="SearchBarGlobal"
        className={clsx(className, 'flex items-center')}
        style={style}
      >
        <Link
          to={{
            pathname: searchRoute,
            state: {
              searchData: shouldDefaultOrganization
                ? {
                    ...searchState,
                    organization: searchState.organization || [
                      organization.id,
                    ],
                  }
                : searchState,
            },
          }}
        >
          {({ active, ...props }) =>
            !active ? (
              <Button
                {...props}
                data-bni-id="SearchPageButton"
                className="h-full px-2 outline-none"
                variant="text-secondary"
              >
                <TgcIcon className="h-5 w-5 p-0" />
              </Button>
            ) : null
          }
        </Link>

        <Multiselect
          focusFirstItem
          variant="secondary"
          menuVariant="dark"
          css={css`
            :global(.rw-popup) {
              position: absolute;
              width: 108%;
              margin-top: 0.2rem;
            }
          `}
          ref={outerRef as any}
          autoFocus={autoFocus}
          data={listData}
          groupBy={(item) =>
            item.type === SearchConstants.SAVED_SEARCH ||
            item.type === SearchConstants.RECENT_SEARCH
              ? item.type
              : ''
          }
          className={styles.multiselect}
          containerClassName={styles.control}
          value={[]}
          listComponent={SearchBarGlobalList as any}
          listProps={{ onClearOption: handleClearOption }}
          inline={!!open}
          searchTerm={searchTerm}
          filter={false}
          dataKey={dataKey}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onSearch={handleSearch}
          onToggle={handleToggle}
          busy={loading}
          placeholder={formatMessage(messages.search)}
          showPlaceholderWithValues
        />
        <Button
          variant="text-secondary"
          className="h-full"
          onClick={() => {
            handleSearchUpdated(searchTerm);
          }}
        >
          <SearchIcon className="h-5 w-5" />
        </Button>
      </div>
    );
  },
);

export default createFragmentContainer(SearchBarGlobal, {
  tenant: graphql`
    fragment SearchBarGlobal_tenant on TenantInterface {
      ...useSearchState_tenant
      ...getConcreteTenant_tenant
      ... on Organization {
        slug
      }
    }
  `,
  organization: graphql`
    fragment SearchBarGlobal_organization on Organization {
      id
    }
  `,
  searchNodes: graphql`
    fragment SearchBarGlobal_searchNodes on Node @relay(plural: true) {
      __typename
      ... on Archive {
        id
        handle
        ...SearchBarListSuggestionOption_archive
      }
      ... on UserProfile {
        id
        handle
        name
        ...SearchBarListSuggestionOption_userProfile
      }
      ... on DomainMembership {
        ...SearchBarListSuggestionOption_membership
        userProfile {
          id
          name
        }
      }
      ... on OrganizationMembership {
        ...SearchBarListSuggestionOption_membership
        userProfile {
          id
          name
        }
      }
      ... on StudyTag {
        id
        handle
        ...SearchBarListSuggestionOption_studyTag
      }
      ... on ExamType {
        id
        handle
        ...SearchBarListSuggestionOption_examType
      }
      ... on Organization {
        id
        handle
        ...SearchBarListSuggestionOption_organization
      }
      ... on WorksheetTemplate {
        id
        ...SearchBarListSuggestionOption_worksheetTemplate
      }
    }
  `,
});
