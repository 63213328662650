import Layout from '@4c/layout';
import FormattedDateTime from '@bfly/ui2/FormattedDateTime';
import Text from '@bfly/ui2/Text';
import getNodes from '@bfly/utils/getNodes';
import styled, { stylesheet } from 'astroturf/react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';
import { DeepNonNullable } from 'utility-types';

import Dl from 'components/DescriptionList';
import PatientInfo from 'components/PatientInfo';
import PatientName from 'components/PatientName';
import VetPatientInfo from 'components/VetPatientInfo';
import VetPatientName from 'components/VetPatientName';
import studyMessages from 'messages/study';

import { StudyHistoryModalContent_authorsUpdatedStudyEvent$data as AuthorsUpdatedStudyEvent } from './__generated__/StudyHistoryModalContent_authorsUpdatedStudyEvent.graphql';
import { StudyHistoryModalContent_finalizationRequestedStudyEvent$data as FinalizationRequestedStudyEvent } from './__generated__/StudyHistoryModalContent_finalizationRequestedStudyEvent.graphql';
import { StudyHistoryModalContent_imageAddedStudyEvent$data as ImageAddedStudyEvent } from './__generated__/StudyHistoryModalContent_imageAddedStudyEvent.graphql';
import { StudyHistoryModalContent_imagesDeletedStudyEvent$data as ImagesDeletedStudyEvent } from './__generated__/StudyHistoryModalContent_imagesDeletedStudyEvent.graphql';
import { StudyHistoryModalContent_movedStudyEvent$data as MovedStudyEvent } from './__generated__/StudyHistoryModalContent_movedStudyEvent.graphql';
import { StudyHistoryModalContent_qaSubmittedStudyEvent$data as QaSubmittedStudyEvent } from './__generated__/StudyHistoryModalContent_qaSubmittedStudyEvent.graphql';
import {
  StudyHistoryModalContent_study$data as Study,
  StudyVersionReason,
} from './__generated__/StudyHistoryModalContent_study.graphql';
import { StudyHistoryModalContent_tagsUpdatedStudyEvent$data as TagsUpdatedStudyEvent } from './__generated__/StudyHistoryModalContent_tagsUpdatedStudyEvent.graphql';
import { StudyHistoryModalContent_versionCreatedStudyEvent$data as VersionCreatedStudyEvent } from './__generated__/StudyHistoryModalContent_versionCreatedStudyEvent.graphql';

type PickEnum<T, K extends keyof DeepNonNullable<T>> = Exclude<
  DeepNonNullable<T>[K],
  // eslint-disable-next-line relay/no-future-added-value
  '%future added value'
>;

type AuthorsUpdatedStudyEventAction = PickEnum<
  AuthorsUpdatedStudyEvent,
  'authorAction'
>;

type TagsUpdatedStudyEventAction = PickEnum<
  TagsUpdatedStudyEvent,
  'tagAction'
>;

const styles = stylesheet`
  .patient-name {
    display: block;
    margin-bottom: 0.5rem;
  }
`;

const authorUpdatedMessages = defineMessages<AuthorsUpdatedStudyEventAction>({
  ADDED: {
    id: 'study.history.authorAdded',
    defaultMessage: 'Study author added',
  },
  REMOVED: {
    id: 'study.history.authorRemoved',
    defaultMessage: 'Study author removed',
  },
  PRIMARY_SET: {
    id: 'study.history.authorPrimarySet',
    defaultMessage: 'Primary study author set',
  },
  SET_SCRIBE: {
    id: 'study.history.authorScribeSet',
    defaultMessage: 'Study scribe set',
  },
  UNSET_SCRIBE: {
    id: 'study.history.authorScribeUnset',
    defaultMessage: 'Study scribe removed',
  },
});

const tagUpdatedMessages = defineMessages<TagsUpdatedStudyEventAction>({
  ADDED: {
    id: 'study.history.tagAdded',
    defaultMessage: 'Study tag added',
  },
  REMOVED: {
    id: 'study.history.tagRemoved',
    defaultMessage: 'Study tag removed',
  },
});

const eventCreatedAtMessages = defineMessages<
  // eslint-disable-next-line relay/no-future-added-value
  Exclude<StudyVersionReason, '%future added value'> | '@@modified'
>({
  ADDED_QA_ENTRY: {
    id: 'study.history.addedQaEntry',
    defaultMessage: 'Added QA Card {value}',
  },
  ADDED_WORKSHEET: {
    id: 'study.history.addedWorksheet',
    defaultMessage: 'Added worksheet {value}',
  },
  CREATED: {
    id: 'study.history.createdAt',
    defaultMessage: 'Created {value}',
  },
  DELETED: {
    id: 'study.history.deletedAt',
    defaultMessage: 'Deleted {value}',
  },
  FINALIZED_DRAFT: {
    id: 'study.history.finalizedAt',
    defaultMessage: 'Finalized {value}',
  },
  INFO_UPDATED: {
    id: 'study.history.editedAt',
    defaultMessage: 'Edited {value}',
  },
  MARKED_DRAFT: {
    id: 'study.history.markedDraft',
    defaultMessage: 'Marked as draft {value}',
  },
  MOVED: {
    id: 'study.history.movedAt',
    defaultMessage: 'Moved {value}',
  },
  REMOVED_QA_ENTRY: {
    id: 'study.history.removedQaEntry',
    defaultMessage: 'Removed QA card {value}',
  },
  REMOVED_WORKSHEET: {
    id: 'study.history.removedWorksheet',
    defaultMessage: 'Removed worksheet {value}',
  },
  UNDELETED: {
    id: 'study.history.undeletedAt',
    defaultMessage: 'Restored {value}',
  },
  UPDATED_QA_ENTRY: {
    id: 'study.history.updatedQaEntry',
    defaultMessage: 'Updated QA card {value}',
  },
  UPDATED_WORKSHEET: {
    id: 'study.history.updatedWorksheet',
    defaultMessage: 'Updated worksheet {value}',
  },
  '@@modified': {
    id: 'study.history.modifiedAt',
    defaultMessage: 'Modified {value}',
  },
});

const otherMessages = defineMessages({
  MODIFIED_BY: {
    id: 'study.history.modifiedBy',
    defaultMessage: 'Modified by {name}',
  },
  QA_SUBMITTED: {
    id: 'study.history.qaSubmitted',
    defaultMessage: 'Submitted QA review {value}',
  },
});

const EventInfo = styled(Layout).attrs({
  justify: 'space-between',
})`
  composes: text-body from global;
  margin-bottom: 2rem; /* Collapses against ListItem and Dl margins. */
`;

const ListItem = styled('li')`
  &:not(:first-child) {
    padding-top: 2rem;
    border-top: 1px solid theme('borderColor.divider');
    margin-top: 2rem;
  }
`;

type Archive = NonNullable<VersionCreatedStudyEvent['version']>['archive'];
type StudyEventInterface = NonNullable<
  NonNullable<NonNullable<Study['eventConnection']>['edges']>[0]
>['node'];

// TODO: Make generic guards?
type StudyEvent =
  | (StudyEventInterface &
      AuthorsUpdatedStudyEvent & { type: 'AuthorsUpdatedStudyEvent' })
  | (StudyEventInterface &
      TagsUpdatedStudyEvent & { type: 'TagsUpdatedStudyEvent' })
  | (StudyEventInterface &
      ImageAddedStudyEvent & { type: 'ImageAddedStudyEvent' })
  | (StudyEventInterface &
      ImagesDeletedStudyEvent & { type: 'ImagesDeletedStudyEvent' })
  | (StudyEventInterface &
      VersionCreatedStudyEvent & { type: 'VersionCreatedStudyEvent' })
  | (StudyEventInterface &
      FinalizationRequestedStudyEvent & {
        type: 'FinalizationRequestedStudyEvent';
      })
  | (StudyEventInterface &
      QaSubmittedStudyEvent & { type: 'QaSubmittedStudyEvent' })
  | (StudyEventInterface & MovedStudyEvent & { type: 'MovedStudyEvent' });

function isEventType<TType extends StudyEvent['type']>(
  event: StudyEventInterface,
  type: TType,
): event is StudyEvent & { type: TType } {
  return event!.type === type;
}

function isImageAddedStudyEvent(
  event: StudyEventInterface,
): event is StudyEventInterface & ImageAddedStudyEvent {
  return event!.type === 'ImageAddedStudyEvent';
}

function isVersionCreatedStudyEvent(
  event: StudyEventInterface,
): event is StudyEventInterface & VersionCreatedStudyEvent {
  return event!.type === 'VersionCreatedStudyEvent';
}

function isMovedStudyEvent(
  event: StudyEventInterface,
): event is StudyEventInterface & MovedStudyEvent {
  return event!.type === 'MovedStudyEvent';
}

/**
 * Filter out VERSION_CREATED events that are the result of a MOVE iff
 * there is a corresponding modern MOVED event.
 */
function filterMovedEvents(
  events: StudyEventInterface[],
): StudyEventInterface[] {
  const modernVersions = new Set();
  events.forEach((event) => {
    if (isMovedStudyEvent(event)) {
      modernVersions.add(event.version!.id);
    }
  });
  return events.filter(
    (event) =>
      !(
        isVersionCreatedStudyEvent(event) &&
        event.version?.versionReason === 'MOVED' &&
        modernVersions.has(event.version!.id)
      ),
  );
}

function renderArchive(archive: Archive) {
  if (!archive) {
    return (
      <Text color="subtitle">
        <FormattedMessage
          id="study.history.archive.unknown"
          defaultMessage="Unknown archive"
        />
      </Text>
    );
  }

  const { label } = archive;
  return archive.deletedAt ? <s>{label}</s> : label;
}

/*
  FIXME: This pulls the archive off of the previous chronological study version.
  We should just attach this archive to the event directly.
*/
function renderSourceArchive(events: StudyEventInterface[], index: number) {
  for (let i = index + 1; i < events.length; i++) {
    if (isVersionCreatedStudyEvent(events[i])) {
      return renderArchive(events[i]!.version!.archive);
    }
  }
  return renderArchive(null);
}

function renderLegacyEvent(
  events: StudyEventInterface[],
  event: StudyEventInterface & VersionCreatedStudyEvent,
  index: number,
) {
  const version = event.version!;

  return (
    <ListItem key={event.id}>
      <EventInfo>
        <FormattedMessage
          tagName="span"
          {...(eventCreatedAtMessages[version.versionReason!] ||
            eventCreatedAtMessages['@@modified'])}
          values={{
            value: <FormattedDateTime value={version.versionCommittedAt!} />,
          }}
        />
        {version.versionCommittedBy && (
          <FormattedMessage
            tagName="span"
            {...otherMessages.MODIFIED_BY}
            values={{ name: version.versionCommittedBy?.name }}
          />
        )}
      </EventInfo>
      {(version.versionReason === 'CREATED' ||
        version.versionReason === 'INFO_UPDATED') && (
        <Dl>
          <Dl.Term>
            <FormattedMessage {...studyMessages.patient} />
          </Dl.Term>
          <Dl.Detail>
            {version.practiceType === 'HUMAN' ? (
              <>
                <PatientName
                  patient={version.patient}
                  className={styles.patientName}
                />
                <PatientInfo
                  patient={version.patient}
                  accessionNumber={version.accessionNumber}
                />
              </>
            ) : (
              <>
                <VetPatientName
                  vetPatient={version.vetPatient!}
                  className={styles.patientName}
                />
                <VetPatientInfo
                  vetPatient={version.vetPatient!}
                  verbose
                  accessionNumber={version.accessionNumber}
                />
              </>
            )}
          </Dl.Detail>
          {version.studyDescription && (
            <>
              <Dl.Term>
                <FormattedMessage {...studyMessages.studyDescription} />
              </Dl.Term>
              <Dl.Detail>{version.studyDescription}</Dl.Detail>
            </>
          )}
          <Dl.Term>
            <FormattedMessage {...studyMessages.notes} />
          </Dl.Term>
          <Dl.Detail>
            {version.notes || (
              <Text color="subtitle">
                <FormattedMessage {...studyMessages.notesEmpty} />
              </Text>
            )}
          </Dl.Detail>
        </Dl>
      )}
      {version.versionReason === 'MOVED' && (
        <Dl>
          <Dl.Term>
            <FormattedMessage
              id="study.history.destinationArchive"
              defaultMessage="Destination Archive"
            />
          </Dl.Term>
          <Dl.Detail>{renderArchive(version.archive)}</Dl.Detail>
          <Dl.Term>
            <FormattedMessage
              id="study.history.sourceArchive"
              defaultMessage="Source Archive"
            />
          </Dl.Term>
          <Dl.Detail>{renderSourceArchive(events, index)}</Dl.Detail>
        </Dl>
      )}
    </ListItem>
  );
}

function renderEvent(event: StudyEventInterface) {
  if (!event || (isImageAddedStudyEvent(event) && !event.image)) {
    // If there's no image, this means the image is still being processed.
    // In this case, the user can't possibly know that there is a new image
    // so we just hide the event.
    return null;
  }

  if (isMovedStudyEvent(event)) {
    let sourceSuffix = '';
    let destinationSuffix = '';
    if (
      event.sourceOrganization?.name !== event.destinationOrganization?.name
    ) {
      sourceSuffix = ` (${event.sourceOrganization?.name})`;
      destinationSuffix = ` (${event.destinationOrganization?.name})`;
    }
    return (
      <ListItem key={event.id}>
        <EventInfo>
          <FormattedMessage
            tagName="span"
            {...eventCreatedAtMessages.MOVED}
            values={{
              value: <FormattedDateTime value={event.createdAt!} />,
            }}
          />
          <FormattedMessage
            tagName="span"
            {...otherMessages.MODIFIED_BY}
            values={{ name: event.createdBy?.name }}
          />
        </EventInfo>
        <Dl>
          <Dl.Term>
            <FormattedMessage
              id="study.history.destinationArchive"
              defaultMessage="Destination Archive"
            />
          </Dl.Term>
          <Dl.Detail>
            {renderArchive(event.destinationArchive)}
            {destinationSuffix}
          </Dl.Detail>
          <Dl.Term>
            <FormattedMessage
              id="study.history.sourceArchive"
              defaultMessage="Source Archive"
            />
          </Dl.Term>
          <Dl.Detail>
            {renderArchive(event.sourceArchive)}
            {sourceSuffix}
          </Dl.Detail>
        </Dl>
      </ListItem>
    );
  }

  if (isEventType(event, 'QaSubmittedStudyEvent') && event.createdBy) {
    return (
      <ListItem key={event.id}>
        <EventInfo>
          <FormattedMessage
            tagName="span"
            {...otherMessages.QA_SUBMITTED}
            values={{ value: <FormattedDateTime value={event.createdAt!} /> }}
          />
          <FormattedMessage
            tagName="span"
            {...otherMessages.MODIFIED_BY}
            values={{ name: event.createdBy?.name }}
          />
        </EventInfo>
      </ListItem>
    );
  }

  if (isEventType(event, 'FinalizationRequestedStudyEvent')) {
    return (
      <ListItem key={event.id}>
        <EventInfo>
          <Dl>
            <FormattedMessage
              id="study.history.finalizationRequested"
              defaultMessage="Attestation requested {value}"
              values={{
                value: <FormattedDateTime value={event.createdAt!} />,
              }}
            />
          </Dl>

          {event.createdBy && (
            <Dl>
              <FormattedMessage
                {...otherMessages.MODIFIED_BY}
                values={{ name: event.createdBy?.name }}
              />
            </Dl>
          )}
        </EventInfo>
        <Dl>
          <Dl.Term>
            <FormattedMessage
              id="study.history.finalizationRequestedFrom"
              defaultMessage="Requested from"
            />
          </Dl.Term>
          <Dl.Detail>
            {event.finalizationRequests
              ?.filter((r) => r?.recipient?.name)
              .map((r) => r?.recipient?.name)
              .join(', ')}
          </Dl.Detail>
        </Dl>
      </ListItem>
    );
  }

  return (
    <ListItem key={event.id}>
      <EventInfo>
        <FormattedMessage
          tagName="span"
          {...(eventCreatedAtMessages[event.type!] ||
            eventCreatedAtMessages['@@modified'])}
          values={{
            value: <FormattedDateTime value={event.createdAt!} />,
          }}
        />
        {/* TODO: There are no new events with `createdBy` set, so skipping this logic for now. */}
        {isEventType(event, 'AuthorsUpdatedStudyEvent') && event.createdBy && (
          <FormattedMessage
            {...otherMessages.MODIFIED_BY}
            values={{ name: event.createdBy?.name }}
          />
        )}
      </EventInfo>
      {isEventType(event, 'AuthorsUpdatedStudyEvent') && event.author && (
        <Dl>
          <Dl.Term>
            {authorUpdatedMessages[event.authorAction!] ? (
              <FormattedMessage
                {...authorUpdatedMessages[event.authorAction!]}
              />
            ) : (
              <FormattedMessage
                id="study.history.authorUpdated"
                defaultMessage="Study author updated"
              />
            )}
          </Dl.Term>
          <Dl.Detail>{event.author?.name}</Dl.Detail>
        </Dl>
      )}
      {isEventType(event, 'TagsUpdatedStudyEvent') && event.tag && (
        <Dl>
          <Dl.Term>
            {tagUpdatedMessages[event.tagAction!] ? (
              <FormattedMessage {...tagUpdatedMessages[event.tagAction!]} />
            ) : (
              <FormattedMessage
                id="study.history.tagUpdated"
                defaultMessage="Study tag updated"
              />
            )}
          </Dl.Term>
          <Dl.Detail>{event.tag?.name}</Dl.Detail>
        </Dl>
      )}
      {isEventType(event, 'ImageAddedStudyEvent') && event.image && (
        <Dl>
          {/* TODO: Thumbnail? Link to image? */}
          <Dl.Term>
            <FormattedMessage
              id="study.history.image.capturedAt"
              defaultMessage="Captured At"
            />
          </Dl.Term>
          <Dl.Detail>
            <FormattedDateTime value={event.image.capturedAt!} />
          </Dl.Detail>
        </Dl>
      )}
      {isEventType(event, 'ImagesDeletedStudyEvent') && event && (
        <Dl>
          {/* TODO: Thumbnail? Link to image? */}
          <Dl.Term>
            <FormattedMessage
              id="study.history.image.deletedAt"
              defaultMessage="Images removed at"
            />
          </Dl.Term>
          <Dl.Detail>
            <FormattedDateTime value={event.createdAt!} />
          </Dl.Detail>
        </Dl>
      )}
    </ListItem>
  );
}

interface Props {
  study: Study;
}

function StudyHistoryModalContent({ study }: Props) {
  const events = filterMovedEvents(getNodes(study.eventConnection));

  return (
    // TODO: this is probably not needed since list-style: none; but double check
    <ul className="list-none" data-bni-id="StudyHistoryModalContent">
      {events.map((event, index) =>
        isVersionCreatedStudyEvent(event)
          ? renderLegacyEvent(events, event, index)
          : renderEvent(event),
      )}
    </ul>
  );
}

// We're only creating these fragments for types.
const _ = [
  graphql`
    fragment StudyHistoryModalContent_authorsUpdatedStudyEvent on AuthorsUpdatedStudyEvent {
      authorAction: action
      author {
        name
      }
    }
  `,
  graphql`
    fragment StudyHistoryModalContent_qaSubmittedStudyEvent on QaSubmittedStudyEvent {
      id
      createdAt
      createdBy {
        name
      }
    }
  `,
  graphql`
    fragment StudyHistoryModalContent_tagsUpdatedStudyEvent on TagsUpdatedStudyEvent {
      tagAction: action
      tag {
        name
      }
    }
  `,
  graphql`
    fragment StudyHistoryModalContent_imagesDeletedStudyEvent on ImagesDeletedStudyEvent {
      id
      createdAt
    }
  `,
  graphql`
    fragment StudyHistoryModalContent_imageAddedStudyEvent on ImageAddedStudyEvent {
      image {
        capturedAt
      }
    }
  `,
  graphql`
    fragment StudyHistoryModalContent_versionCreatedStudyEvent on VersionCreatedStudyEvent {
      version {
        id
        archive {
          deletedAt
          label
        }
        practiceType
        accessionNumber
        patient {
          ...PatientName_patient
          ...PatientInfo_patient
        }
        vetPatient {
          ...VetPatientName_vetPatient
          ...VetPatientInfo_vetPatient
        }
        notes
        studyDescription
        versionCommittedAt
        versionReason
        versionCommittedBy {
          name
        }
      }
    }
  `,
  graphql`
    fragment StudyHistoryModalContent_finalizationRequestedStudyEvent on FinalizationRequestedStudyEvent {
      finalizationRequests {
        recipient {
          name
        }
      }
    }
  `,
  graphql`
    fragment StudyHistoryModalContent_movedStudyEvent on MovedStudyEvent {
      version {
        id
      }
      createdAt
      createdBy {
        name
      }
      sourceArchive {
        label
        deletedAt
      }
      destinationArchive {
        label
        deletedAt
      }
      sourceOrganization {
        name
      }
      destinationOrganization {
        name
      }
    }
  `,
];
export default createFragmentContainer(StudyHistoryModalContent, {
  study: graphql`
    fragment StudyHistoryModalContent_study on Study {
      eventConnection {
        edges {
          node {
            createdAt
            createdBy {
              name
            }
            type: __typename
            ... on Node {
              id
            }
            ... on QaSubmittedStudyEvent {
              ...StudyHistoryModalContent_qaSubmittedStudyEvent
                @relay(mask: false)
            }
            ... on AuthorsUpdatedStudyEvent {
              ...StudyHistoryModalContent_authorsUpdatedStudyEvent
                @relay(mask: false)
            }
            ... on TagsUpdatedStudyEvent {
              ...StudyHistoryModalContent_tagsUpdatedStudyEvent
                @relay(mask: false)
            }
            ... on ImagesDeletedStudyEvent {
              ...StudyHistoryModalContent_imagesDeletedStudyEvent
                @relay(mask: false)
            }
            ... on ImageAddedStudyEvent {
              ...StudyHistoryModalContent_imageAddedStudyEvent
                @relay(mask: false)
            }
            ... on VersionCreatedStudyEvent {
              ...StudyHistoryModalContent_versionCreatedStudyEvent
                @relay(mask: false)
            }
            ... on FinalizationRequestedStudyEvent {
              ...StudyHistoryModalContent_finalizationRequestedStudyEvent
                @relay(mask: false)
            }
            ... on MovedStudyEvent {
              ...StudyHistoryModalContent_movedStudyEvent @relay(mask: false)
            }
          }
        }
      }
    }
  `,
});
