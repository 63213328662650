/**
 * @generated SignedSource<<d395631d8dcfc303ae613cac2236c299>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type routeConfig_DomainQuery$variables = {};
export type routeConfig_DomainQuery$data = {
  readonly tenant: {
    readonly viewerPermissions: {
      readonly " $fragmentSpreads": FragmentRefs<"PermissionsProvider_viewerPermissions">;
    } | null;
  } | null;
  readonly viewer: {
    readonly " $fragmentSpreads": FragmentRefs<"PermissionsProvider_viewer">;
  } | null;
};
export type routeConfig_DomainQuery = {
  response: routeConfig_DomainQuery$data;
  variables: routeConfig_DomainQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "routeConfig_DomainQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ViewerPermissions",
            "kind": "LinkedField",
            "name": "viewerPermissions",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "PermissionsProvider_viewerPermissions"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "PermissionsProvider_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "routeConfig_DomainQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ViewerPermissions",
            "kind": "LinkedField",
            "name": "viewerPermissions",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "accessAllOrganizations",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "archiveManagement",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "bulkDataExport",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "connectivityManagement",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "credentialManagement",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dataDeletion",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dataExport",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "educationManagement",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "examTypeManagement",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "fleetManagement",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "iqCareRestrictedExperience",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "loginAndSecurityManagement",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "organizationManagement",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "qa",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "qaManagement",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "signing",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "studyDocumentation",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "studyDocumentationManagement",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "studyReversion",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "userManagement",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "userPermissions",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserProfile",
            "kind": "LinkedField",
            "name": "profile",
            "plural": false,
            "selections": [
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "251b08b6a25c2e1dfbac1d1f6a1819d0",
    "id": null,
    "metadata": {},
    "name": "routeConfig_DomainQuery",
    "operationKind": "query",
    "text": "query routeConfig_DomainQuery {\n  tenant {\n    __typename\n    viewerPermissions {\n      ...PermissionsProvider_viewerPermissions\n    }\n    id\n  }\n  viewer {\n    ...PermissionsProvider_viewer\n    id\n  }\n}\n\nfragment PermissionsProvider_viewer on Viewer {\n  profile {\n    id\n  }\n}\n\nfragment PermissionsProvider_viewerPermissions on ViewerPermissions {\n  ...checkPermission_viewerPermissions\n}\n\nfragment checkPermission_viewerPermissions on ViewerPermissions {\n  accessAllOrganizations\n  archiveManagement\n  bulkDataExport\n  connectivityManagement\n  credentialManagement\n  dataDeletion\n  dataExport\n  educationManagement\n  examTypeManagement\n  fleetManagement\n  iqCareRestrictedExperience\n  loginAndSecurityManagement\n  organizationManagement\n  qa\n  qaManagement\n  signing\n  studyDocumentation\n  studyDocumentationManagement\n  studyReversion\n  userManagement\n  userPermissions\n}\n"
  }
};
})();

(node as any).hash = "61c7c72ea7c6f06bf6d973c2e6ee2354";

export default node;
