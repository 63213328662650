import useId from '@bfly/ui2/useId';
import { stylesheet } from 'astroturf';
import clsx from 'clsx';
import { useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import { useVariation } from 'components/LaunchDarklyContext';
import { FormHandle } from 'components/RelayForm';
import { studyReviewStatus } from 'utils/StudyPermissions';

import { useSections } from '../utils/examPageSidebar';
import ExamPageSidebarFooter from './ExamPageSidebarFooter';
import ExamPageSidebarHeading from './ExamPageSidebarHeading';
import ExamPageSidebarNotes from './ExamPageSidebarNotes';
import ExamPageSidebarQa from './ExamPageSidebarQa';
import ExamPageSidebarWorksheets from './ExamPageSidebarWorksheets';
import ExamTags from './ExamTags';
import SelectExamTypeControl from './SelectExamTypeControl';
import { ExamPageSidebar_study$data as Study } from './__generated__/ExamPageSidebar_study.graphql';
import { ExamPageSidebar_viewer$data as Viewer } from './__generated__/ExamPageSidebar_viewer.graphql';

const styles = stylesheet`
  .main-column {
    @apply flex-1 overflow-x-hidden;
  }
  .full-height {
    @apply md:h-full md:overflow-y-hidden;
  }
`;

interface Props {
  study: Study;
  viewer: Viewer;
}

function ExamPageSidebar({ study, viewer }: Props) {
  const tagsId = useId();
  const canUseExamTypes = useVariation('exam-types');

  const [qaReadOnly, setQaReadOnly] = useState(
    studyReviewStatus(study) === 'REVIEWED',
  );
  const qaFormRef = useRef<null | FormHandle>(null);

  const { showHeaderActions, showQa, showWorksheets, showEmptyWorksheet } =
    useSections(study);

  /*  
    SIDEBAR LAYOUT

    sidebar             ExamPageSidebar

      ∟ main              ExamPageSidebarMain
        ∟ column-a          ExamPageSidebarColumnA
          ∟ worksheets  
          ∟ other fields
          ∟ notes

        ∟ column-b          
          ∟ qa

      ∟ footer              ExamPageSidebarFooter
  */
  return (
    <div
      data-bni-id="ExamPageSidebar"
      className={clsx(
        styles.fullHeight,
        'border-l border-grey-80', // border
        'flex flex-col w-full bg-grey-90',
      )}
    >
      <div
        data-bni-id="ExamPageSidebarMain"
        className={clsx(styles.fullHeight, 'flex flex-row w-full')}
      >
        <div
          data-bni-id="ExamPageSidebarColumnA"
          className={clsx(
            styles.fullHeight,
            styles.mainColumn,
            'flex flex-col',
          )}
        >
          {(showWorksheets || showEmptyWorksheet) && (
            <div className="flex-1 overflow-hidden flex flex-col">
              <ExamPageSidebarWorksheets study={study} />
            </div>
          )}

          {!showHeaderActions && (
            <>
              <div className="py-4 px-5 space-y-4 flex-0 border-t border-grey-80">
                {canUseExamTypes && <SelectExamTypeControl study={study} />}
              </div>
              <div className="py-4 px-5 space-y-4 flex-0 border-t border-grey-80">
                <ExamPageSidebarHeading>
                  <FormattedMessage
                    id="examPageSidebar.worksheet.tags"
                    defaultMessage="Tags"
                  />
                </ExamPageSidebarHeading>
                <ExamTags study={study} id={tagsId} />
              </div>
            </>
          )}
          <div className="flex-0">
            <ExamPageSidebarNotes study={study} />
          </div>
        </div>

        {showQa && (
          <ExamPageSidebarQa
            formRef={(formRef) => {
              qaFormRef.current = formRef;
            }}
            readOnly={qaReadOnly}
            onEdit={(isEditing) => setQaReadOnly(!isEditing)}
            study={study}
            className={clsx(
              styles.fullHeight,
              styles.mainColumn,
              'border-l border-divider flex flex-col items-stretch justify-items-stretch md:min-h-full overflow-x-hidden',
            )}
          />
        )}
      </div>
      <ExamPageSidebarFooter
        onQaSubmit={() => qaFormRef.current!.submit()}
        qaReadOnly={qaReadOnly}
        className="flex-0 z-10 w-full px-5 h-16  flex-none bg-body flex flex-row-reverse items-center border-t border-grey-80"
        data-bni-id="ExamPageSidebarFooter"
        study={study}
        viewer={viewer}
      />
    </div>
  );
}

export default createFragmentContainer(ExamPageSidebar, {
  study: graphql`
    fragment ExamPageSidebar_study on Study
    @argumentDefinitions(
      isPACEExam: { type: "Boolean", defaultValue: false }
    ) {
      id
      finalizedAt
      organization {
        id
      }
      finalizedAt
      viewerCanQa
      ...ExamPageSidebarFooter_study
      ...ExamPageSidebarQa_study
      ...SelectExamTypeControl_study
      ...ExamTags_study
      ...ExamPageSidebarWorksheets_study @arguments(isPACEExam: $isPACEExam)
      ...examPageSidebar_useSections_study
      ...ExamPageSidebarNotes_study
      ...StudyPermissions_studyReviewStatus
    }
  `,
  viewer: graphql`
    fragment ExamPageSidebar_viewer on Viewer {
      ...ExamPageSidebarFooter_viewer
    }
  `,
});
