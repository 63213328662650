import Layout from '@4c/layout';
import SearchIcon from '@bfly/icons/Search';
import Banner from '@bfly/ui2/Banner';
import BlankSlate from '@bfly/ui2/BlankSlate';
import Button from '@bfly/ui2/Button';
import Header from '@bfly/ui2/Header';
import LoadingIndicator from '@bfly/ui2/LoadingIndicator';
import MainContent from '@bfly/ui2/MainContent';
import Page, { usePageLayout } from '@bfly/ui2/Page';
import { RelayPagination } from '@bfly/ui2/RelayInfiniteList';
import Subscription from '@bfly/ui2/Subscription';
import Text from '@bfly/ui2/Text';
import useSubscription from '@bfly/ui2/useSubscription';
import React, {
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import ErrorBoundary from 'components/ErrorBoundary';
import { useExportSearchContext } from 'components/ExportSearchProvider';
import { useVariation } from 'components/LaunchDarklyContext';
import NProgress from 'components/NProgress';
import OutageMessage from 'components/OutageMessage';
import { usePermissions } from 'components/PermissionsProvider';
import StudyDateRangeFilterDropdown from 'components/StudyDateRangeFilterDropdown';
import StudyListViewToggleButton from 'components/StudyListViewToggleButton';
import StudySortFilterToggle from 'components/StudySortFilterToggle';
import { ConnectionNodeFromKey } from 'hooks/usePagedConnection';
import usePreferredStudyListView, {
  STUDY_GRID_VIEW_PAGE_SIZE,
  STUDY_LIST_VIEW_PAGE_SIZE,
} from 'hooks/usePreferredStudyListView';
import useStudyListLazyImages from 'hooks/useStudyListLazyImages';
import useStudyListLazySync from 'hooks/useStudyListLazySync';
import {
  StudyListNavKind,
  StudyListNavProvider,
} from 'utils/StudyListNavUtils';
import * as StudySelection from 'utils/StudySelection';

import { StudyDataColumnUserPreferenceKey } from '../hooks/useStudyDataColumnsUserPreference';
import EmptyArchiveScreen from './EmptyArchiveScreen';
import StudyBatchActions from './StudyBatchActions';
import StudyDataGrid from './StudyDataGrid';
import StudyFilterDropdown from './StudyFilterDropdown';
import StudyList from './StudyList';
import UndeleteArchiveControl from './UndeleteArchiveControl';
import { StudyListContentOrganizationStudyEhrOruStatusUpdatedSubscription as OrganizationStudyEhrOruStatusUpdatedSubscription } from './__generated__/StudyListContentOrganizationStudyEhrOruStatusUpdatedSubscription.graphql';
import { StudyListContentOrganizationStudyPacsPushStatusUpdatedSubscription as OrganizationStudyPacsPushStatusUpdatedSubscription } from './__generated__/StudyListContentOrganizationStudyPacsPushStatusUpdatedSubscription.graphql';
import { StudyListContent_OrganizationStudyFinalizedSubscription as OrganizationStudyFinalizedSubscription } from './__generated__/StudyListContent_OrganizationStudyFinalizedSubscription.graphql';
import { StudyListContent_archive$data as Archive } from './__generated__/StudyListContent_archive.graphql';
import { StudyListContent_domain$data as Domain } from './__generated__/StudyListContent_domain.graphql';
import {
  StudyListContent_organization$data as Organization,
  StudyListContent_organization$key as OrganizationKey,
} from './__generated__/StudyListContent_organization.graphql';
import { StudyListContent_studies$data as Studies } from './__generated__/StudyListContent_studies.graphql';
import { StudyListContent_viewer$data as Viewer } from './__generated__/StudyListContent_viewer.graphql';

export interface StudyListContentProps {
  title: React.ReactNode;
  viewer: Viewer;
  studies: Studies | null;
  archive: Archive | null;
  domain: Domain | null;
  organization: Organization | null;
  loading?: boolean;
  allowDelete?: boolean;
  allowMove?: boolean;
  allowAssignExamTypes?: boolean;
  allowList?: boolean;
  allowStatusFiltering?: boolean;
  lazyLoadImages?: boolean;
  headerActions?: React.ReactNode;
  archiveActions?: React.ReactNode;
  relayPagination?: RelayPagination;
  shouldClearStatus?: boolean;
  children?: React.ReactNode;
  navKind: StudyListNavKind;
  showOrganization?: boolean;
  columnsPreferenceKey: StudyDataColumnUserPreferenceKey;
  isAllOrgs?: boolean;

  'data-bni-id'?: string;
  showLocalFilters?: boolean;
}

export const StudyListContext = React.createContext<{
  viewerCanMoveSelected: boolean;
  viewerCanDeleteSelected: boolean;
}>(null as any);

interface ExportButtonProps {
  columnPreferences: RefObject<any>;
}

const ExportButton = ({ columnPreferences }: ExportButtonProps) => {
  const { exportFile, exportingInProgress } = useExportSearchContext();

  const handleExportAll = async () => {
    const { columnOrder } = columnPreferences.current;
    await exportFile(columnOrder);
  };

  return (
    <Button
      variant="secondary"
      data-bni-id="ExportStudiesButton"
      onClick={handleExportAll}
      disabled={exportingInProgress}
      className="min-w-[10rem]"
      busy={exportingInProgress}
    >
      <FormattedMessage id="studies.exportAll" defaultMessage="Export all" />
    </Button>
  );
};

/**
 * Returns a Map of {studyId => studyCursor}
 */
export function getIdsToCursors(studies: Studies | null): Map<string, string> {
  const idsCursorTuple: [string, string][] =
    studies?.map((e) => [e!.node!.id, e!.cursor]) || [];
  const idsToCursors = new Map<string, string>(idsCursorTuple);

  return idsToCursors;
}

function StudyListContent({
  title,
  studies,
  archive,
  domain,
  organization: propsOrganization,
  viewer,
  allowDelete = false,
  allowMove = false,
  allowAssignExamTypes = false,
  allowList = true,
  allowStatusFiltering,
  lazyLoadImages = false,
  headerActions,
  loading,
  archiveActions,
  relayPagination,
  showLocalFilters,
  shouldClearStatus = true,
  showOrganization,
  'data-bni-id': dataBniId,
  navKind,
  columnsPreferenceKey,
  isAllOrgs,
  children,
}: StudyListContentProps) {
  const [preferredView, setPreferredView] =
    usePreferredStudyListView(shouldClearStatus);
  const idsToCursors = useMemo(() => getIdsToCursors(studies), [studies]);
  const organization =
    propsOrganization || domain!.organizationConnection!.edges![0]!.node!;
  const canUseDrafts = useVariation('draft-studies');
  const canExportStudies = useVariation('global-search-csv-export');
  const [contextValue, setSelectedItemsByKey] =
    StudySelection.useSelectionContextValue({
      allowDelete,
      allowMove,
      allowAssignExamTypes,
    });
  const { hasBasicPermission } = usePermissions();
  const columnPreferencesRef = useRef<{ columnOrder: string[] | null }>();
  const { setIsDomain } = useExportSearchContext();
  const {
    selectedStudyItems,
    viewerCanDeleteSelected,
    viewerCanMoveSelected,
  } = contextValue;
  const studyNodes = useMemo(
    () => studies?.map((e) => e.node),
    [studies],
  ) as ConnectionNodeFromKey<OrganizationKey, 'studySearchConnection'>;

  const handleDeselectAll = useCallback(() => {
    setSelectedItemsByKey(new Map());
  }, [setSelectedItemsByKey]);

  const numSelectedStudies = selectedStudyItems.length;
  const isEmpty = !loading && !studies?.length;

  const actualLayout = allowList ? preferredView : 'grid';

  usePageLayout(actualLayout === 'grid' ? 'fill' : 'overflow');

  const emptyMessage =
    archive && organization ? (
      <EmptyArchiveScreen organization={organization} />
    ) : (
      <BlankSlate>
        <BlankSlate.Img as={SearchIcon} height="36" width="36" />
        <BlankSlate.Title>
          <FormattedMessage
            id="studyListContent.noResults"
            defaultMessage="No Studies Found"
          />
        </BlankSlate.Title>
      </BlankSlate>
    );

  const showBatchActions = actualLayout !== 'grid';

  // this is a dumb heuristic but we will remove it as soon as search or listview lands
  const showGridActionsInTopHeader =
    actualLayout === 'grid' && (!archive || !!archiveActions) && !children;

  const studiesWithImages = useStudyListLazyImages(
    studies?.map((s) => s.node!.id),
    !lazyLoadImages,
  );

  const studiesWithSync = useStudyListLazySync(
    studies?.map((s) => s.node!.id),
  );

  const handleColumnChange = useCallback(
    (colPreferences: { columnOrder: string[] }) => {
      columnPreferencesRef.current = colPreferences;
    },
    [],
  );

  useEffect(() => {
    setIsDomain(!!viewer.domain);
  }, [setIsDomain, viewer.domain]);

  const showExportButton =
    navKind === 'GLOBAL_SEARCH' &&
    canExportStudies &&
    hasBasicPermission('bulkDataExport') &&
    !!studies?.length;

  // XXX: this is wrong, we need a domain scoped one as well
  useSubscription<OrganizationStudyFinalizedSubscription>(
    graphql`
      subscription StudyListContent_OrganizationStudyFinalizedSubscription(
        $input: OrganizationStudyFinalizedInput!
      ) {
        organizationStudyFinalized(input: $input) {
          studyEdge {
            node {
              finalizedAt
              finalizedBy {
                name
              }
            }
          }
        }
      }
    `,
    {
      input: {
        organizationId: organization.id,
      },
    },
  );

  const stickyHeader = actualLayout === 'list';

  const canQa = hasBasicPermission('qa');

  return (
    <>
      <Subscription<OrganizationStudyEhrOruStatusUpdatedSubscription>
        subscription={graphql`
          subscription StudyListContentOrganizationStudyEhrOruStatusUpdatedSubscription(
            $input: OrganizationStudyEhrOruStatusUpdatedInput!
          ) {
            organizationStudyEhrOruStatusUpdated(input: $input) {
              studyEdge {
                node {
                  ...StudySyncStatus_study
                }
              }
            }
          }
        `}
        input={{ organizationId: organization.id }}
      />
      <Subscription<OrganizationStudyPacsPushStatusUpdatedSubscription>
        subscription={graphql`
          subscription StudyListContentOrganizationStudyPacsPushStatusUpdatedSubscription(
            $input: OrganizationStudyPacsPushStatusUpdatedInput!
          ) {
            organizationStudyPacsPushStatusUpdated(input: $input) {
              studyEdge {
                node {
                  ...StudySyncStatus_study
                }
              }
            }
          }
        `}
        input={{ organizationId: organization.id }}
      />
      <StudyListNavProvider kind={navKind}>
        <StudySelection.Provider value={contextValue}>
          <Page.Header sticky={stickyHeader} className="h-[7.4rem]">
            {title}
            {showExportButton && (
              <ExportButton columnPreferences={columnPreferencesRef} />
            )}
            <Header.Actions>{archiveActions}</Header.Actions>
            <Header.Actions className="ml-auto">
              {showBatchActions && numSelectedStudies ? (
                <StudyBatchActions
                  organization={organization!}
                  domain={domain}
                  selectedStudyItems={selectedStudyItems}
                  viewerCanDeleteSelected={viewerCanDeleteSelected}
                  viewerCanMoveSelected={viewerCanMoveSelected}
                  onDeselectAll={handleDeselectAll}
                />
              ) : (
                <>
                  {headerActions}
                  {showGridActionsInTopHeader && showLocalFilters && (
                    <StudyDateRangeFilterDropdown className="h-full mr-3" />
                  )}
                  {allowList && (
                    <StudyListViewToggleButton
                      view={preferredView}
                      onToggleView={setPreferredView}
                    />
                  )}
                </>
              )}
            </Header.Actions>
          </Page.Header>
          {actualLayout === 'grid' && (
            <NProgress loading={!!loading} className="-mt-0.5" />
          )}
          {archive?.deletedAt && (
            <Banner variant="danger" className="justify-center">
              <Text className="mr-3">
                <FormattedMessage
                  id="archive.deleted"
                  defaultMessage="This archive was deleted"
                />
              </Text>
              <UndeleteArchiveControl as={Banner.Button} archive={archive}>
                <FormattedMessage
                  id="archive.undelete"
                  defaultMessage="Restore"
                />
              </UndeleteArchiveControl>
            </Banner>
          )}

          {actualLayout === 'grid' && (
            <>
              {!showGridActionsInTopHeader && showLocalFilters && (
                <Layout
                  align="center"
                  justify="flex-end"
                  className="flex-shrink-0 w-full py-3 px-app-panel-sm md:px-app-panel max-w-screen-lg mx-auto"
                >
                  {children}
                  <Layout.Spacer />

                  <StudyDateRangeFilterDropdown />
                </Layout>
              )}
              <Layout
                direction="column"
                className="flex-grow"
                data-bni-id={dataBniId}
              >
                <ErrorBoundary
                  fallbackComponent={<OutageMessage className="m-4" />}
                >
                  <StudyDataGrid
                    columnsPreferenceKey={columnsPreferenceKey}
                    loading={loading}
                    viewer={viewer}
                    showArchive={!archive}
                    isSearchPage={navKind === 'GLOBAL_SEARCH'}
                    organization={organization!}
                    domain={domain}
                    relayPagination={relayPagination}
                    pageSize={STUDY_GRID_VIEW_PAGE_SIZE}
                    allowDelete={allowDelete}
                    allowMove={allowMove}
                    allowAssignExamTypes={allowAssignExamTypes}
                    studiesWithImages={
                      lazyLoadImages ? studiesWithImages : studyNodes!
                    }
                    studiesWithSync={studiesWithSync}
                    studies={studyNodes!}
                    canQa={canQa}
                    showOrganization={showOrganization}
                    onColumnChange={handleColumnChange}
                    idsToCursors={idsToCursors}
                    isAllOrgs={isAllOrgs}
                  />
                </ErrorBoundary>
              </Layout>
            </>
          )}
          {actualLayout === 'list' && (
            <MainContent size="lg" data-bni-id={dataBniId}>
              <Layout className="pb-4 w-full" align="center">
                {children}
                <Layout.Spacer />
                {showLocalFilters && (
                  <>
                    {allowStatusFiltering && canUseDrafts && (
                      <StudyFilterDropdown archive={archive} />
                    )}
                    <StudyDateRangeFilterDropdown />
                  </>
                )}
                <StudySortFilterToggle />
              </Layout>
              {/* eslint-disable-next-line no-nested-ternary */}
              {isEmpty ? (
                emptyMessage
              ) : loading ? (
                <LoadingIndicator />
              ) : (
                <StudyList
                  pageSize={STUDY_LIST_VIEW_PAGE_SIZE}
                  studies={studyNodes!}
                  relayPagination={relayPagination}
                  studiesWithImages={
                    lazyLoadImages ? studiesWithImages : studyNodes!
                  }
                  idsToCursors={idsToCursors}
                />
              )}
            </MainContent>
          )}
        </StudySelection.Provider>
      </StudyListNavProvider>
    </>
  );
}

export default createFragmentContainer(StudyListContent, {
  studies: graphql`
    fragment StudyListContent_studies on StudyEdge # We want to work on Edges, cause we need to utilize cursor value for pagination
    @relay(plural: true)
    @argumentDefinitions(
      listEnabled: { type: "Boolean", defaultValue: true }
      lazyLoadImages: { type: "Boolean", defaultValue: false }
      showArchive: { type: "Boolean", defaultValue: false }
    ) {
      cursor
      node {
        id
        ...StudyList_studies
          @include(if: $listEnabled)
          @arguments(showArchive: $showArchive)
        ...StudyList_studiesWithImages
          @include(if: $listEnabled)
          @skip(if: $lazyLoadImages)
        ...StudyDataGrid_studies
        ...StudyDataGrid_studiesWithImages @skip(if: $lazyLoadImages)
      }
    }
  `,
  viewer: graphql`
    fragment StudyListContent_viewer on Viewer {
      domain {
        __typename
      }
      ...StudyDataGrid_viewer
    }
  `,
  domain: graphql`
    fragment StudyListContent_domain on Domain {
      id
      organizationConnection {
        edges {
          node {
            ...StudyListContent_organization @relay(mask: false)
          }
        }
      }
      ...StudyDataGrid_domain
      ...StudyBatchActions_domain
    }
  `,
  organization: graphql`
    fragment StudyListContent_organization on Organization {
      id
      viewerCanQa
      ...StudyBatchActions_organization
      ...StudyDataGrid_organization
      ...EmptyArchiveScreen_organization
    }
  `,
  archive: graphql`
    fragment StudyListContent_archive on Archive {
      viewerCanQa
      deletedAt
      ...UndeleteArchiveControl_archive
      ...StudyFilterDropdown_archive
    }
  `,
});
