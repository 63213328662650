import Button from '@bfly/ui2/Button';
import FormCheck from '@bfly/ui2/FormCheck';
import ItemGrid from '@bfly/ui2/ItemGrid';
import Link from '@bfly/ui2/Link';
import clsx from 'clsx';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import PermissionTooltip from 'components/PermissionTooltip';
import { usePermissions } from 'components/PermissionsProvider';
import { useArchiveRoutes } from 'routes/archive';

import ArchivePrivacyDescription from './ArchivePrivacyDescription';
import { ArchivePrivacyControl_archive$data as Archive } from './__generated__/ArchivePrivacyControl_archive.graphql';

interface Props {
  archive: Archive;
  isPrivate: boolean;
  onSelect: (nextIsPrivate: boolean) => void;
  disabled: boolean;
  name: string;
}

function ArchivePrivacyControl({
  archive,
  isPrivate,
  onSelect,
  disabled,
  name,
}: Props) {
  const archiveRoutes = useArchiveRoutes();

  const active = isPrivate === archive.isPrivate;

  const { hasBasicPermission } = usePermissions();
  const hasPermissionToManagePrivacy = hasBasicPermission('archiveManagement');

  const handleChange = useCallback(() => {
    onSelect(isPrivate);
  }, [isPrivate, onSelect]);

  const shouldShowMemberButton =
    active && (archive.isPrivate || archive.hasRestrictedFinalization);

  return (
    <ItemGrid.Row>
      <ItemGrid.Cell>
        <FormCheck
          type="radio"
          name={name}
          data-bni-id={`ArchivePrivacyControl${
            isPrivate ? 'Private' : 'Public'
          }`}
          value={isPrivate ? 'private' : 'public'}
          onChange={handleChange}
          checked={active}
          disabled={disabled || !hasPermissionToManagePrivacy}
          className="items-center"
        >
          <ArchivePrivacyDescription
            organization={archive.organization!}
            isPrivate={isPrivate}
          />
        </FormCheck>
      </ItemGrid.Cell>

      <ItemGrid.ActionsCell>
        {shouldShowMemberButton && (
          <PermissionTooltip wrap hide={hasPermissionToManagePrivacy}>
            <Link
              as={Button}
              variant="text-primary"
              data-bni-id="editAccessDisabled"
              disabled={!hasPermissionToManagePrivacy}
              to={archiveRoutes.archiveMembers()}
              className={clsx(
                !hasPermissionToManagePrivacy &&
                  'pointer-events-none cursor-not-allowed',
              )}
            >
              <FormattedMessage
                id="archiveSettings.private.viewMembersButton"
                defaultMessage="Edit Access"
              />
            </Link>
          </PermissionTooltip>
        )}
      </ItemGrid.ActionsCell>
    </ItemGrid.Row>
  );
}

export default createFragmentContainer(ArchivePrivacyControl, {
  archive: graphql`
    fragment ArchivePrivacyControl_archive on Archive {
      organization {
        ...ArchivePrivacyDescription_organization
      }
      isPrivate
      hasRestrictedFinalization
    }
  `,
});
