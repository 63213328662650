/**
 * @generated SignedSource<<0828a5d6f7d1034f0ffb7c15bbe9da90>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type domainConnectivityRoutes_CheckDomainPermissionQuery$variables = {};
export type domainConnectivityRoutes_CheckDomainPermissionQuery$data = {
  readonly tenant: {
    readonly viewerPermissions: {
      readonly " $fragmentSpreads": FragmentRefs<"RouteAccessControl_viewerPermissions">;
    } | null;
  } | null;
};
export type domainConnectivityRoutes_CheckDomainPermissionQuery = {
  response: domainConnectivityRoutes_CheckDomainPermissionQuery$data;
  variables: domainConnectivityRoutes_CheckDomainPermissionQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "accessAllOrganizations",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "archiveManagement",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "bulkDataExport",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "connectivityManagement",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "credentialManagement",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "dataDeletion",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "dataExport",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "educationManagement",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "examTypeManagement",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "fleetManagement",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "iqCareRestrictedExperience",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "loginAndSecurityManagement",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "organizationManagement",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "qa",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "qaManagement",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "signing",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "studyDocumentation",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "studyDocumentationManagement",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "studyReversion",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "userManagement",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "userPermissions",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "domainConnectivityRoutes_CheckDomainPermissionQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ViewerPermissions",
            "kind": "LinkedField",
            "name": "viewerPermissions",
            "plural": false,
            "selections": [
              {
                "kind": "InlineDataFragmentSpread",
                "name": "RouteAccessControl_viewerPermissions",
                "selections": [
                  {
                    "kind": "InlineDataFragmentSpread",
                    "name": "checkPermission_viewerPermissions",
                    "selections": (v0/*: any*/),
                    "args": null,
                    "argumentDefinitions": []
                  }
                ],
                "args": null,
                "argumentDefinitions": []
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "domainConnectivityRoutes_CheckDomainPermissionQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ViewerPermissions",
            "kind": "LinkedField",
            "name": "viewerPermissions",
            "plural": false,
            "selections": (v0/*: any*/),
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "17f5a11f4a8c6dc47f52a0ed7e2daf74",
    "id": null,
    "metadata": {},
    "name": "domainConnectivityRoutes_CheckDomainPermissionQuery",
    "operationKind": "query",
    "text": "query domainConnectivityRoutes_CheckDomainPermissionQuery {\n  tenant {\n    __typename\n    viewerPermissions {\n      ...RouteAccessControl_viewerPermissions\n    }\n    id\n  }\n}\n\nfragment RouteAccessControl_viewerPermissions on ViewerPermissions {\n  ...checkPermission_viewerPermissions\n}\n\nfragment checkPermission_viewerPermissions on ViewerPermissions {\n  accessAllOrganizations\n  archiveManagement\n  bulkDataExport\n  connectivityManagement\n  credentialManagement\n  dataDeletion\n  dataExport\n  educationManagement\n  examTypeManagement\n  fleetManagement\n  iqCareRestrictedExperience\n  loginAndSecurityManagement\n  organizationManagement\n  qa\n  qaManagement\n  signing\n  studyDocumentation\n  studyDocumentationManagement\n  studyReversion\n  userManagement\n  userPermissions\n}\n"
  }
};
})();

(node as any).hash = "c161b996f938122b1727c367ff08f773";

export default node;
