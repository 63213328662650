import Layout from '@4c/layout';
import Navbar from '@bfly/ui2/Navbar';
import Page, { PageProps } from '@bfly/ui2/Page';
import React from 'react';
import { createFragmentContainer, graphql } from 'react-relay';

import useDeviceTooSmall from 'hooks/useIsTinyViewport';

import { isInApp } from '../../utils/browserInfo';
import AppHeaderUserDropdownButton from './AppHeaderUserDropdownButton';
import AppLeadingNav from './AppLeadingNav';
import AppProductNav from './AppProductNav';
import AppSearch from './AppSearch';
import ExperiencesProvider from './ExperiencesProvider';
import HelpDropdownButton from './HelpDropdownButton';
import { useVariationAllowMissingLdContext } from './LaunchDarklyContext';
import { MultiSelectProvider } from './MultiSelectContext';
import NotificationCenter from './NotificationCenter';
import SubscriptionExpiredBanner from './SubscriptionExpiredBanner';
import { BaseAppPage_archive$data as Archive } from './__generated__/BaseAppPage_archive.graphql';
import { BaseAppPage_organization$data as Organization } from './__generated__/BaseAppPage_organization.graphql';
import { BaseAppPage_searchNodes$data as SearchNodes } from './__generated__/BaseAppPage_searchNodes.graphql';
import { BaseAppPage_tenant$data as Tenant } from './__generated__/BaseAppPage_tenant.graphql';
import { BaseAppPage_viewer$data as Viewer } from './__generated__/BaseAppPage_viewer.graphql';

export type PageLayout = PageProps['layout'];

interface Props {
  tenant: Tenant | null;
  viewer: Viewer | null;
  organization: Organization | null;
  archive: Archive | null;
  center?: boolean;
  children?: React.ReactNode;
  sidePanel?: React.ReactElement | null | false;
  searchNodes: SearchNodes | null;
  pageLayout?: PageLayout;
  'data-bni-id'?: string;
}

function BaseAppPage({
  tenant,
  viewer,
  organization,
  archive,
  center,
  children,
  sidePanel,
  pageLayout,
  searchNodes,
  ...props
}: Props) {
  const inApp = isInApp();
  const isTooSmall = useDeviceTooSmall();

  const canUseGlobalSearch =
    useVariationAllowMissingLdContext('global-search');

  return (
    <Page layout={pageLayout}>
      <MultiSelectProvider>
        <ExperiencesProvider>
          <Navbar className="z-fixed">
            <AppLeadingNav
              viewer={viewer}
              organization={organization}
              searchNodes={searchNodes}
              tenant={tenant}
            />

            <Layout.Spacer />
            {!inApp && !isTooSmall && <AppProductNav />}
            <Navbar.Divider />
            {organization && !canUseGlobalSearch && (
              <>
                <AppSearch archive={archive} organization={organization} />
                <Navbar.Divider />
              </>
            )}
            <HelpDropdownButton />
            {organization && (
              <NotificationCenter organization={organization} />
            )}
            <AppHeaderUserDropdownButton
              viewer={viewer}
              organization={organization}
            />
          </Navbar>
          {organization && (
            <SubscriptionExpiredBanner organization={organization} />
          )}
          <Page.Container center={center}>
            {sidePanel}
            <Page.Main data-bni-id={props['data-bni-id']}>
              {children}
            </Page.Main>
          </Page.Container>
        </ExperiencesProvider>
      </MultiSelectProvider>
    </Page>
  );
}

export default createFragmentContainer(BaseAppPage, {
  viewer: graphql`
    fragment BaseAppPage_viewer on Viewer {
      ...AppLeadingNav_viewer
      ...AppHeaderUserDropdownButton_viewer
    }
  `,
  organization: graphql`
    fragment BaseAppPage_organization on Organization {
      subscription {
        hasCompletedTrial
      }
      name
      ...AppSearch_organization
      ...AppLeadingNav_organization
      ...AppHeaderUserDropdownButton_organization
      ...NotificationCenter_organization
      ...SubscriptionExpiredBanner_organization
    }
  `,
  tenant: graphql`
    fragment BaseAppPage_tenant on TenantInterface {
      ...AppLeadingNav_tenant
    }
  `,
  archive: graphql`
    fragment BaseAppPage_archive on Archive {
      ...AppSearch_archive
    }
  `,
  searchNodes: graphql`
    fragment BaseAppPage_searchNodes on Node @relay(plural: true) {
      ...AppLeadingNav_searchNodes
    }
  `,
});
