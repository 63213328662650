import Button from '@bfly/ui2/Button';
import { FormattedMessage } from 'react-intl';

import CredentialGroupSelectButton from 'components/CredentialGroupSelectButton';
import DateRangeSelectButton from 'components/DateRangeSelectButton';
import ExamTypeSelectButton from 'components/ExamTypeSelectButton';
import { useVariationAllowMissingLdContext } from 'components/LaunchDarklyContext';
import { ViewerState } from 'utils/viewerState';

import { SUPPORTED_EXAM_TYPES } from '../constants';
import { useScanLabContext } from '../context';
import EduStudiesAuthorSuggestionButton from './EduStudiesAuthorSuggestionButton';

export default function Filters({ viewer }: { viewer: ViewerState }) {
  const tenantId =
    viewer.domain?.id || viewer.memberships?.[0]?.organization?.id;
  const {
    filters,
    setFilters,
    lastListRoute: listRoute,
  } = useScanLabContext();

  const canUseCredentialing = useVariationAllowMissingLdContext(
    'credentialing-analytics-page',
  );

  if (!tenantId) return null;

  return (
    <div
      className="flex flex-row space-x-3 mr-4 ml-auto"
      data-bni-id="ScanLabStudiesFilters"
    >
      {listRoute === 'review' && (
        <>
          <EduStudiesAuthorSuggestionButton
            data-bni-id="EduStudiesAuthorSuggestionButton"
            tenantId={tenantId}
            value={filters.author || []}
            onChange={(author) => setFilters({ author })}
          />
          {canUseCredentialing && (
            <CredentialGroupSelectButton
              data-bni-id="CredentialGroupSelectButton"
              dataKey="id"
              value={filters?.groupId || []}
              onChange={(group) => {
                setFilters({ groupId: group?.map(({ id }) => id!) });
              }}
            />
          )}
        </>
      )}
      <DateRangeSelectButton
        data-bni-id="DateRangeSelectButton"
        value={filters?.dates}
        onChange={(dates) => {
          setFilters({ dates });
        }}
      />
      <ExamTypeSelectButton
        hideFilter
        tenantId={tenantId}
        placement="bottom-end"
        data-bni-id="ExamTypeSelectButton"
        value={filters?.examTypes || []}
        onChange={(value) => {
          setFilters({ examTypes: value?.map(({ handle }) => handle!) });
        }}
        dataKey="handle"
        hideNonSystemTypes
        filter={(examType) =>
          SUPPORTED_EXAM_TYPES.includes(examType.systemId as any)
        }
      />
      <Button
        variant="text-secondary"
        onClick={() => {
          if (listRoute === 'review') {
            setFilters({
              dates: null,
              examTypes: null,
              groupId: null,
              author: null,
            });
          } else {
            setFilters({
              dates: null,
              examTypes: null,
              groupId: null,
            });
          }
        }}
      >
        <FormattedMessage id="scanLab.filters.reset" defaultMessage="Reset" />
      </Button>
    </div>
  );
}
