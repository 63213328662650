import { graphql } from 'react-relay';

import Route, { chainPrerenders } from 'components/Route';
import {
  checkFlagsOr404,
  checkRoutePermission,
} from 'utils/RouteAccessControl';

export default (
  <Route
    path="dicom-field-templates"
    query={graphql`
      query dicomFieldTemplates_checkIsDomainAdmin_Query {
        tenant {
          ...RouteAccessControl_tenant
          viewerPermissions {
            ...RouteAccessControl_viewerPermissions
          }
        }
      }
    `}
    prerender={chainPrerenders(
      checkFlagsOr404('dicom-field-templates'),
      checkRoutePermission('connectivityManagement', 'BASIC', 'tenant'),
    )}
  >
    <Route
      getComponent={() =>
        import(
          /* webpackChunkName: "admin" */ './components/DicomFieldTemplatesPage'
        ).then((m) => m.default)
      }
      query={graphql`
        query dicomFieldTemplates_DicomFieldTemplatesPage_Query {
          viewer {
            ...DicomFieldTemplatesPage_viewer
          }
        }
      `}
    />
    <Route
      path="-/new"
      getComponent={() =>
        import(
          /* webpackChunkName: "admin" */ './components/CreateDicomFieldTemplatePage'
        ).then((m) => m.default)
      }
      query={graphql`
        query dicomFieldTemplates_CreateDicomFieldTemplatesPage_Query {
          viewer {
            ...CreateDicomFieldTemplatePage_viewer
          }
        }
      `}
    />
    <Route
      path=":dicomFieldTemplateHandle"
      getComponent={() =>
        import(
          /* webpackChunkName: "admin" */ './components/UpdateDicomFieldTemplatePage'
        ).then((m) => m.default)
      }
      query={graphql`
        query dicomFieldTemplates_UpdateDicomFieldTemplatesPage_Query(
          $dicomFieldTemplateHandle: String!
        ) {
          dicomFieldTemplate: domainDicomFieldTemplate(
            handle: $dicomFieldTemplateHandle
          ) {
            ...UpdateDicomFieldTemplatePage_dicomFieldTemplate
          }
        }
      `}
    />
  </Route>
);
