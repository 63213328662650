import captureException from '@bfly/utils/captureException';
import pick from 'lodash/pick';
import React from 'react';
import type { ErrorInfo } from 'react';

import { noticeError } from 'utils/newRelic';
import { ViewerStateManager } from 'utils/viewerState';

import ErrorPage from './ErrorPage';

interface Props {
  children: React.ReactNode;
}

class ApplicationErrorBoundary extends React.Component<
  Props,
  { hasError: boolean }
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // eslint-disable-next-line no-console
    console.error(error, errorInfo);

    noticeError(new Error(`Error Boundary: URL: ${window.location}`), {
      ...pick(ViewerStateManager.getInstance().state, [
        'id',
        'email',
        'domain.id',
      ]),
      originalError: `${error}`,
      errorInfo,
    });

    // common bfly error handling
    captureException(error, errorInfo as Record<string, any>);
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage />;
    }

    return this.props.children;
  }
}

export default ApplicationErrorBoundary;
