/* eslint-disable relay/no-future-added-value */

import uniqueId from 'lodash/uniqueId';
import { graphql, readInlineData } from 'relay-runtime';
import {
  InferType,
  TypeOf,
  array,
  bool,
  lazy,
  number,
  object,
  string,
} from 'yup';

import { templateVersionToSchema } from 'schemas/qaEntry';
import { studyReviewStatus } from 'utils/StudyPermissions';

import { standardQa_examTypeProficiency$key as ExamTypeProficiencyKey } from './__generated__/standardQa_examTypeProficiency.graphql';
import {
  standardQa_study$data as Study,
  standardQa_study$key as StudyKey,
  InterpretationAccuracy as _InterpretationAccuracy,
  StandardQaFeedback as _StandardQaFeedback,
} from './__generated__/standardQa_study.graphql';

export type InterpretationAccuracy = Exclude<
  _InterpretationAccuracy,
  '%future added value'
>;

export type StandardQaFeedback = Exclude<
  _StandardQaFeedback,
  '%future added value'
>;

export const interpretationAccuracyOptions: InterpretationAccuracy[] = [
  'TRUE_POSITIVE',
  'TRUE_NEGATIVE',
  'FALSE_POSITIVE',
  'FALSE_NEGATIVE',
];

export const standardQaFeedbackOptions: StandardQaFeedback[] = [
  'ADJUST_GAIN',
  'ADJUST_DEPTH',
  'INCOMPLETE_MEASUREMENTS',
  'INCORRECT_PRESET',
  'INCORRECT_ORIENTATION',
  'INCORRECT_TRANSDUCER',
  'MISSING_STANDARD_VIEWS',
  'NOT_CLINICALLY_INDICATED',
];

const interpretationAccuracy = string()
  .enum([null, ...interpretationAccuracyOptions])
  .optional()
  .nullable();

export const schema = object({
  imageQuality: number().optional().nullable(),
  accuracyAsPresented: interpretationAccuracy,
  accuracyComparedToGoldStandard: interpretationAccuracy,
  feedback: array(string().enum(standardQaFeedbackOptions))
    .optional()
    .nullable(),
  comments: string().optional().nullable(),
  examTypeProficiency: array(
    object({
      id: string().required(),
      // this should not be nullable, but if you delete an exam type it is
      name: string().nullable(),
      countsTowardCredentialing: bool().required(),
      deletedAt: string().nullable(),
    }),
  )
    .ensure()
    .when('$canUseStandardQa', {
      is: false,
      then: (s) => s.strip(),
    }),
  qaEntries: array(
    lazy((value) =>
      templateVersionToSchema(value.templateVersion).shape({
        id: string().optional().default(uniqueId),
        templateVersion: object({
          id: string().required(),
          title: string(),
        }),
      }),
    ),
  ).ensure(),
});

export type StandardQaForm = InferType<typeof schema>;
export type DeserializedStandardQa = TypeOf<typeof schema>;

export type ExamTypeProficiencyField =
  StandardQaForm['examTypeProficiency'][0];

export function templateVersionToEntry(templateVersion: {
  id: string;
  title: string | null;
}) {
  return templateVersion
    ? { values: {}, templateVersion, id: uniqueId(templateVersion.id) }
    : null;
}

export function getExamTypeProficiency(
  studyRef: ExamTypeProficiencyKey,
  readOnly: boolean,
) {
  const credentialInfo: ExamTypeProficiencyField[] = [];

  const { examTypes, standardQa } = readInlineData(
    graphql`
      fragment standardQa_examTypeProficiency on Study @inline {
        standardQa {
          examTypeCredentialInfo {
            examType {
              id
              name
              deletedAt
            }
            countsTowardCredentialing
          }
        }
        examTypes {
          id
          name
          deletedAt
        }
      }
    `,
    studyRef,
  );

  standardQa?.examTypeCredentialInfo?.forEach(
    ({ examType, countsTowardCredentialing }) => {
      credentialInfo.push({
        id: examType!.id,
        name: examType!.name!,
        countsTowardCredentialing,
        deletedAt: examType?.deletedAt ?? undefined,
      });
    },
  );

  examTypes?.forEach((examType) => {
    if (!credentialInfo.some((e) => e.id === examType?.id))
      credentialInfo.push({
        id: examType!.id,
        name: examType!.name!,
        deletedAt: examType?.deletedAt ?? undefined,
        // required but has no default value
        countsTowardCredentialing: undefined as unknown as boolean,
      });
  });

  // if readonly show all
  // if not readonly show only non-deleted
  return credentialInfo.filter(({ deletedAt }) => readOnly || !deletedAt);
}

function getQaEntries(study: Study) {
  if (studyReviewStatus(study) === 'REVIEWED') {
    return study.qaEntries
      ? [...study.qaEntries].filter((e) => !!e?.templateVersion)
      : [];
  }

  const defaultTemplateVersion =
    study!.organization!.workflowSettings?.defaultQaTemplate?.latestVersion;

  return defaultTemplateVersion
    ? [templateVersionToEntry(defaultTemplateVersion)]
    : [];
}

export const deserialize = (studyRef: StudyKey, readOnly: boolean) => {
  const study = readInlineData(
    graphql`
      fragment standardQa_study on Study @inline {
        standardQa {
          imageQuality
          accuracyAsPresented
          accuracyComparedToGoldStandard
          feedback
          comments
        }
        qaEntries {
          id
          values
          templateVersion {
            id
            title
            ...qaEntry_templateVersion
            # this is needed by the parent b/c Relay makes forms annoying
            # eslint-disable-next-line relay/must-colocate-fragment-spreads
            ...ExamQa_templateVersion
          }
        }
        organization {
          workflowSettings {
            defaultQaTemplate {
              latestVersion {
                id
                title
                ...qaEntry_templateVersion
                # this is needed by the parent b/c Relay makes forms annoying
                # eslint-disable-next-line relay/must-colocate-fragment-spreads
                ...ExamQa_templateVersion
              }
            }
          }
        }
        ...standardQa_examTypeProficiency
        ...StudyPermissions_studyReviewStatus
      }
    `,
    studyRef,
  );

  return schema.cast({
    ...study.standardQa,
    qaEntries: getQaEntries(study),
    examTypeProficiency: getExamTypeProficiency(study, readOnly),
  });
};

export const serialize = (
  { examTypeProficiency, ...nextValue }: StandardQaForm,
  studyId: string,
  canUseStandardQa: boolean,
) => {
  const qaEntries = nextValue.qaEntries?.map(
    ({ values, templateVersion }) => ({
      templateVersionId: templateVersion.id,
      values,
    }),
  );

  return canUseStandardQa
    ? {
        ...nextValue,
        studyId,
        examTypeCredentialInfo: examTypeProficiency
          // don't send anything thats been deleted
          .filter((examType) => !examType.deletedAt)
          .map((examType) => ({
            examTypeId: examType.id,
            countsTowardCredentialing: examType.countsTowardCredentialing!,
          })),
        qaEntries,
      }
    : { studyId, qaEntries };
};
