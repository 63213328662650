/**
 * @generated SignedSource<<6801eddc59f13d403ba63e87426b593b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { InlineFragment, ReaderInlineDataFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type canAccessOrganizationSettings_organization$data = {
  readonly viewerPermissions: {
    readonly " $fragmentSpreads": FragmentRefs<"checkPermission_viewerPermissions">;
  } | null;
  readonly " $fragmentType": "canAccessOrganizationSettings_organization";
};
export type canAccessOrganizationSettings_organization$key = {
  readonly " $data"?: canAccessOrganizationSettings_organization$data;
  readonly " $fragmentSpreads": FragmentRefs<"canAccessOrganizationSettings_organization">;
};

const node: ReaderInlineDataFragment = {
  "kind": "InlineDataFragment",
  "name": "canAccessOrganizationSettings_organization"
};

(node as any).hash = "d549b6685b0757cead4aecfd618e280d";

export default node;
