import SyncingIcon from '@bfly/icons/Syncing';
import Dropdown from '@bfly/ui2/Dropdown';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import { useVariation } from 'components/LaunchDarklyContext';
import { usePermissions } from 'components/PermissionsProvider';
import useModalState from 'hooks/useModalState';
import { useCanManageEndpoints } from 'utils/StudyPermissions';

import StudyEndpointsModal from './StudyEndpointsModal';
import { StudyEndpointsDropdownItem_study$data as Study } from './__generated__/StudyEndpointsDropdownItem_study.graphql';

function StudyEndpointsDropdownItem({ study }: { study: Study }) {
  const canManageEndpoints = useCanManageEndpoints(study);
  const canUseHl7Connector = useVariation('hl7-connector');
  const { hasBasicPermission } = usePermissions();

  const [showModal, modalProps] = useModalState();

  if (
    !canManageEndpoints ||
    !study.finalizedAt ||
    !hasBasicPermission('connectivityManagement')
  ) {
    return null;
  }

  return (
    <>
      <Dropdown.Item icon={<SyncingIcon />} onClick={showModal}>
        {canUseHl7Connector ? (
          <FormattedMessage id="study.endpoints" defaultMessage="Endpoints" />
        ) : (
          <FormattedMessage
            id="study.pacsDetails"
            defaultMessage="DICOM Management"
          />
        )}
      </Dropdown.Item>
      <StudyEndpointsModal {...modalProps} study={study} />
    </>
  );
}

export default createFragmentContainer(StudyEndpointsDropdownItem, {
  study: graphql`
    fragment StudyEndpointsDropdownItem_study on Study {
      finalizedAt
      ...StudyEndpointsModal_study
      ...StudyPermissions_canManageEndpoints
    }
  `,
});
