import Layout from '@4c/layout';
import BackArrowLink from '@bfly/ui2/BackArrowLink';
import Button from '@bfly/ui2/Button';
import Link from '@bfly/ui2/Link';
import Navbar from '@bfly/ui2/Navbar';
import useResponsive from '@bfly/ui2/useResponsive';
import { Helmet } from 'react-helmet';
import { FormattedMessage, defineMessages, useIntl } from 'react-intl';

import AppProductNav from 'components/AppProductNav';
import HelpDropdownButton from 'components/HelpDropdownButton';
import UserDropdownButton from 'components/UserDropdownButton';
import { marketingWebsiteUrl } from 'config/app';
import { useEducationRoutes } from 'routes/education';
import { useViewerAllowMissingContext } from 'utils/viewerState';

import { isInApp } from '../../../utils/browserInfo';

const messages = defineMessages({
  title: {
    id: 'education.appHeaderTitle',
    defaultMessage: 'Butterfly Education',
  },
});

interface Props {
  canGoBack?: boolean;
}

export default function EducationAppHeader({ canGoBack }: Props) {
  const viewer = useViewerAllowMissingContext();
  const educationRoutes = useEducationRoutes();
  const isMedium = useResponsive('md', 'down');
  const isSmall = useResponsive('sm', 'down');
  const inApp = isInApp();

  const { formatMessage } = useIntl();
  const butterflyEducation = formatMessage(messages.title);

  return (
    <Navbar>
      <Helmet
        defaultTitle={butterflyEducation}
        titleTemplate={`%s - ${butterflyEducation}`}
      />

      {isMedium && canGoBack ? (
        <BackArrowLink to={educationRoutes.education()} className="w-auto" />
      ) : (
        <Navbar.Brand as={Link} to="/-/edu" />
      )}

      <Layout.Spacer />
      {!inApp && !isSmall && (
        <>
          <AppProductNav />
          <Navbar.Divider />
        </>
      )}

      {!inApp && (
        <>
          <HelpDropdownButton />
          <UserDropdownButton />
        </>
      )}

      {!viewer && !isSmall && (
        <Button href={marketingWebsiteUrl} className="ml-4">
          <FormattedMessage
            id="educationAppHeader.learnMore"
            defaultMessage="Learn More"
          />
        </Button>
      )}
    </Navbar>
  );
}
