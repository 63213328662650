import HttpError from 'found/HttpError';
import { graphql, readInlineData } from 'react-relay';

import Route, { RelayRouteRenderArgs } from 'components/Route';
import canAccessOrganizationSettings from 'utils/canAccessOrganizationSettings';

import { organizations_DomainOrganizationsList_Query as DomainOrganizationsListQuery } from './__generated__/organizations_DomainOrganizationsList_Query.graphql';
import { organizations_OrganizationSettingsContent_Query as OrganizationSettingsContentQuery } from './__generated__/organizations_OrganizationSettingsContent_Query.graphql';
import { organizations_OrganizationSettingsContent_organization$key as OrganizationKey } from './__generated__/organizations_OrganizationSettingsContent_organization.graphql';
import { organizations_OrganizationSettingsPage_Query as OrganizationSettingsPageQuery } from './__generated__/organizations_OrganizationSettingsPage_Query.graphql';
import DomainOrganizationsList from './components/DomainOrganizationsList';
import DomainOrganizationsPage from './components/DomainOrganizationsPage';
import annotationExtensionsRoute from './routes/annotationExtensions';
import membersRoute from './routes/members';
import subscriptionRoute from './routes/subscription';

function checkCanAccessOrganizationSettings({
  props,
  match,
}: RelayRouteRenderArgs<OrganizationSettingsContentQuery['response']>) {
  if (!props || !match) return;
  const organization = readInlineData<OrganizationKey>(
    graphql`
      fragment organizations_OrganizationSettingsContent_organization on Organization
      @inline {
        ...canAccessOrganizationSettings_organization
      }
    `,
    props.organization,
  );

  if (!canAccessOrganizationSettings(organization)) {
    throw new HttpError(404);
  }
}

export const domainOrganizationsRoute = (
  <Route path="organizations">
    <Route<OrganizationSettingsPageQuery>
      Component={DomainOrganizationsPage}
      query={graphql`
        query organizations_OrganizationSettingsPage_Query {
          viewer {
            ...DomainOrganizationsPage_viewer
          }
        }
      `}
    >
      <Route<DomainOrganizationsListQuery>
        Component={DomainOrganizationsList}
        prepareVariables={(variables, { location: { query } }) => ({
          ...variables,
          name: query.search || null,
        })}
        query={graphql`
          query organizations_DomainOrganizationsList_Query($name: String) {
            viewer {
              ...DomainOrganizationsList_viewer @arguments(name: $name)
            }
          }
        `}
      />
    </Route>
  </Route>
);

export const orgSettingsRoutes = (
  <>
    {membersRoute}
    {subscriptionRoute}
    {annotationExtensionsRoute}

    <Route<OrganizationSettingsContentQuery>
      path="settings"
      getComponent={() =>
        import(
          /* webpackChunkName: "admin" */ './components/OrganizationSettingsContent'
        ).then((m) => m.default)
      }
      query={graphql`
        query organizations_OrganizationSettingsContent_Query(
          $organizationSlug: String!
        ) {
          organization: organizationBySlug(slug: $organizationSlug) {
            ...OrganizationSettingsContent_organization
            ...organizations_OrganizationSettingsContent_organization
          }
          tenant(slug: $organizationSlug) {
            ...OrganizationSettingsContent_tenant
          }
        }
      `}
      prerender={(renderArgs) => {
        const { ldClient, props } = renderArgs;

        if (!props || !ldClient) return;

        checkCanAccessOrganizationSettings(renderArgs);
      }}
    />
  </>
);
