import Layout from '@4c/layout';
import ExternalLinkIcon from '@bfly/icons/ExternalLink';
import Logo from '@bfly/icons/Logo';
import Members from '@bfly/icons/Members';
import ScanLabIcon from '@bfly/icons/ScanLab';
import ScanLabReviewIcon from '@bfly/icons/ScanlabReview';
import BackButton from '@bfly/ui2/BackButton';
import Button from '@bfly/ui2/Button';
import Header from '@bfly/ui2/Header';
import Link from '@bfly/ui2/Link';
import Navbar from '@bfly/ui2/Navbar';
import Text from '@bfly/ui2/Text';
import { css } from 'astroturf';
import { useState } from 'react';
import { FormattedMessage, MessageDescriptor } from 'react-intl';
import { graphql } from 'react-relay';

import FileExportButton from 'components/FileExportButton';
import LmsRedirect from 'components/LmsRedirect';
import { useActiveRoute } from 'components/isRouteActive';
import { routes as eduRoutes } from 'routes/education';
import { routes as scanLabRoutes } from 'routes/scanLab';
import Analytics from 'utils/Analytics';
import { useCanAccessEduDashboard } from 'utils/canAccessEduDashboard';
import { useCanAccessScanLab } from 'utils/canAccessScanLab';

import { STUDIES_MESSAGES } from '../../scanLab/messages';
import educationMessages from '../messages/educationMessages';
import { LmsSsoBanner_ExportQuery as ExportQuery } from './__generated__/LmsSsoBanner_ExportQuery.graphql';
import useButterflyAcademyAccess from './utils/useButterflyAcademyAccess';

interface ExportButtonProps {
  message?: MessageDescriptor;
}

function EducationDashboardExportButton({
  message = educationMessages.exportButton,
}: ExportButtonProps = {}) {
  return (
    <FileExportButton<ExportQuery>
      variant="secondary"
      className="ml-auto md-max:w-full md-max:mt-1"
      message={<FormattedMessage {...message} />}
      query={graphql`
        query LmsSsoBanner_ExportQuery($timezoneOffset: Int) {
          lms {
            dashboardExport(timezoneOffset: $timezoneOffset) {
              ...FileExportButton_fileExport
            }
          }
        }
      `}
      variables={{ timezoneOffset: -new Date().getTimezoneOffset() }}
      getFile={(res) => res!.lms!.dashboardExport}
    />
  );
}

function ScanLabPortfolioLink() {
  return (
    <Link
      to={scanLabRoutes.portfolio()}
      as={Navbar.NavItem}
      className="bg-transparent"
      data-bni-id="ScanLabPortfolioBtn"
    >
      <Text color="headline" className="flex items-center">
        <ScanLabIcon width={18} className="mr-2" />
        <FormattedMessage {...STUDIES_MESSAGES.portfolio.title} />
      </Text>
    </Link>
  );
}

function ScanLabReviewLink() {
  return (
    <Link
      to={scanLabRoutes.review()}
      as={Navbar.NavItem}
      className="bg-transparent"
      data-bni-id="ScanLabReviewBtn"
    >
      <Text color="headline" className="flex items-center">
        <ScanLabReviewIcon width={18} className="mr-2" />
        <FormattedMessage {...STUDIES_MESSAGES.review.title} />
      </Text>
    </Link>
  );
}

export default function LmsSsoBanner() {
  const butterflyAcademyAccess = useButterflyAcademyAccess();
  const {
    portfolio: canAccessScanLabPortfolio,
    review: canAccessScanLabReview,
  } = useCanAccessScanLab();
  const canViewScanLab = canAccessScanLabPortfolio || canAccessScanLabReview;
  const [lmsRedirect, setLmsRedirect] = useState(false);
  const canAccessEduDashboard = useCanAccessEduDashboard();
  const EDU_LOCATION = eduRoutes.education();
  const EDU_DASHBOARD_LOCATION = eduRoutes.dashboardCourseOverview();

  const isRouteActive = useActiveRoute();

  const isEduActive = isRouteActive(EDU_LOCATION, { exact: true });

  const isEduDashboardRoute = isRouteActive(eduRoutes.dashboard());

  const onClickGoToCurriculum = () => {
    Analytics.track('goToCurriculum', {});
    setLmsRedirect(true);
  };

  if (butterflyAcademyAccess !== 'enabled') {
    if (!canViewScanLab || isEduDashboardRoute) return null;

    return (
      <div data-bni-id="lmsLoginBanner">
        <Layout
          align="center"
          justify="flex-end"
          className="w-full mt-2 flex-wrap sm:flex-nowrap"
        >
          {canAccessScanLabPortfolio && <ScanLabPortfolioLink />}
          {canAccessScanLabPortfolio && canAccessScanLabReview && (
            <Navbar.Divider />
          )}
          {canAccessScanLabReview && <ScanLabReviewLink />}
        </Layout>
      </div>
    );
  }

  return (
    <div data-bni-id="lmsLoginBanner">
      {isEduDashboardRoute ? (
        <Header className="w-full">
          <Link to={eduRoutes.education()}>
            <BackButton />
          </Link>

          <Header.Title>
            <FormattedMessage {...educationMessages.educationDashboard} />
          </Header.Title>
          <EducationDashboardExportButton
            message={educationMessages.exportButtonShort}
          />
        </Header>
      ) : (
        <Layout
          align="center"
          justify="flex-end"
          className="w-full mt-2 flex-wrap sm:flex-nowrap"
        >
          {canAccessEduDashboard && (
            <>
              <Link
                to={EDU_DASHBOARD_LOCATION}
                as={Navbar.NavItem}
                active={!isEduActive}
                className="bg-transparent"
                data-bni-id="EducationDashboardBtn"
              >
                <Text color="headline" className="flex items-center">
                  <Members width={18} className="mr-2" />
                  <FormattedMessage
                    {...educationMessages.educationDashboard}
                  />
                </Text>
              </Link>
              <Navbar.Divider />
            </>
          )}
          {canAccessScanLabPortfolio && (
            <>
              <ScanLabPortfolioLink />
              <Navbar.Divider />
            </>
          )}
          {canAccessScanLabReview && (
            <>
              <ScanLabReviewLink />
              <Navbar.Divider />
            </>
          )}
          <Button
            onClick={onClickGoToCurriculum}
            busy={lmsRedirect}
            variant="text-secondary"
            data-bni-id="bflyAcademyButton"
          >
            <Logo
              width={18}
              className="mr-2"
              css={css`
                path {
                  fill: var(--bni-blue) !important;
                }
              `}
            />
            <FormattedMessage
              id="edu.lmsSSO.cta"
              defaultMessage="Butterfly Academy"
            />
            <ExternalLinkIcon className="ml-2 opacity-70" />
          </Button>
        </Layout>
      )}
      {lmsRedirect && (
        <LmsRedirect
          openNewTab
          keepActiveRoute
          onOpen={(isOpen) => {
            if (isOpen) setLmsRedirect(false);
          }}
        />
      )}
    </div>
  );
}
