import Button from '@bfly/ui2/Button';
import Listbox from '@bfly/ui2/Listbox';
import Modal from '@bfly/ui2/Modal';
import Text from '@bfly/ui2/Text';
import useToast from '@bfly/ui2/useToast';
import captureException from '@bfly/utils/captureException';
import useMounted from '@restart/hooks/useMounted';
import { useMemo, useState } from 'react';
import { FormattedMessage, defineMessage } from 'react-intl';
import type { MessageDescriptor } from 'react-intl';
import { createFragmentContainer, fetchQuery, graphql } from 'react-relay';
import type { RelayProp } from 'react-relay';
import type { MultipleChangeHandler } from 'react-widgets/Listbox';

import { usePermissions } from 'components/PermissionsProvider';
import useSafeUseState from 'hooks/useSafeUseState';
import actionMessages from 'messages/actions';
import downloadFile from 'utils/downloadFile';
import withModal from 'utils/withModal';

import type { StudyDownloadPdfModal_UrlQuery as UrlQuery } from './__generated__/StudyDownloadPdfModal_UrlQuery.graphql';
import type { StudyDownloadPdfModal_study$data as Study } from './__generated__/StudyDownloadPdfModal_study.graphql';

const urlQuery = graphql`
  query StudyDownloadPdfModal_UrlQuery(
    $studyId: ID!
    $includeStudyInfo: Boolean
    $includePatientInfo: Boolean
    $includeVetPatientInfo: Boolean
    $includeStudyImages: Boolean
    $includeWorksheets: Boolean
    $timezone: String!
  ) {
    study: node(id: $studyId) {
      ... on Study {
        pdfUrl(
          includeStudyInfo: $includeStudyInfo
          includePatientInfo: $includePatientInfo
          includeVetPatientInfo: $includeVetPatientInfo
          includeStudyImages: $includeStudyImages
          includeWorksheets: $includeWorksheets
          timezone: $timezone
        )
      }
    }
  }
`;

interface StudyPdfOption {
  id: string;
  message: MessageDescriptor;
}

const STUDY_PDF_OPTIONS_SHARED: StudyPdfOption[] = [
  {
    id: 'includeStudyInfo',
    message: defineMessage({
      id: 'studyDownloadPdfModal.includeStudyInfo',
      defaultMessage: 'Study Information',
    }),
  },
  {
    id: 'includeWorksheets',
    message: defineMessage({
      id: 'studyDownloadPdfModal.includeWorksheets',
      defaultMessage: 'Worksheets',
    }),
  },
  {
    id: 'includeStudyImages',
    message: defineMessage({
      id: 'studyDownloadPdfModal.includeStudyImages',
      defaultMessage: 'Images',
    }),
  },
];

const STUDY_PDF_OPTIONS_HUMAN: StudyPdfOption[] = [
  // sticking to the order of how each category is presented on the FE and PDF
  STUDY_PDF_OPTIONS_SHARED[0],
  {
    id: 'includePatientInfo',
    message: defineMessage({
      id: 'studyDownloadPdfModal.includePatientInfo',
      defaultMessage: 'Patient Information',
    }),
  },
  ...STUDY_PDF_OPTIONS_SHARED.slice(1),
];

const STUDY_PDF_OPTIONS_VET: StudyPdfOption[] = [
  // sticking to the order of how each category is presented on the FE and PDF
  STUDY_PDF_OPTIONS_SHARED[0],
  {
    id: 'includeVetPatientInfo',
    message: defineMessage({
      id: 'studyDownloadPdfModal.includeVetPatientInfo',
      defaultMessage: 'Vet Patient Information',
    }),
  },
  ...STUDY_PDF_OPTIONS_SHARED.slice(1),
];

const getStudyPdfOptions = (hasAccess: boolean) => {
  if (hasAccess) {
    return STUDY_PDF_OPTIONS_HUMAN;
  }
  return STUDY_PDF_OPTIONS_HUMAN.filter(
    (option) => option.id !== 'includePatientInfo',
  );
};

interface Props {
  study: Study;
  onHide(): void;
  relay: RelayProp;
}

function StudyDownloadPdfModal({ study, onHide, relay }: Props) {
  const toast = useToast();
  const [value, setValue] = useState<StudyPdfOption[]>([]);
  const [busy, setBusy] = useSafeUseState(false);
  const [showError, setShowError] = useSafeUseState(false);
  const isMounted = useMounted();
  const { hasBasicPermission } = usePermissions();
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  const [humanOptions, hasDataExportPermission] = useMemo(() => {
    const hasHumanOptions = getStudyPdfOptions(
      hasBasicPermission('dataExport'),
    );
    const hasPermission = hasBasicPermission('dataExport');

    return [hasHumanOptions, hasPermission];
  }, [hasBasicPermission]);

  const filteredStudyPdfOptions =
    study.practiceType === 'HUMAN' ? humanOptions : STUDY_PDF_OPTIONS_VET;

  function handleChange(dataItem) {
    setValue(dataItem);
    setShowError(false);
  }

  async function downloadPdf() {
    if (!value.length) {
      setShowError(true);
      return;
    }

    setBusy(true);

    const variables = { studyId: study.id, timezone: timeZone };
    for (const item of value) {
      variables[item.id] = true;
    }

    try {
      const result = await fetchQuery<UrlQuery>(
        relay.environment,
        urlQuery,
        variables,
      ).toPromise();
      if (!isMounted()) return;
      await downloadFile(result!.study!.pdfUrl!);
    } catch (e: any) {
      captureException(e);
      toast.error(
        <FormattedMessage
          id="studyDownloadPdfModal.create.failed"
          defaultMessage="Failed to create PDF"
        />,
      );
    } finally {
      setBusy(false);
    }

    onHide();
  }

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage
            id="studyDownloadPdfModal.title"
            defaultMessage="Create PDF"
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          {hasDataExportPermission ? (
            <FormattedMessage
              id="studyDownloadPdfModal.body.warning"
              defaultMessage="I understand this study may contain confidential and/or privileged patient information and may be legally protected from disclosure, and I agree to accept any liability from sharing or storing this study."
            />
          ) : (
            <FormattedMessage
              id="studyDownloadPdfModal.body.warning.regular"
              defaultMessage="Including patient information in a study PDF requires administrator rights. If you need to download a study with patient information please request this from your administrator."
            />
          )}
        </p>

        <p>
          <FormattedMessage
            id="studyDownloadPdfModal.body.select"
            defaultMessage="Please select relevant content:"
          />
        </p>
        {showError && (
          <Text as="p" color="danger">
            <FormattedMessage
              id="studyDownloadPdfModal.body.error"
              defaultMessage="At least one option must be selected."
            />
          </Text>
        )}
        <Listbox<StudyPdfOption>
          data={filteredStudyPdfOptions}
          value={value}
          multiple
          variant="secondary"
          onChange={handleChange as MultipleChangeHandler<StudyPdfOption>}
          disabled={
            busy ||
            filteredStudyPdfOptions.filter(
              ({ id }) =>
                (!study.worksheets!.length && id === 'includeWorksheets') ||
                (study.numImages === 0 && id === 'includeStudyImages'),
            )
          }
          renderItem={({ item }) => <FormattedMessage {...item.message} />}
          dataKey="id"
          css="height: 12rem;"
        />
      </Modal.Body>
      <Modal.Footer>
        <Modal.ButtonGroup>
          <Button busy={busy} variant="primary" onClick={downloadPdf}>
            <FormattedMessage {...actionMessages.download} />
          </Button>
          <Button variant="secondary" onClick={onHide}>
            <FormattedMessage {...actionMessages.cancel} />
          </Button>
        </Modal.ButtonGroup>
      </Modal.Footer>
    </>
  );
}

export default createFragmentContainer(
  withModal(StudyDownloadPdfModal, { variant: 'dark' }),
  {
    study: graphql`
      fragment StudyDownloadPdfModal_study on Study {
        id
        worksheets {
          __typename
        }
        numImages
        practiceType
      }
    `,
  },
);
