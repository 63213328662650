import Anchor from '@bfly/ui2/Anchor';
import Link from '@bfly/ui2/Link';
import Page from '@bfly/ui2/Page';
import { FormattedMessage } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import { useAdminRoutes } from 'routes/admin';
import makeStoreUrl from 'utils/makeStoreUrl';

import { usePermissions } from './PermissionsProvider';
import { SubscriptionExpiredBanner_organization$data as Organization } from './__generated__/SubscriptionExpiredBanner_organization.graphql';

interface Props {
  organization: Organization;
}

const bannerLinkClassName =
  'text-headline underline hover:underline hover:text-grey-25';

function SubscriptionExpiredBanner({ organization }: Props) {
  const { subscription, slug } = organization!;
  const adminRoutes = useAdminRoutes({ organizationSlug: slug! });
  const {
    hasCompletedTrial,
    isInGracePeriod,
    canAccessProFeatures,
    hasStartedActiveSubscription,
  } = subscription!;

  const { hasBasicPermission } = usePermissions();

  const hasBillingPermissions = hasBasicPermission('organizationManagement');
  if (!hasCompletedTrial || (canAccessProFeatures && !isInGracePeriod)) {
    return null;
  }

  return (
    <Page.Banner
      variant="expired"
      className="flex items-center justify-center z-0"
    >
      <div>
        {hasBillingPermissions &&
          (hasStartedActiveSubscription ? (
            <FormattedMessage
              id="navbar.banner.subscriptionExpired.adminPro"
              defaultMessage="Your Pro membership has expired. <textexternallink>Reactivate</textexternallink> or <textlink>learn more</textlink>."
              values={{
                textexternallink: (str) => (
                  <Anchor
                    className={bannerLinkClassName}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={makeStoreUrl('subscription', {
                      organizationSlug: slug!,
                    })}
                  >
                    {str}
                  </Anchor>
                ),
                textlink: (str) => (
                  <Link
                    as="a"
                    className={bannerLinkClassName}
                    to={adminRoutes.organizationSubscription()}
                  >
                    {str}
                  </Link>
                ),
              }}
            />
          ) : (
            <FormattedMessage
              id="navbar.banner.subscriptionExpired.adminTrial"
              defaultMessage="<textexternallink>Upgrade</textexternallink> or <textlink>learn more</textlink> about a Pro membership."
              values={{
                textexternallink: (str) => (
                  <Anchor
                    className={bannerLinkClassName}
                    target="_blank"
                    rel="noopener noreferrer"
                    href={makeStoreUrl('subscription', {
                      organizationSlug: slug!,
                    })}
                  >
                    {str}
                  </Anchor>
                ),
                textlink: (str) => (
                  <Link
                    as="a"
                    className={bannerLinkClassName}
                    to={adminRoutes.organizationSubscription()}
                  >
                    {str}
                  </Link>
                ),
              }}
            />
          ))}
        {!hasBillingPermissions && (
          <FormattedMessage
            id="navbar.banner.subscriptionExpired.regular"
            defaultMessage="Your membership has expired. Please contact an organization admin to reactivate."
          />
        )}
      </div>
    </Page.Banner>
  );
}

export default createFragmentContainer(SubscriptionExpiredBanner, {
  organization: graphql`
    fragment SubscriptionExpiredBanner_organization on Organization {
      subscription {
        hasCompletedTrial
        hasStartedActiveSubscription
        canAccessProFeatures
        isInGracePeriod
      }
      slug
    }
  `,
});
