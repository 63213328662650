import BlankSlate from '@bfly/ui2/BlankSlate';
import Listbox from '@bfly/ui2/Listbox';
import Modal from '@bfly/ui2/Modal';
import Text from '@bfly/ui2/Text';
import { css } from 'astroturf';
import useRouter from 'found/useRouter';
import { useMemo, useState } from 'react';
import { FormattedMessage, defineMessages } from 'react-intl';
import { createFragmentContainer, graphql } from 'react-relay';

import { routes } from 'routes/config';
import { useExamRoutes } from 'routes/exam';
import Analytics from 'utils/Analytics';
import { StudyListFilter } from 'utils/StudyConstants';
import { Organization, useViewerContext } from 'utils/viewerState';
import withModal from 'utils/withModal';

import DebouncedSearchControl from './DebouncedSearchControl';
import { useVariation } from './LaunchDarklyContext';
import { SwitchOrganizationModal_activeOrganization$data as ActiveOrganization } from './__generated__/SwitchOrganizationModal_activeOrganization.graphql';

export const messages = defineMessages({
  searchPlaceholder: {
    id: 'organizationSwitcherMenu.search.placeholder',
    defaultMessage: 'Search by organization name',
  },
  allOrganizations: {
    defaultMessage: 'All Organizations',
    id: 'organizationSwitcher.all.orgs',
  },
});

interface Props {
  activeOrganization: ActiveOrganization | null;
  onHide: () => void;
}

type OrganizationOption = Pick<Organization, 'id' | 'name' | 'slug'>;

const ALL_ORGANIZATIONS_OPTION: OrganizationOption = {
  id: 'all.orgs',
  name: messages.allOrganizations.defaultMessage,
  slug: '-',
};

const SwitchOrganizationModal = ({ activeOrganization, onHide }: Props) => {
  const { router, match } = useRouter();
  const viewer = useViewerContext();
  const examRoutes = useExamRoutes();

  const canShowAllOrgs = useVariation('cross-org-study-list') && viewer.domain;

  const [search, setSearch] = useState('');

  const onSelect = ({ slug, id }: OrganizationOption) => {
    onHide();

    Analytics.track('multiOrgSwitch', {
      from: match.params.organizationSlug,
      to: slug,
    });

    if (id === ALL_ORGANIZATIONS_OPTION?.id) {
      router.push(
        examRoutes.examLists({
          organizationSlug: '-',
          status: StudyListFilter.ALL,
        }),
      );
    } else if (slug) {
      router.push(routes.rootOrganization({ organizationSlug: slug }));
    }
  };

  const organizationOptions = useMemo(() => {
    let options = [...viewer.organizations];

    if (search.trim().length > 0) {
      options = options.filter((org) =>
        org.name!.toLowerCase().includes(search.toLowerCase()),
      );
    }

    return [
      // only add the "All Organizations" option if the user has access to all organizations
      ...(canShowAllOrgs ? [ALL_ORGANIZATIONS_OPTION] : []),
      ...options,
    ];
  }, [canShowAllOrgs, search, viewer.organizations]);

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage
            id="switchOrganizationModal.title"
            defaultMessage="Switch Organization"
          />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body scrollable={false} className="flex flex-col overflow-hidden">
        {viewer.organizations.length > 9 && (
          <DebouncedSearchControl
            value={search}
            placeholder={messages.searchPlaceholder}
            onChange={setSearch}
            className="mb-6"
            variant="secondary"
          />
        )}
        <Listbox<OrganizationOption>
          variant="secondary"
          data={organizationOptions}
          dataKey="id"
          value={activeOrganization?.id || ALL_ORGANIZATIONS_OPTION.id}
          textField="name"
          messages={{
            emptyList: () => (
              <BlankSlate>
                <BlankSlate.Title>
                  <FormattedMessage
                    defaultMessage="No Organizations found."
                    id="switchOrganizationModal.filter.notfound.title"
                  />
                </BlankSlate.Title>
                <BlankSlate.Body>
                  <FormattedMessage
                    defaultMessage="The filter returned no results."
                    id="switchOrganizationModal.filter.notfound.body"
                  />
                </BlankSlate.Body>
              </BlankSlate>
            ),
          }}
          renderItem={(item) => {
            if (item.item.id === ALL_ORGANIZATIONS_OPTION.id) {
              return <Text variant="body-bold">{item.text}</Text>;
            }
            return item.text;
          }}
          onChange={onSelect}
          css={css`
            :global(.rw-list-option) {
              padding-top: theme('spacing.2');
              padding-bottom: theme('spacing.2');

              &::before {
                top: 16px !important;
              }
            }
          `}
        />
      </Modal.Body>
    </>
  );
};

export default createFragmentContainer(
  withModal(SwitchOrganizationModal, { backdrop: true, variant: 'dark' }),
  {
    activeOrganization: graphql`
      fragment SwitchOrganizationModal_activeOrganization on Organization {
        id
      }
    `,
  },
);
