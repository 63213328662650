/**
 * @generated SignedSource<<d8ac8d1fc5066ec031ec093ba187e60d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type analyticsRoutes_AllTimeStatsPage_Query$variables = {
  organizationSlug?: string | null;
};
export type analyticsRoutes_AllTimeStatsPage_Query$data = {
  readonly tenant: {
    readonly " $fragmentSpreads": FragmentRefs<"AllTimeStatsPage_tenant">;
  } | null;
};
export type analyticsRoutes_AllTimeStatsPage_Query = {
  response: analyticsRoutes_AllTimeStatsPage_Query$data;
  variables: analyticsRoutes_AllTimeStatsPage_Query$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationSlug"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "slug",
    "variableName": "organizationSlug"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "handle",
  "storageKey": null
},
v5 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 2147483647
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "analyticsRoutes_AllTimeStatsPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AllTimeStatsPage_tenant"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "analyticsRoutes_AllTimeStatsPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "kind": "TypeDiscriminator",
            "abstractKey": "__isTenantInterface"
          },
          {
            "alias": "type",
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v3/*: any*/),
          {
            "alias": null,
            "args": [
              {
                "kind": "Literal",
                "name": "first",
                "value": 11000
              }
            ],
            "concreteType": "WorksheetTemplateConnection",
            "kind": "LinkedField",
            "name": "worksheetTemplateConnection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "WorksheetTemplateEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "WorksheetTemplate",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "WorksheetTemplateVersion",
                        "kind": "LinkedField",
                        "name": "latestVersion",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "worksheetTemplateConnection(first:11000)"
          },
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "ArchiveConnection",
            "kind": "LinkedField",
            "name": "archiveConnection",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ArchiveEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Archive",
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "label",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Organization",
                        "kind": "LinkedField",
                        "name": "organization",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": "archiveConnection(first:2147483647)"
          },
          {
            "alias": null,
            "args": (v5/*: any*/),
            "filters": null,
            "handle": "connection",
            "key": "Organization_archiveConnection",
            "kind": "LinkedHandle",
            "name": "archiveConnection"
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "viewerCanQa",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ViewerPermissions",
            "kind": "LinkedField",
            "name": "viewerPermissions",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "qa",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "slug",
                "storageKey": null
              }
            ],
            "type": "Organization",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "85d3e8d3f57cc3c485a971533e9f08ff",
    "id": null,
    "metadata": {},
    "name": "analyticsRoutes_AllTimeStatsPage_Query",
    "operationKind": "query",
    "text": "query analyticsRoutes_AllTimeStatsPage_Query(\n  $organizationSlug: String\n) {\n  tenant(slug: $organizationSlug) {\n    __typename\n    ...AllTimeStatsPage_tenant\n    id\n  }\n}\n\nfragment AllTimeStatsPage_tenant on TenantInterface {\n  __isTenantInterface: __typename\n  ...StatsFilters_tenant\n  ...useStudyStatisticsExport_tenant\n  viewerCanQa\n  viewerPermissions {\n    qa\n  }\n}\n\nfragment ArchiveSelectButton_tenant on TenantInterface {\n  __isTenantInterface: __typename\n  archiveConnection(first: 2147483647) {\n    edges {\n      node {\n        id\n        handle\n        label\n        organization {\n          name\n          id\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n  ...getConcreteTenant_tenant\n}\n\nfragment MemberSelectButton_tenant on TenantInterface {\n  __isTenantInterface: __typename\n  id\n}\n\nfragment StatsFilters_tenant on TenantInterface {\n  __isTenantInterface: __typename\n  type: __typename\n  ... on Organization {\n    id\n    handle\n    slug\n  }\n  id\n  ...WorksheetSelectButton_tenant\n  ...MemberSelectButton_tenant\n  ...ArchiveSelectButton_tenant\n}\n\nfragment WorksheetSelectButton_tenant on TenantInterface {\n  __isTenantInterface: __typename\n  worksheetTemplateConnection(first: 11000) {\n    edges {\n      node {\n        id\n        handle\n        latestVersion {\n          title\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment getConcreteTenant_tenant on TenantInterface {\n  __isTenantInterface: __typename\n  ... on Domain {\n    type: __typename\n  }\n  ... on Organization {\n    type: __typename\n  }\n}\n\nfragment useStudyStatisticsExport_tenant on TenantInterface {\n  __isTenantInterface: __typename\n  id\n}\n"
  }
};
})();

(node as any).hash = "c7748b58ba0502e6345677ae1bf6a780";

export default node;
