import getNodes from '@bfly/utils/getNodes';
import { css } from 'astroturf';
import { createFragmentContainer, graphql } from 'react-relay';

import EnterpriseAdminTabNav from 'components/EnterpriseAdminTabNav';
import { useVariation } from 'components/LaunchDarklyContext';
import { usePermissions } from 'components/PermissionsProvider';
import { RouteMatch, RoutePageProps } from 'components/Route';

import { useMemberCounts } from '../utils/MemberCounts';
import DomainMembersGrid from './DomainMembersGrid';
import { DomainMembersGridWrapper_viewer$data as Viewer } from './__generated__/DomainMembersGridWrapper_viewer.graphql';

interface Props extends RoutePageProps {
  viewer: Viewer;
  match: RouteMatch;
}

const DomainMembersGridWrapper = (props: Props) => {
  const inactiveUsersEnabled = useVariation('inactive-users');
  const isInactive = props.match.route.path === 'inactive';

  const membershipRoles = getNodes(props.viewer.membershipRoles)
    .filter(({ isDisabled }) => !isDisabled)
    .map(({ isDisabled, name, id }) => ({
      isDisabled: !!isDisabled,
      name: name || '',
      id: id!,
    }));

  const domainOrganizations = props.viewer.domain?.organizationConnection
    ? getNodes(props.viewer.domain?.organizationConnection).map(
        ({ id, name }) => ({ id: id || '', name: name || '' }),
      )
    : [];

  const dicomLabels = getNodes(
    props.viewer.domain!.dicomFieldTemplateConnection,
  ).map(({ label, id }) => {
    return { label: label || '', id };
  });
  const interfaceCodes = getNodes(props.viewer.domain?.ehrConnection).map(
    (node) => {
      return { handle: node.handle!, name: node.name!, id: node.id! };
    },
  );

  const { hasAdminPermission } = usePermissions();

  const hasUserManagementPermissions = hasAdminPermission('userManagement');

  const editable = !isInactive && hasUserManagementPermissions;

  const { version } = useMemberCounts();
  return (
    <>
      {inactiveUsersEnabled && (
        <EnterpriseAdminTabNav
          css={css`
            @screen md {
              padding: 0 calc(theme('padding.app-panel') - 15px);
              box-shadow: 4px 0px 4px -2px rgba(0, 0, 0, 0.2);
              border-bottom: 2px solid var(--bni-grey-85);
            }
          `}
        />
      )}
      <DomainMembersGrid
        {...props}
        key={props.match.route.path + version}
        domainId={props.viewer.domain!.id}
        dicomLabels={dicomLabels}
        interfaceCodes={interfaceCodes}
        membershipRoles={membershipRoles}
        domainOrganizations={domainOrganizations}
        editable={editable}
        isInactive={isInactive}
      />
    </>
  );
};

export default createFragmentContainer(DomainMembersGridWrapper, {
  viewer: graphql`
    fragment DomainMembersGridWrapper_viewer on Viewer
    @argumentDefinitions(
      search: { type: "String", defaultValue: null }
      isDeleted: { type: "Boolean" }
    ) {
      ...DomainMembersGrid_viewer
        @arguments(search: $search, isDeleted: $isDeleted)
      membershipRoles(
        first: 2147483647
        sort: NAME_ASC
        roleType: [SYSTEM_DEFINED, USER_DEFINED]
      ) {
        edges {
          node {
            id
            isDisabled
            name
          }
        }
      }
      domain {
        id
        organizationConnection(first: 2147483647) {
          edges {
            node {
              id
              name
            }
          }
        }
        dicomFieldTemplateConnection(first: 2147483647) {
          edges {
            node {
              label
              id
            }
          }
        }
        ehrConnection(first: 2147483647) {
          edges {
            node {
              name
              handle
              id
            }
          }
        }
      }
    }
  `,
});
